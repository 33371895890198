import * as professionalProfilePb from "../grpc/generated/professionalProfile_pb";
import professionalProfileService from "../services/professionalProfileService"
import router from "../../../router";

const validateNoAuthorization = (error: any) => {
    if(error?.message === 'Acesso não autorizado' || error?.code === 16){
        // alert("não autorizado.");
        localStorage.removeItem("token");
        router.push("/account/login");
    }
}


async function GetInfo () {
    try {
        const req = new professionalProfilePb.EmptyRequest();
        const res = await professionalProfileService.getInfo(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateAvailableSchedule (payload: any) {
    try {
        const req = new professionalProfilePb.CreateAvailableScheduleRequest();
        req.setInitialDate(payload.initialDate);
        req.setFinalDate(payload.finalDate);
        req.setFrequency(payload.frequency);

        payload.availableScheduleHasDay.forEach((availableSchedule: any) => {
            const availableScheduleHasDay = new professionalProfilePb.AvailableScheduleHasDayProto();
            availableScheduleHasDay.setDayId(availableSchedule.id);
            req.addAvailableScheduleHasDay(availableScheduleHasDay);
        })

        payload.professionalServiceTypeHasAvailableSchedule.forEach((professionalService: any) => {
            const professionalServiceTypeHasAvailableSchedule = new professionalProfilePb.ProfessionalServiceTypeHasAvailableScheduleProto();
            professionalServiceTypeHasAvailableSchedule.setProfessionalServiceTypeId(professionalService.id);
            req.addProfessionalServiceTypeHasAvailableSchedule(professionalServiceTypeHasAvailableSchedule);
        })

        const res = await professionalProfileService.createAvailableSchedule(req);

        return res;
    } catch (error) {
        console.log(error);
        validateNoAuthorization(error);
        return error;
    }
}

async function DeleteAvailableScheduleHistory (availableScheduleId: string) {
    try {
        const req = new professionalProfilePb.DeleteAvailableScheduleHistoryRequest();
        req.setAvailableScheduleId(availableScheduleId);

        const res = await professionalProfileService.deleteAvailableScheduleHistory(req);

        return res;
    } catch (error) {
        console.log(error);
        validateNoAuthorization(error);
        return error;
    }
}

async function ListProfessionalsMedicalRecords(listProfessionals: any) {
    try {
        const req = new professionalProfilePb.ListProfessionalsMedicalRecordsRequest();       
        var professionals = new professionalProfilePb.ListProfessionalsMedicalRecordsRequest();
        listProfessionals.forEach((professionalid: any) => {            
            if(professionalid) professionals.setProfessionalidList(professionalid)
            req.addProfessionalid(professionalid);
        });
        const res = await professionalProfileService.listProfessionalsMedicalRecords(req);

        return res;
    } catch (error) {
        console.log(error);
        // validateNoAuthorization(error); // Handle unauthorized access error
        return error;
    }
}

const UpdateInfo = async (obj: any) => {
    try {
        const req = new professionalProfilePb.UpdateInfoRequest();
        req.setSocialsecuritynumber(obj.social_security_number)
        req.setIdentitynumber(obj.identity_number)
        req.setBirthdate(obj.birth_date)
        req.setAddress(obj.address)
        req.setNumber(obj.number)
        req.setComplement(obj.complement)
        req.setNeighborhood(obj.neighborhood)
        req.setCity(obj.city)
        req.setState(obj.state)
        req.setPostalcode(obj.cep)
        // req.setLatitude()
        // req.setLongitude()

        const res = await professionalProfileService.updateInfo(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const ListCouncil = async () => {
    try {
        const req = new professionalProfilePb.EmptyRequest();


        const res = await professionalProfileService.listCouncil(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

interface ICouncil {
    council: string;
    number: string;
    description?: string;
    state: string;
}

const AddCouncil = async (obj: ICouncil) => {
    try {
        const req = new professionalProfilePb.AddCouncilRequest();
        req.setCouncil(obj.council)
        req.setNumber(obj.number)
        req.setDescription(obj.description)
        req.setState(obj.state)


        const res = await professionalProfileService.addCouncil(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const DeleteCouncil = async (councilid: string) => {
    try {
        const req = new professionalProfilePb.DeleteCouncilRequest();
        req.setCouncilid(councilid)

        const res = await professionalProfileService.deleteCouncil(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const AddSpeciality = async (obj: any) => {
    try {
        const req = new professionalProfilePb.AddSpecialtyRequest();
        req.setCouncilid(obj.councilid)
        req.setDescription(obj.description)
        req.setRqeNumber(obj.rqe_number)

        const res = await professionalProfileService.addSpeciality(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const DeleteSpeciality = async (specialtyid: string) => {
    try {
        const req = new professionalProfilePb.DeleteSpecialtyRequest();
        req.setSpecialtyid(specialtyid)
        
        const res = await professionalProfileService.deleteSpeciality(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const UpdateName = async (obj: any) => {
    try {
        const req = new professionalProfilePb.UpdateNameRequest();
        req.setFirstname(obj.first_name)
        req.setLastname(obj.last_name)

        const res = await professionalProfileService.updateName(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListPlaces = async (status: string) => {
    try {
        const req = new professionalProfilePb.ListPlacesRequest();
        req.setStatus(status);

        const res = await professionalProfileService.listPlaces(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListServiceIdPlace = async (selectedService: any, selectedLocal: any) => {
    try {
        const req = new professionalProfilePb.ListServiceIdPlaceRequest();
        req.setAddressplaceid(selectedLocal.value.id);
        req.setServiceid(selectedService.value.id);

        const res = await professionalProfileService.listServiceIdPlace(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListPlacesActives = async () => {
    try {
        const req = new professionalProfilePb.EmptyRequest();

        const res = await professionalProfileService.listPlacesActives(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListDays = async () => {
    try {
        const req = new professionalProfilePb.EmptyRequest();

        const res = await professionalProfileService.listDays(req);
        console.log("res do ListDays", res)

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListServicesToPlace = async (selectedLocalMain: any) => {
    try {
        const req = new professionalProfilePb.ListServicesToPlaceRequest();
        req.setAddressplaceid(selectedLocalMain?.id);

        const res = await professionalProfileService.listServicesToPlace(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const GetPartnerships = async (word: string) => {
    try {
        const req = new professionalProfilePb.GetPartnershipsRequest();
        req.setWord(word);

        const res = await professionalProfileService.getPartnerships(req);

        return res;
    } catch (error) {
        console.log(error);
        validateNoAuthorization(error);
    }
};


const ListPartnerships = async () => {
    try {
        const req = new professionalProfilePb.EmptyRequest();

        const res = await professionalProfileService.listPartnerships(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListAvailableScheduleHistory = async (obj: any) => {
    try {
        const req = new professionalProfilePb.ListAvailableScheduleHistoryRequest();
        req.setStatus(obj.status);
        req.setWord(obj.word);

        const res = await professionalProfileService.listAvailableScheduleHistory(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const ListBlockedAvailableSchedule = async (word: string) => {
    try {
        const req = new professionalProfilePb.ListBlockedAvailableScheduleRequest();
        req.setWord(word);

        const res = await professionalProfileService.listBlockedAvailableSchedule(req);

        return res;
    } catch (error) {
        console.log(error);
    }
};


export {
    CreateAvailableSchedule,
    GetInfo,
    ListProfessionalsMedicalRecords,
    UpdateInfo,
    ListCouncil,
    AddCouncil,
    DeleteCouncil,
    AddSpeciality,
    DeleteSpeciality,
    UpdateName,
    ListPlaces,
    ListServiceIdPlace,
    ListPlacesActives,
    ListDays,
    ListServicesToPlace,
    GetPartnerships,
    ListPartnerships,
    ListAvailableScheduleHistory,
    ListBlockedAvailableSchedule,
    DeleteAvailableScheduleHistory,
};