import professionalAccountPb from "../grpc/generated/professionalAccount_pb";
import professionalAccountService from "../services/professionalAccountService"
import router from "../../../router";

interface ILogin {
    login: string;
    password: string;
}

const Login = async (formData: ILogin) => {
    try {
        const req = new professionalAccountPb.LoginRequest();
        req.setUser(formData.login);
        req.setPassword(formData.password);

        const res = await professionalAccountService.login(req);
        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const Register = async (formData: any) => {
    try {
        const req = new professionalAccountPb.RegisterRequest();
        req.setFirstname(formData.first_name);
        req.setLastname(formData.last_name);
        req.setEmail(formData.email);
        req.setCountrycode(formData.country_code);
        req.setPhone(formData.phone);
        req.setPassword(formData.password);
        req.setConfirm(formData.confirm_pass);

        const res = await professionalAccountService.register(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SaveInfo = async (formData: any) => {
    try {
        const req = new professionalAccountPb.SaveInfoAfterUserCloseBrowserPageOneRequest();
        req.setFirstname(formData.first_name);
        req.setLastname(formData.last_name);
        req.setEmail(formData.email);
        req.setCountrycode(formData.country_code);
        req.setPhone(formData.phone);

        const res = await professionalAccountService.saveInfoAfterUserCloseBrowserPageOne(req);

        console.log(res);        
        localStorage.setItem('formData', JSON.stringify(formData));
        return res;
    } catch (error) {
        console.log(error);
    }
}

const PhoneConfirmation = async (phone: any ) => {
    const request = new professionalAccountPb.SendPhoneConfirmationRequest();
    request.setPhone(phone);

    const response = await professionalAccountService.sendPhoneConfirmation(
      request
    );

    if (response.success == true) {
      router.push("/account/phone/confirmation");
    }
};


const UpdatePhone = async (obj) => {
    try {
        const req = new professionalAccountPb.UpdatePhoneRequest();
        req.setCountrycode(obj.code);
        req.setPhone(obj.phone);

        const res = await professionalAccountService.updatePhone(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}


const VerifyPhone = async (obj) => {
    try {
        const req = new professionalAccountPb.VerifyPhoneRequest();
        req.setPhone(obj.phone);
        req.setCode(obj.code);

        const res = await professionalAccountService.verifyPhone(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const VerifyToken = async () => {
    try {
        const req = new professionalAccountPb.EmptyRequest();

        const res = await professionalAccountService.verifyToken(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SendEmailConfirmation = async (email: string) => {
    try {
        const req = new professionalAccountPb.SendEmailConfirmationRequest();
        req.setEmail(email);
        const res = await professionalAccountService.sendEmailConfirmation(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const UpdateEmail = async (email: string) => {
    try {
        const req = new professionalAccountPb.UpdateEmailRequest();
        req.setEmail(email);
        const res = await professionalAccountService.updateEmail(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

interface IConfirmExchangeEmail {
    code: string;
    phone: string;
}

const ConfirmExchangeEmail = async (obj: IConfirmExchangeEmail) => {
    try {
        const req = new professionalAccountPb.ConfirmExchangeEmailRequest();
        req.setCode(obj.code);
        req.setPhone(obj.phone);
        const res = await professionalAccountService.confirmExchangeEmail(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SendSMSConfirmEmail = async (phone: string) => {
    try {
        const req = new professionalAccountPb.SendSMSConfirmEmailRequest();
        req.setPhone(phone);

        const res = await professionalAccountService.sendSMSConfirmEmail(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

interface IVerifyEmail {
    email: string;
    code: string;
}

const VerifyEmail = async (obj: IVerifyEmail) => {
    try {
        const req = new professionalAccountPb.VerifyEmailRequest();
        req.setEmail(obj.email);
        req.setCode(obj.code)

        const res = await professionalAccountService.verifyEmail(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

interface IVerifyContinueRegister {
    email: string;
    token: string;
}

const VerifyContinueRegister = async (obj: IVerifyContinueRegister) => {
    try {
        const req = new professionalAccountPb.VerifyContinueRegisterRequest();
        req.setEmail(obj.email);
        req.setToken(obj.token)

        const res = await professionalAccountService.verifyContinueRegister(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}



export {    
    Login,
    Register,
    SaveInfo,
    PhoneConfirmation,
    UpdatePhone,
    VerifyPhone,
    VerifyToken,
    SendEmailConfirmation,
    UpdateEmail,
    ConfirmExchangeEmail,
    SendSMSConfirmEmail,
    VerifyEmail,
    VerifyContinueRegister,
}