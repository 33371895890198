async function validation(to: any, from: any, next: any) {
    if (localStorage.getItem("token_secondary")) {
        next()
    } else {
        next({ name: 'Appointment.Today' })
    }
}


export default {
    validation
}