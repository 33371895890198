import { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from 'vue-router';
import { 
    VerifyToken,
}  from "../modules/core/actions/professionalAccount"
import { GetInfo }  from "../modules/core/actions/professionalProfile"

// SCREEN 1
const AccountRegister = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    window.localStorage.clear();
    next();
}

// SCREEN 2
const AccountPhoneConfirmation = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();

    if(verifyToken?.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                const res = await GetInfo();
                console.log(res);
                if(res?.data){
                    const data = JSON.parse(res.data);
                    
                    localStorage.setItem('phone', data?.phone);
                    localStorage.setItem('email', data?.email);
                    next();
                }
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next({ name: 'Account.Records' });
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }else if(screen === 6){
                next({ name: 'Account.Presentation' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Dashboard'});
            }
        }
    }else{
        next({ name: 'Account' })
    }
}

// SCREEN 3
const AccountInfos = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();

    if(verifyToken?.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'AccountPhoneConfirmation' });
            }else if (screen === 3){
                next();
            }else if (screen === 4) {
                next({ name: 'Account.Records' });
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }else if(screen === 6){
                next({ name: 'Account.Presentation' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Dashboard'});
            }
        }
        
    }else{
        next({ name: 'Account' })
    }
}

// SCREEN 4
const AccountRecords = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();

    if(verifyToken?.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'AccountPhoneConfirmation' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next();
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }else if(screen === 6){
                next({ name: 'Account.Presentation' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Dashboard'});
            }
        }
    }else{
        next({ name: 'Account' })
    }
}

// SCREEN 5
const AccountUpload = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();
    
    if(verifyToken?.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'AccountPhoneConfirmation' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next({ name: 'Account.Records' });
            }else if (screen === 5){
                next();
            }else if(screen === 6){
                next({ name: 'Account.Presentation' });
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Dashboard'});
            }
        }

    } else{
        next({ name: 'Account' })
    }
}

// SCREEN 6
const AccountPresentation = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();
    
    if(verifyToken?.success){
        const data = JSON.parse(verifyToken.data);

        if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'AccountPhoneConfirmation' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next({ name: 'Account.Records' });
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }else if(screen === 6){
                next();
            }
        } else if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next({ name: 'Account.Login' })
            }else{
                next({ name: 'Dashboard'});
            }
        }

    }else{
        next({ name: 'Account' })
    }
}

const SetPhoneAndEmailFromGetInfo = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();
    
    if(verifyToken?.success){
        // const data = JSON.parse(verifyToken.data);

        const res = await GetInfo();
        // console.log(res);
        if(res?.data){
            const data = JSON.parse(res.data);
            
            localStorage.setItem('phone', data?.phone);
            localStorage.setItem('email', data?.email)
        }

        next()
    }else{
        next({ name: 'Account' })
    }
}

const SetPhone = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();
    
    if(verifyToken?.success){
        // const data = JSON.parse(verifyToken.data);

        const res = await GetInfo();
        // console.log(res);
        if(res?.data){
            const data = JSON.parse(res.data);
            
            localStorage.setItem('phone', data?.phone);
        }

        next()
    }else{
        next({ name: 'Account' })
    }
}


const AccountEmailConfirm = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const verifyToken = await VerifyToken();
    
    if(verifyToken?.success){
        const data = JSON.parse(verifyToken.data);

        if('emailConfirmed' in data){
            const emailConfirmed = JSON.parse(verifyToken.data).emailConfirmed;

            if(!emailConfirmed){
                next()
            }else{
                next({ name: 'Dashboard'});
            }
        }else if('screen' in data){
            const { screen } = data;

            if(screen === 2){
                next({ name: 'AccountPhoneConfirmation' });
            }else if ( screen === 3 ){
                next({ name: 'Account.Infos' });
            }else if (screen === 4) {
                next({ name: 'Account.Records' });
            }else if (screen === 5){
                next({ name: 'Account.UploadDocument' });
            }else if(screen === 6){
                next({ name: 'Account.Presentation' });
            }
        } 

    }else{
        next({ name: 'Account' })
    }
}


export default {
    AccountPhoneConfirmation,
    AccountInfos,
    AccountRecords,
    AccountUpload,
    AccountRegister,
    AccountPresentation,
    SetPhoneAndEmailFromGetInfo,
    SetPhone,
    AccountEmailConfirm,
}