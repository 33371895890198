/**
 * @fileoverview gRPC-Web generated client stub for professionalAccountApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: professionalAccount.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.professionalAccountApi = require('./professionalAccount_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.RegisterRequest,
 *   !proto.professionalAccountApi.RegisterResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_Register = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/Register',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.RegisterRequest,
  proto.professionalAccountApi.RegisterResponse,
  /**
   * @param {!proto.professionalAccountApi.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/Register',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_Register,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/Register',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.LoginRequest,
 *   !proto.professionalAccountApi.LoginResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_Login = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/Login',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.LoginRequest,
  proto.professionalAccountApi.LoginResponse,
  /**
   * @param {!proto.professionalAccountApi.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/Login',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_Login,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/Login',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.EmptyRequest,
 *   !proto.professionalAccountApi.VerifyTokenResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_VerifyToken = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/VerifyToken',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.EmptyRequest,
  proto.professionalAccountApi.VerifyTokenResponse,
  /**
   * @param {!proto.professionalAccountApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.VerifyTokenResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.VerifyTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.VerifyTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.verifyToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyToken',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyToken,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.VerifyTokenResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.verifyToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyToken',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.SendEmailConfirmationRequest,
 *   !proto.professionalAccountApi.SendEmailConfirmationResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_SendEmailConfirmation = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/SendEmailConfirmation',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.SendEmailConfirmationRequest,
  proto.professionalAccountApi.SendEmailConfirmationResponse,
  /**
   * @param {!proto.professionalAccountApi.SendEmailConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.SendEmailConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.SendEmailConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.SendEmailConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.SendEmailConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.sendEmailConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendEmailConfirmation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendEmailConfirmation,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.SendEmailConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.SendEmailConfirmationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.sendEmailConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendEmailConfirmation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendEmailConfirmation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.SendPhoneConfirmationRequest,
 *   !proto.professionalAccountApi.SendPhoneConfirmationResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_SendPhoneConfirmation = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/SendPhoneConfirmation',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.SendPhoneConfirmationRequest,
  proto.professionalAccountApi.SendPhoneConfirmationResponse,
  /**
   * @param {!proto.professionalAccountApi.SendPhoneConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.SendPhoneConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.SendPhoneConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.SendPhoneConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.SendPhoneConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.sendPhoneConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendPhoneConfirmation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendPhoneConfirmation,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.SendPhoneConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.SendPhoneConfirmationResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.sendPhoneConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendPhoneConfirmation',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendPhoneConfirmation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.VerifyEmailRequest,
 *   !proto.professionalAccountApi.VerifyEmailResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_VerifyEmail = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.VerifyEmailRequest,
  proto.professionalAccountApi.VerifyEmailResponse,
  /**
   * @param {!proto.professionalAccountApi.VerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.VerifyEmailResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.VerifyEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.VerifyEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyEmail,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.VerifyEmailResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.VerifyPhoneRequest,
 *   !proto.professionalAccountApi.VerifyPhoneResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_VerifyPhone = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/VerifyPhone',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.VerifyPhoneRequest,
  proto.professionalAccountApi.VerifyPhoneResponse,
  /**
   * @param {!proto.professionalAccountApi.VerifyPhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.VerifyPhoneResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.VerifyPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.VerifyPhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.VerifyPhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.verifyPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyPhone',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyPhone,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.VerifyPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.VerifyPhoneResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.verifyPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyPhone',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.ValidateEmailRequest,
 *   !proto.professionalAccountApi.ValidateEmailResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_ValidateEmail = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/ValidateEmail',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.ValidateEmailRequest,
  proto.professionalAccountApi.ValidateEmailResponse,
  /**
   * @param {!proto.professionalAccountApi.ValidateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.ValidateEmailResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.ValidateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.ValidateEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.ValidateEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.validateEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/ValidateEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_ValidateEmail,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.ValidateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.ValidateEmailResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.validateEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/ValidateEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_ValidateEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.ValidatePhoneRequest,
 *   !proto.professionalAccountApi.ValidatePhoneResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_ValidatePhone = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/ValidatePhone',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.ValidatePhoneRequest,
  proto.professionalAccountApi.ValidatePhoneResponse,
  /**
   * @param {!proto.professionalAccountApi.ValidatePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.ValidatePhoneResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.ValidatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.ValidatePhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.ValidatePhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.validatePhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/ValidatePhone',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_ValidatePhone,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.ValidatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.ValidatePhoneResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.validatePhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/ValidatePhone',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_ValidatePhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.UpdatePhoneRequest,
 *   !proto.professionalAccountApi.UpdatePhoneResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_UpdatePhone = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/UpdatePhone',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.UpdatePhoneRequest,
  proto.professionalAccountApi.UpdatePhoneResponse,
  /**
   * @param {!proto.professionalAccountApi.UpdatePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.UpdatePhoneResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.UpdatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.UpdatePhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.UpdatePhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.updatePhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/UpdatePhone',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_UpdatePhone,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.UpdatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.UpdatePhoneResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.updatePhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/UpdatePhone',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_UpdatePhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.VerifyContinueRegisterRequest,
 *   !proto.professionalAccountApi.VerifyContinueRegisterResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_VerifyContinueRegister = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/VerifyContinueRegister',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.VerifyContinueRegisterRequest,
  proto.professionalAccountApi.VerifyContinueRegisterResponse,
  /**
   * @param {!proto.professionalAccountApi.VerifyContinueRegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.VerifyContinueRegisterResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.VerifyContinueRegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.VerifyContinueRegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.VerifyContinueRegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.verifyContinueRegister =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyContinueRegister',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyContinueRegister,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.VerifyContinueRegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.VerifyContinueRegisterResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.verifyContinueRegister =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyContinueRegister',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyContinueRegister);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailRequest,
 *   !proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_SendPasswordRecoveryCodeToEmail = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/SendPasswordRecoveryCodeToEmail',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailRequest,
  proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailResponse,
  /**
   * @param {!proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.sendPasswordRecoveryCodeToEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendPasswordRecoveryCodeToEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendPasswordRecoveryCodeToEmail,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.SendPasswordRecoveryCodeToEmailResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.sendPasswordRecoveryCodeToEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendPasswordRecoveryCodeToEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendPasswordRecoveryCodeToEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.FindUserAccountRequest,
 *   !proto.professionalAccountApi.FindUserAccountResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_FindUserAccount = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/FindUserAccount',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.FindUserAccountRequest,
  proto.professionalAccountApi.FindUserAccountResponse,
  /**
   * @param {!proto.professionalAccountApi.FindUserAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.FindUserAccountResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.FindUserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.FindUserAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.FindUserAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.findUserAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/FindUserAccount',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_FindUserAccount,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.FindUserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.FindUserAccountResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.findUserAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/FindUserAccount',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_FindUserAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.SendPasswordRecoveryCodeToUserRequest,
 *   !proto.professionalAccountApi.SendPasswordRecoveryCodeToUserResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_SendPasswordRecoveryCodeToUser = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/SendPasswordRecoveryCodeToUser',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.SendPasswordRecoveryCodeToUserRequest,
  proto.professionalAccountApi.SendPasswordRecoveryCodeToUserResponse,
  /**
   * @param {!proto.professionalAccountApi.SendPasswordRecoveryCodeToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.SendPasswordRecoveryCodeToUserResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.SendPasswordRecoveryCodeToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.SendPasswordRecoveryCodeToUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.SendPasswordRecoveryCodeToUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.sendPasswordRecoveryCodeToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendPasswordRecoveryCodeToUser',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendPasswordRecoveryCodeToUser,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.SendPasswordRecoveryCodeToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.SendPasswordRecoveryCodeToUserResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.sendPasswordRecoveryCodeToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendPasswordRecoveryCodeToUser',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendPasswordRecoveryCodeToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.VerifyPasswordRecoveryCodeRequest,
 *   !proto.professionalAccountApi.VerifyPasswordRecoveryCodeResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_VerifyPasswordRecoveryCode = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/VerifyPasswordRecoveryCode',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.VerifyPasswordRecoveryCodeRequest,
  proto.professionalAccountApi.VerifyPasswordRecoveryCodeResponse,
  /**
   * @param {!proto.professionalAccountApi.VerifyPasswordRecoveryCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.VerifyPasswordRecoveryCodeResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.VerifyPasswordRecoveryCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.VerifyPasswordRecoveryCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.VerifyPasswordRecoveryCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.verifyPasswordRecoveryCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyPasswordRecoveryCode',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyPasswordRecoveryCode,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.VerifyPasswordRecoveryCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.VerifyPasswordRecoveryCodeResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.verifyPasswordRecoveryCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/VerifyPasswordRecoveryCode',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_VerifyPasswordRecoveryCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.UpdatePasswordRequest,
 *   !proto.professionalAccountApi.UpdatePasswordResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_UpdatePassword = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/UpdatePassword',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.UpdatePasswordRequest,
  proto.professionalAccountApi.UpdatePasswordResponse,
  /**
   * @param {!proto.professionalAccountApi.UpdatePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.UpdatePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.UpdatePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.UpdatePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.updatePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_UpdatePassword,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.UpdatePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.updatePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_UpdatePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest,
 *   !proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_SaveInfoAfterUserCloseBrowserPageOne = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/SaveInfoAfterUserCloseBrowserPageOne',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest,
  proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse,
  /**
   * @param {!proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.saveInfoAfterUserCloseBrowserPageOne =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SaveInfoAfterUserCloseBrowserPageOne',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SaveInfoAfterUserCloseBrowserPageOne,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.SaveInfoAfterUserCloseBrowserPageOneResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.saveInfoAfterUserCloseBrowserPageOne =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SaveInfoAfterUserCloseBrowserPageOne',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SaveInfoAfterUserCloseBrowserPageOne);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.GetInfoProfessionalRequest,
 *   !proto.professionalAccountApi.GetInfoProfessionalResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_GetInfoProfessional = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/GetInfoProfessional',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.GetInfoProfessionalRequest,
  proto.professionalAccountApi.GetInfoProfessionalResponse,
  /**
   * @param {!proto.professionalAccountApi.GetInfoProfessionalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.GetInfoProfessionalResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.GetInfoProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.GetInfoProfessionalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.GetInfoProfessionalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.getInfoProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/GetInfoProfessional',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_GetInfoProfessional,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.GetInfoProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.GetInfoProfessionalResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.getInfoProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/GetInfoProfessional',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_GetInfoProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.UpdateEmailRequest,
 *   !proto.professionalAccountApi.UpdateEmailResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_UpdateEmail = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/UpdateEmail',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.UpdateEmailRequest,
  proto.professionalAccountApi.UpdateEmailResponse,
  /**
   * @param {!proto.professionalAccountApi.UpdateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.UpdateEmailResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.UpdateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.UpdateEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.UpdateEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.updateEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/UpdateEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_UpdateEmail,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.UpdateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.UpdateEmailResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.updateEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/UpdateEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_UpdateEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.ConfirmExchangeEmailRequest,
 *   !proto.professionalAccountApi.ConfirmExchangeEmailResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_ConfirmExchangeEmail = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/ConfirmExchangeEmail',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.ConfirmExchangeEmailRequest,
  proto.professionalAccountApi.ConfirmExchangeEmailResponse,
  /**
   * @param {!proto.professionalAccountApi.ConfirmExchangeEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.ConfirmExchangeEmailResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.ConfirmExchangeEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.ConfirmExchangeEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.ConfirmExchangeEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.confirmExchangeEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/ConfirmExchangeEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_ConfirmExchangeEmail,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.ConfirmExchangeEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.ConfirmExchangeEmailResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.confirmExchangeEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/ConfirmExchangeEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_ConfirmExchangeEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.professionalAccountApi.SendSMSConfirmEmailRequest,
 *   !proto.professionalAccountApi.SendSMSConfirmEmailResponse>}
 */
const methodDescriptor_ProfessionalAccountProtoService_SendSMSConfirmEmail = new grpc.web.MethodDescriptor(
  '/professionalAccountApi.ProfessionalAccountProtoService/SendSMSConfirmEmail',
  grpc.web.MethodType.UNARY,
  proto.professionalAccountApi.SendSMSConfirmEmailRequest,
  proto.professionalAccountApi.SendSMSConfirmEmailResponse,
  /**
   * @param {!proto.professionalAccountApi.SendSMSConfirmEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.professionalAccountApi.SendSMSConfirmEmailResponse.deserializeBinary
);


/**
 * @param {!proto.professionalAccountApi.SendSMSConfirmEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.professionalAccountApi.SendSMSConfirmEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.professionalAccountApi.SendSMSConfirmEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.professionalAccountApi.ProfessionalAccountProtoServiceClient.prototype.sendSMSConfirmEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendSMSConfirmEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendSMSConfirmEmail,
      callback);
};


/**
 * @param {!proto.professionalAccountApi.SendSMSConfirmEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.professionalAccountApi.SendSMSConfirmEmailResponse>}
 *     Promise that resolves to the response
 */
proto.professionalAccountApi.ProfessionalAccountProtoServicePromiseClient.prototype.sendSMSConfirmEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/professionalAccountApi.ProfessionalAccountProtoService/SendSMSConfirmEmail',
      request,
      metadata || {},
      methodDescriptor_ProfessionalAccountProtoService_SendSMSConfirmEmail);
};


module.exports = proto.professionalAccountApi;

