// source: professionalProfile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.professionalProfileApi.ActionAct', null, global);
goog.exportSymbol('proto.professionalProfileApi.ActionActuation', null, global);
goog.exportSymbol('proto.professionalProfileApi.ActionActuationListAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ActionActuationProf', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddAwardRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddAwardResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddBlockedAvailableScheduleRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddBlockedAvailableScheduleResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddCouncilRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddCouncilResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddDayRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddDayResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddDiseaseRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddDiseaseResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddExperienceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddExperienceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddFormationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddFormationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddIdiomRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddIdiomResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddLocationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddLocationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddPartnershipRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddPartnershipResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddPublicationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddPublicationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddServiceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddServiceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddServiceTypeRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddServiceTypeResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddSpecialtyRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddSpecialtyResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.Address', null, global);
goog.exportSymbol('proto.professionalProfileApi.AddressInfo', null, global);
goog.exportSymbol('proto.professionalProfileApi.AvailableDaysId', null, global);
goog.exportSymbol('proto.professionalProfileApi.AvailableIdProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.AvailableProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.AvailableScheduleHasDayProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.AvailableScheduleHistory', null, global);
goog.exportSymbol('proto.professionalProfileApi.Blockeds', null, global);
goog.exportSymbol('proto.professionalProfileApi.ChangeStatusToInactiveRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ChangeStatusToInactiveResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.CityProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.Council', null, global);
goog.exportSymbol('proto.professionalProfileApi.CouncilProf', null, global);
goog.exportSymbol('proto.professionalProfileApi.CouncilProfessional', null, global);
goog.exportSymbol('proto.professionalProfileApi.CreateAvailableScheduleRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.CreateAvailableScheduleResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DateInfo', null, global);
goog.exportSymbol('proto.professionalProfileApi.Days', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteActionActuationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteActionActuationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteAwardRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteAwardResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteCouncilRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteCouncilResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteDiseaseRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteDiseaseResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteExperienceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteExperienceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteFormationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteFormationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteIdiomRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteIdiomResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeletePartnershipRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeletePartnershipResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeletePlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeletePlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeletePublicationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeletePublicationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteSpecialtyRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.DeleteSpecialtyResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.Diseases', null, global);
goog.exportSymbol('proto.professionalProfileApi.DiseasesProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.DisplayMessageResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.EditActionActuationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.EditActionActuationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.EmptyRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.FirstListing', null, global);
goog.exportSymbol('proto.professionalProfileApi.GeneratedDaysProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetInfoResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetPartner', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetPartnerRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetPartnerResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetPartnershipsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetPartnershipsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetServiceTypeInformationsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.GetServiceTypeInformationsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.Holiday', null, global);
goog.exportSymbol('proto.professionalProfileApi.Holidays', null, global);
goog.exportSymbol('proto.professionalProfileApi.Hour', null, global);
goog.exportSymbol('proto.professionalProfileApi.InfoProf', null, global);
goog.exportSymbol('proto.professionalProfileApi.InfoProfessionals', null, global);
goog.exportSymbol('proto.professionalProfileApi.InfoProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListActiveRegistriesRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListActiveRegistriesResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableInfo', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleHistoryRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleHistoryResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleId', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleIdRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleIdResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAvailableScheduleResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListAward', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListBlockedAvailableScheduleRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListBlockedAvailableScheduleResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListCertificate', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListColorsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListCouncil', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListCouncilRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListCouncilResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListDaysConflicts', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListDaysResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListDisease', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListExperience', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListFormation', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListLanguage', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListLastsServicesRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListLastsServicesResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListOfBlockedDay', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListOfDayAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListOfDays', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPartnershipsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPlace', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPlaceAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPlaceOfServiceMode', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPlacesActivesResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPlacesRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPlacesResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalAvailableRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalAvailableResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalProfileResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListPublication', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListService', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceIdLocationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceIdLocationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceIdPlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceIdPlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceIdRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceIdResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceTypeAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceTypes', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServiceTypesDistinct', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServicesPlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServicesPlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServicesToPlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListServicesToPlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListToGenerateSugestion', null, global);
goog.exportSymbol('proto.professionalProfileApi.LocationPatient', null, global);
goog.exportSymbol('proto.professionalProfileApi.NamesProfessional', null, global);
goog.exportSymbol('proto.professionalProfileApi.Place', null, global);
goog.exportSymbol('proto.professionalProfileApi.PlaceName', null, global);
goog.exportSymbol('proto.professionalProfileApi.Professional', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionalPartner', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionalProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionalServiceTypes', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionalServiceTypesPlace', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionalsProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.Professions', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfessionsProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfileOfProfessional', null, global);
goog.exportSymbol('proto.professionalProfileApi.ProfileOfProfessionalAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchActiveServiceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchActiveServiceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchAppointmentRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchAppointmentResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchListPartnershipsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchListPartnershipsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchListPlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchListPlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchListServiceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchListServiceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchProfessionalsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.SearchProfessionalsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.SecondListing', null, global);
goog.exportSymbol('proto.professionalProfileApi.SeeAsPatientResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.Service', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceHasSpecialities', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceHistory', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceInformations', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceList', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceListIdPlace', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceListLocation', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceListPlace', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceLocations', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceName', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServicePlaces', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServicePlacesListAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceTypeInfo', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceTypeProto', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceTypes', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceTypesHistory', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceTypesListAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.ServiceTypesOffered', null, global);
goog.exportSymbol('proto.professionalProfileApi.Spec', null, global);
goog.exportSymbol('proto.professionalProfileApi.Specialities', null, global);
goog.exportSymbol('proto.professionalProfileApi.SpecialitiesList', null, global);
goog.exportSymbol('proto.professionalProfileApi.SpecialitiesListAvailable', null, global);
goog.exportSymbol('proto.professionalProfileApi.SpecialitiesPartner', null, global);
goog.exportSymbol('proto.professionalProfileApi.SpecialitiesProf', null, global);
goog.exportSymbol('proto.professionalProfileApi.Speciality', null, global);
goog.exportSymbol('proto.professionalProfileApi.SpecialityHistory', null, global);
goog.exportSymbol('proto.professionalProfileApi.Sugestion', null, global);
goog.exportSymbol('proto.professionalProfileApi.SugestionPlace', null, global);
goog.exportSymbol('proto.professionalProfileApi.SugestionService', null, global);
goog.exportSymbol('proto.professionalProfileApi.SugestionServiceTypes', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateAwardRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateAwardResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateBiographyRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateBiographyResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateExperienceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateExperienceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateFormationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateFormationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateInfoRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateInfoResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateNameRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateNameResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdatePlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdatePlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateProfessionalServiceTypes', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdatePublicationRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdatePublicationResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateServiceHasSpecialities', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateServiceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateServiceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateServiceTypeRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateServiceTypeResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateServiceTypes', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateStatusPlaceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateStatusPlaceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateStatusServiceRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateStatusServiceResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateVisiblesRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.UpdateVisiblesResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyChangeStatusToActiveRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyChangeStatusToActiveResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyHolidayRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyHolidayResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyMinTimeRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyMinTimeResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyUfActiveRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.VerifyUfActiveResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListBlockedAvailableScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.displayName = 'proto.professionalProfileApi.ListBlockedAvailableScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GeneratedDaysProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GeneratedDaysProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GeneratedDaysProto.displayName = 'proto.professionalProfileApi.GeneratedDaysProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListDaysConflicts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListDaysConflicts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListDaysConflicts.displayName = 'proto.professionalProfileApi.ListDaysConflicts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GetServiceTypeInformationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetServiceTypeInformationsRequest.displayName = 'proto.professionalProfileApi.GetServiceTypeInformationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GetServiceTypeInformationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetServiceTypeInformationsResponse.displayName = 'proto.professionalProfileApi.GetServiceTypeInformationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceInformations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServiceInformations.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServiceInformations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceInformations.displayName = 'proto.professionalProfileApi.ServiceInformations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddressInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddressInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddressInfo.displayName = 'proto.professionalProfileApi.AddressInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SpecialitiesProf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SpecialitiesProf.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SpecialitiesProf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SpecialitiesProf.displayName = 'proto.professionalProfileApi.SpecialitiesProf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ActionActuationProf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ActionActuationProf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ActionActuationProf.displayName = 'proto.professionalProfileApi.ActionActuationProf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListToGenerateSugestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListToGenerateSugestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListToGenerateSugestion.displayName = 'proto.professionalProfileApi.ListToGenerateSugestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalAvailableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalAvailableRequest.displayName = 'proto.professionalProfileApi.ListProfessionalAvailableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalAvailableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalAvailableResponse.displayName = 'proto.professionalProfileApi.ListProfessionalAvailableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ProfileOfProfessionalAvailable.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ProfileOfProfessionalAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfileOfProfessionalAvailable.displayName = 'proto.professionalProfileApi.ProfileOfProfessionalAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AvailableProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.AvailableProto.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.AvailableProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AvailableProto.displayName = 'proto.professionalProfileApi.AvailableProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.DateInfo.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.DateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DateInfo.displayName = 'proto.professionalProfileApi.DateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Hour = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Hour, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Hour.displayName = 'proto.professionalProfileApi.Hour';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceTypesDistinct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceTypesDistinct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceTypesDistinct.displayName = 'proto.professionalProfileApi.ListServiceTypesDistinct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServiceAvailable.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceAvailable.displayName = 'proto.professionalProfileApi.ListServiceAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceTypeAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServiceTypeAvailable.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceTypeAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceTypeAvailable.displayName = 'proto.professionalProfileApi.ListServiceTypeAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPlaceOfServiceMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListPlaceOfServiceMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPlaceOfServiceMode.displayName = 'proto.professionalProfileApi.ListPlaceOfServiceMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPlaceAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListPlaceAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPlaceAvailable.displayName = 'proto.professionalProfileApi.ListPlaceAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Sugestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.Sugestion.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.Sugestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Sugestion.displayName = 'proto.professionalProfileApi.Sugestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SugestionService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SugestionService.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SugestionService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SugestionService.displayName = 'proto.professionalProfileApi.SugestionService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SugestionServiceTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SugestionServiceTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SugestionServiceTypes.displayName = 'proto.professionalProfileApi.SugestionServiceTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SugestionPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SugestionPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SugestionPlace.displayName = 'proto.professionalProfileApi.SugestionPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionalsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ProfessionalsProto.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionalsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionalsProto.displayName = 'proto.professionalProfileApi.ProfessionalsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPlace.displayName = 'proto.professionalProfileApi.ListPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListCouncil = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListCouncil.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListCouncil, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListCouncil.displayName = 'proto.professionalProfileApi.ListCouncil';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchAppointmentRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchAppointmentRequest.displayName = 'proto.professionalProfileApi.SearchAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchAppointmentResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchAppointmentResponse.displayName = 'proto.professionalProfileApi.SearchAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.LocationPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.LocationPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.LocationPatient.displayName = 'proto.professionalProfileApi.LocationPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SecondListing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SecondListing.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SecondListing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SecondListing.displayName = 'proto.professionalProfileApi.SecondListing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceTypeProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceTypeProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceTypeProto.displayName = 'proto.professionalProfileApi.ServiceTypeProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.InfoProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.InfoProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.InfoProto.displayName = 'proto.professionalProfileApi.InfoProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.CityProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.CityProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.CityProto.displayName = 'proto.professionalProfileApi.CityProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.FirstListing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.FirstListing.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.FirstListing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.FirstListing.displayName = 'proto.professionalProfileApi.FirstListing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DiseasesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DiseasesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DiseasesProto.displayName = 'proto.professionalProfileApi.DiseasesProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionsProto.displayName = 'proto.professionalProfileApi.ProfessionsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetPartnershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GetPartnershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetPartnershipsRequest.displayName = 'proto.professionalProfileApi.GetPartnershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetPartnershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.GetPartnershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.GetPartnershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetPartnershipsResponse.displayName = 'proto.professionalProfileApi.GetPartnershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionalProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionalProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionalProto.displayName = 'proto.professionalProfileApi.ProfessionalProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.EmptyRequest.displayName = 'proto.professionalProfileApi.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GetInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetInfoResponse.displayName = 'proto.professionalProfileApi.GetInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateInfoRequest.displayName = 'proto.professionalProfileApi.UpdateInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateInfoResponse.displayName = 'proto.professionalProfileApi.UpdateInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddCouncilRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddCouncilRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddCouncilRequest.displayName = 'proto.professionalProfileApi.AddCouncilRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.NamesProfessional = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.NamesProfessional, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.NamesProfessional.displayName = 'proto.professionalProfileApi.NamesProfessional';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddCouncilResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddCouncilResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddCouncilResponse.displayName = 'proto.professionalProfileApi.AddCouncilResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteCouncilRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteCouncilRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteCouncilRequest.displayName = 'proto.professionalProfileApi.DeleteCouncilRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteCouncilResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteCouncilResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteCouncilResponse.displayName = 'proto.professionalProfileApi.DeleteCouncilResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddSpecialtyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddSpecialtyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddSpecialtyRequest.displayName = 'proto.professionalProfileApi.AddSpecialtyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddSpecialtyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddSpecialtyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddSpecialtyResponse.displayName = 'proto.professionalProfileApi.AddSpecialtyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteSpecialtyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteSpecialtyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteSpecialtyRequest.displayName = 'proto.professionalProfileApi.DeleteSpecialtyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteSpecialtyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteSpecialtyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteSpecialtyResponse.displayName = 'proto.professionalProfileApi.DeleteSpecialtyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ActionActuation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ActionActuation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ActionActuation.displayName = 'proto.professionalProfileApi.ActionActuation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Speciality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.Speciality.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.Speciality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Speciality.displayName = 'proto.professionalProfileApi.Speciality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.CouncilProfessional = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.CouncilProfessional.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.CouncilProfessional, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.CouncilProfessional.displayName = 'proto.professionalProfileApi.CouncilProfessional';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListCouncilRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListCouncilRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListCouncilRequest.displayName = 'proto.professionalProfileApi.ListCouncilRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListCouncilResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListCouncilResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListCouncilResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListCouncilResponse.displayName = 'proto.professionalProfileApi.ListCouncilResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteActionActuationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteActionActuationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteActionActuationRequest.displayName = 'proto.professionalProfileApi.DeleteActionActuationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteActionActuationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteActionActuationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteActionActuationResponse.displayName = 'proto.professionalProfileApi.DeleteActionActuationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.EditActionActuationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.EditActionActuationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.EditActionActuationRequest.displayName = 'proto.professionalProfileApi.EditActionActuationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.EditActionActuationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.EditActionActuationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.EditActionActuationResponse.displayName = 'proto.professionalProfileApi.EditActionActuationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateNameRequest.displayName = 'proto.professionalProfileApi.UpdateNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateNameResponse.displayName = 'proto.professionalProfileApi.UpdateNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Professional = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Professional, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Professional.displayName = 'proto.professionalProfileApi.Professional';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalsRequest.displayName = 'proto.professionalProfileApi.ListProfessionalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListProfessionalsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalsResponse.displayName = 'proto.professionalProfileApi.ListProfessionalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddLocationRequest.displayName = 'proto.professionalProfileApi.AddLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddLocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddLocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddLocationResponse.displayName = 'proto.professionalProfileApi.AddLocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Address.displayName = 'proto.professionalProfileApi.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Council = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Council, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Council.displayName = 'proto.professionalProfileApi.Council';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListActiveRegistriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListActiveRegistriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListActiveRegistriesRequest.displayName = 'proto.professionalProfileApi.ListActiveRegistriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListActiveRegistriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListActiveRegistriesResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListActiveRegistriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListActiveRegistriesResponse.displayName = 'proto.professionalProfileApi.ListActiveRegistriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceHasSpecialities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceHasSpecialities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceHasSpecialities.displayName = 'proto.professionalProfileApi.ServiceHasSpecialities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionalServiceTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionalServiceTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionalServiceTypes.displayName = 'proto.professionalProfileApi.ProfessionalServiceTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.AddServiceRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.AddServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddServiceRequest.displayName = 'proto.professionalProfileApi.AddServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddServiceResponse.displayName = 'proto.professionalProfileApi.AddServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceRequest.displayName = 'proto.professionalProfileApi.ListServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServiceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceResponse.displayName = 'proto.professionalProfileApi.ListServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Specialities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Specialities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Specialities.displayName = 'proto.professionalProfileApi.Specialities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceTypes.displayName = 'proto.professionalProfileApi.ServiceTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceTypes.displayName = 'proto.professionalProfileApi.ListServiceTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListService.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListService.displayName = 'proto.professionalProfileApi.ListService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.Service.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Service.displayName = 'proto.professionalProfileApi.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchListServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SearchListServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchListServiceRequest.displayName = 'proto.professionalProfileApi.SearchListServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchListServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchListServiceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchListServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchListServiceResponse.displayName = 'proto.professionalProfileApi.SearchListServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceIdRequest.displayName = 'proto.professionalProfileApi.ListServiceIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceIdLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceIdLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceIdLocationRequest.displayName = 'proto.professionalProfileApi.ListServiceIdLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServiceIdResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceIdResponse.displayName = 'proto.professionalProfileApi.ListServiceIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceIdLocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServiceIdLocationResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceIdLocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceIdLocationResponse.displayName = 'proto.professionalProfileApi.ListServiceIdLocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServiceList.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServiceList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceList.displayName = 'proto.professionalProfileApi.ServiceList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceListLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServiceListLocation.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServiceListLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceListLocation.displayName = 'proto.professionalProfileApi.ServiceListLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.CouncilProf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.CouncilProf.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.CouncilProf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.CouncilProf.displayName = 'proto.professionalProfileApi.CouncilProf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Spec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.Spec.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.Spec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Spec.displayName = 'proto.professionalProfileApi.Spec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ActionAct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ActionAct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ActionAct.displayName = 'proto.professionalProfileApi.ActionAct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SpecialitiesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SpecialitiesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SpecialitiesList.displayName = 'proto.professionalProfileApi.SpecialitiesList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.UpdateServiceRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.UpdateServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateServiceRequest.displayName = 'proto.professionalProfileApi.UpdateServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateServiceResponse.displayName = 'proto.professionalProfileApi.UpdateServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateServiceHasSpecialities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateServiceHasSpecialities.displayName = 'proto.professionalProfileApi.UpdateServiceHasSpecialities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateProfessionalServiceTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateProfessionalServiceTypes.displayName = 'proto.professionalProfileApi.UpdateProfessionalServiceTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateStatusServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateStatusServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateStatusServiceRequest.displayName = 'proto.professionalProfileApi.UpdateStatusServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateStatusServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateStatusServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateStatusServiceResponse.displayName = 'proto.professionalProfileApi.UpdateStatusServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyUfActiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.VerifyUfActiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyUfActiveRequest.displayName = 'proto.professionalProfileApi.VerifyUfActiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyUfActiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.VerifyUfActiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyUfActiveResponse.displayName = 'proto.professionalProfileApi.VerifyUfActiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListLastsServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListLastsServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListLastsServicesRequest.displayName = 'proto.professionalProfileApi.ListLastsServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListLastsServicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListLastsServicesResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListLastsServicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListLastsServicesResponse.displayName = 'proto.professionalProfileApi.ListLastsServicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceName.displayName = 'proto.professionalProfileApi.ServiceName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchActiveServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SearchActiveServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchActiveServiceRequest.displayName = 'proto.professionalProfileApi.SearchActiveServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchActiveServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchActiveServiceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchActiveServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchActiveServiceResponse.displayName = 'proto.professionalProfileApi.SearchActiveServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionalServiceTypesPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionalServiceTypesPlace.displayName = 'proto.professionalProfileApi.ProfessionalServiceTypesPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddServiceTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.AddServiceTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.AddServiceTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddServiceTypeRequest.displayName = 'proto.professionalProfileApi.AddServiceTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddServiceTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddServiceTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddServiceTypeResponse.displayName = 'proto.professionalProfileApi.AddServiceTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPlacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListPlacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPlacesRequest.displayName = 'proto.professionalProfileApi.ListPlacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPlacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListPlacesResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListPlacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPlacesResponse.displayName = 'proto.professionalProfileApi.ListPlacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Place = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Place, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Place.displayName = 'proto.professionalProfileApi.Place';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdatePlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdatePlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdatePlaceRequest.displayName = 'proto.professionalProfileApi.UpdatePlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdatePlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdatePlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdatePlaceResponse.displayName = 'proto.professionalProfileApi.UpdatePlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateStatusPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateStatusPlaceRequest.displayName = 'proto.professionalProfileApi.UpdateStatusPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateStatusPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateStatusPlaceResponse.displayName = 'proto.professionalProfileApi.UpdateStatusPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeletePlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeletePlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeletePlaceRequest.displayName = 'proto.professionalProfileApi.DeletePlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeletePlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeletePlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeletePlaceResponse.displayName = 'proto.professionalProfileApi.DeletePlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServicesPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServicesPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServicesPlaceRequest.displayName = 'proto.professionalProfileApi.ListServicesPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServicesPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServicesPlaceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServicesPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServicesPlaceResponse.displayName = 'proto.professionalProfileApi.ListServicesPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceListPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServiceListPlace.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServiceListPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceListPlace.displayName = 'proto.professionalProfileApi.ServiceListPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchListPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SearchListPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchListPlaceRequest.displayName = 'proto.professionalProfileApi.SearchListPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchListPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchListPlaceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchListPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchListPlaceResponse.displayName = 'proto.professionalProfileApi.SearchListPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceIdPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceIdPlaceRequest.displayName = 'proto.professionalProfileApi.ListServiceIdPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServiceIdPlaceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServiceIdPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServiceIdPlaceResponse.displayName = 'proto.professionalProfileApi.ListServiceIdPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceListIdPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServiceListIdPlace.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServiceListIdPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceListIdPlace.displayName = 'proto.professionalProfileApi.ServiceListIdPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceTypesOffered = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceTypesOffered, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceTypesOffered.displayName = 'proto.professionalProfileApi.ServiceTypesOffered';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateServiceTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.UpdateServiceTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.UpdateServiceTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateServiceTypeRequest.displayName = 'proto.professionalProfileApi.UpdateServiceTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateServiceTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateServiceTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateServiceTypeResponse.displayName = 'proto.professionalProfileApi.UpdateServiceTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateServiceTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateServiceTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateServiceTypes.displayName = 'proto.professionalProfileApi.UpdateServiceTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ChangeStatusToInactiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ChangeStatusToInactiveRequest.displayName = 'proto.professionalProfileApi.ChangeStatusToInactiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ChangeStatusToInactiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ChangeStatusToInactiveResponse.displayName = 'proto.professionalProfileApi.ChangeStatusToInactiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.VerifyChangeStatusToActiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.displayName = 'proto.professionalProfileApi.VerifyChangeStatusToActiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.VerifyChangeStatusToActiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.displayName = 'proto.professionalProfileApi.VerifyChangeStatusToActiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListColorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListColorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListColorsResponse.displayName = 'proto.professionalProfileApi.ListColorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddBlockedAvailableScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.displayName = 'proto.professionalProfileApi.AddBlockedAvailableScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddBlockedAvailableScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.displayName = 'proto.professionalProfileApi.AddBlockedAvailableScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPlacesActivesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListPlacesActivesResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListPlacesActivesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPlacesActivesResponse.displayName = 'proto.professionalProfileApi.ListPlacesActivesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.PlaceName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.PlaceName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.PlaceName.displayName = 'proto.professionalProfileApi.PlaceName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServicesToPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListServicesToPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServicesToPlaceRequest.displayName = 'proto.professionalProfileApi.ListServicesToPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListServicesToPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListServicesToPlaceResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListServicesToPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListServicesToPlaceResponse.displayName = 'proto.professionalProfileApi.ListServicesToPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServicePlaces = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServicePlaces.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServicePlaces, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServicePlaces.displayName = 'proto.professionalProfileApi.ServicePlaces';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyMinTimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.VerifyMinTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyMinTimeRequest.displayName = 'proto.professionalProfileApi.VerifyMinTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyMinTimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.VerifyMinTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyMinTimeResponse.displayName = 'proto.professionalProfileApi.VerifyMinTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.CreateAvailableScheduleRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.CreateAvailableScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.CreateAvailableScheduleRequest.displayName = 'proto.professionalProfileApi.CreateAvailableScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Holiday = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Holiday, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Holiday.displayName = 'proto.professionalProfileApi.Holiday';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Holidays = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Holidays, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Holidays.displayName = 'proto.professionalProfileApi.Holidays';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AvailableScheduleHasDayProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AvailableScheduleHasDayProto.displayName = 'proto.professionalProfileApi.AvailableScheduleHasDayProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.displayName = 'proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.CreateAvailableScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.CreateAvailableScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.CreateAvailableScheduleResponse.displayName = 'proto.professionalProfileApi.CreateAvailableScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddDayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddDayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddDayRequest.displayName = 'proto.professionalProfileApi.AddDayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddDayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddDayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddDayResponse.displayName = 'proto.professionalProfileApi.AddDayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListOfDays = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListOfDays, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListOfDays.displayName = 'proto.professionalProfileApi.ListOfDays';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListDaysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListDaysResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListDaysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListDaysResponse.displayName = 'proto.professionalProfileApi.ListDaysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Days = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Days, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Days.displayName = 'proto.professionalProfileApi.Days';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleRequest.displayName = 'proto.professionalProfileApi.ListAvailableScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListAvailableScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleResponse.displayName = 'proto.professionalProfileApi.ListAvailableScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListOfBlockedDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListOfBlockedDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListOfBlockedDay.displayName = 'proto.professionalProfileApi.ListOfBlockedDay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListOfDayAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListOfDayAvailable.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListOfDayAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListOfDayAvailable.displayName = 'proto.professionalProfileApi.ListOfDayAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServicePlacesListAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServicePlacesListAvailable.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServicePlacesListAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServicePlacesListAvailable.displayName = 'proto.professionalProfileApi.ServicePlacesListAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceTypesListAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceTypesListAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceTypesListAvailable.displayName = 'proto.professionalProfileApi.ServiceTypesListAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SpecialitiesListAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SpecialitiesListAvailable.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SpecialitiesListAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SpecialitiesListAvailable.displayName = 'proto.professionalProfileApi.SpecialitiesListAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ActionActuationListAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ActionActuationListAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ActionActuationListAvailable.displayName = 'proto.professionalProfileApi.ActionActuationListAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListBlockedAvailableScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.displayName = 'proto.professionalProfileApi.ListBlockedAvailableScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Blockeds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Blockeds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Blockeds.displayName = 'proto.professionalProfileApi.Blockeds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.displayName = 'proto.professionalProfileApi.ListAvailableScheduleHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.displayName = 'proto.professionalProfileApi.ListAvailableScheduleHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AvailableScheduleHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.AvailableScheduleHistory.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.AvailableScheduleHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AvailableScheduleHistory.displayName = 'proto.professionalProfileApi.AvailableScheduleHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ServiceHistory.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ServiceHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceHistory.displayName = 'proto.professionalProfileApi.ServiceHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceTypesHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceTypesHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceTypesHistory.displayName = 'proto.professionalProfileApi.ServiceTypesHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SpecialityHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SpecialityHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SpecialityHistory.displayName = 'proto.professionalProfileApi.SpecialityHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.displayName = 'proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.displayName = 'proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleIdRequest.displayName = 'proto.professionalProfileApi.ListAvailableScheduleIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleIdResponse.displayName = 'proto.professionalProfileApi.ListAvailableScheduleIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableScheduleId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListAvailableScheduleId.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableScheduleId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableScheduleId.displayName = 'proto.professionalProfileApi.ListAvailableScheduleId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAvailableInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListAvailableInfo.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListAvailableInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAvailableInfo.displayName = 'proto.professionalProfileApi.ListAvailableInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceTypeInfo.displayName = 'proto.professionalProfileApi.ServiceTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AvailableDaysId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AvailableDaysId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AvailableDaysId.displayName = 'proto.professionalProfileApi.AvailableDaysId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyHolidayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.VerifyHolidayRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.VerifyHolidayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyHolidayRequest.displayName = 'proto.professionalProfileApi.VerifyHolidayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.VerifyHolidayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.VerifyHolidayResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.VerifyHolidayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.VerifyHolidayResponse.displayName = 'proto.professionalProfileApi.VerifyHolidayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AvailableIdProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AvailableIdProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AvailableIdProto.displayName = 'proto.professionalProfileApi.AvailableIdProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DisplayMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DisplayMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DisplayMessageResponse.displayName = 'proto.professionalProfileApi.DisplayMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddAwardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddAwardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddAwardRequest.displayName = 'proto.professionalProfileApi.AddAwardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddAwardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddAwardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddAwardResponse.displayName = 'proto.professionalProfileApi.AddAwardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateAwardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateAwardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateAwardRequest.displayName = 'proto.professionalProfileApi.UpdateAwardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateAwardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateAwardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateAwardResponse.displayName = 'proto.professionalProfileApi.UpdateAwardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteAwardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteAwardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteAwardRequest.displayName = 'proto.professionalProfileApi.DeleteAwardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteAwardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteAwardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteAwardResponse.displayName = 'proto.professionalProfileApi.DeleteAwardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddIdiomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddIdiomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddIdiomRequest.displayName = 'proto.professionalProfileApi.AddIdiomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddIdiomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddIdiomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddIdiomResponse.displayName = 'proto.professionalProfileApi.AddIdiomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteIdiomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteIdiomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteIdiomRequest.displayName = 'proto.professionalProfileApi.DeleteIdiomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteIdiomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteIdiomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteIdiomResponse.displayName = 'proto.professionalProfileApi.DeleteIdiomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddExperienceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddExperienceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddExperienceRequest.displayName = 'proto.professionalProfileApi.AddExperienceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddExperienceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddExperienceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddExperienceResponse.displayName = 'proto.professionalProfileApi.AddExperienceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateExperienceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateExperienceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateExperienceRequest.displayName = 'proto.professionalProfileApi.UpdateExperienceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateExperienceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateExperienceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateExperienceResponse.displayName = 'proto.professionalProfileApi.UpdateExperienceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteExperienceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteExperienceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteExperienceRequest.displayName = 'proto.professionalProfileApi.DeleteExperienceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteExperienceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteExperienceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteExperienceResponse.displayName = 'proto.professionalProfileApi.DeleteExperienceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddPublicationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddPublicationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddPublicationRequest.displayName = 'proto.professionalProfileApi.AddPublicationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddPublicationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddPublicationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddPublicationResponse.displayName = 'proto.professionalProfileApi.AddPublicationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdatePublicationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdatePublicationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdatePublicationRequest.displayName = 'proto.professionalProfileApi.UpdatePublicationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdatePublicationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdatePublicationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdatePublicationResponse.displayName = 'proto.professionalProfileApi.UpdatePublicationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeletePublicationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeletePublicationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeletePublicationRequest.displayName = 'proto.professionalProfileApi.DeletePublicationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeletePublicationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeletePublicationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeletePublicationResponse.displayName = 'proto.professionalProfileApi.DeletePublicationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddDiseaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddDiseaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddDiseaseRequest.displayName = 'proto.professionalProfileApi.AddDiseaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddDiseaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddDiseaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddDiseaseResponse.displayName = 'proto.professionalProfileApi.AddDiseaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteDiseaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteDiseaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteDiseaseRequest.displayName = 'proto.professionalProfileApi.DeleteDiseaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteDiseaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteDiseaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteDiseaseResponse.displayName = 'proto.professionalProfileApi.DeleteDiseaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddFormationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddFormationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddFormationRequest.displayName = 'proto.professionalProfileApi.AddFormationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddFormationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddFormationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddFormationResponse.displayName = 'proto.professionalProfileApi.AddFormationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateFormationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateFormationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateFormationRequest.displayName = 'proto.professionalProfileApi.UpdateFormationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateFormationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateFormationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateFormationResponse.displayName = 'proto.professionalProfileApi.UpdateFormationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteFormationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteFormationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteFormationRequest.displayName = 'proto.professionalProfileApi.DeleteFormationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeleteFormationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeleteFormationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeleteFormationResponse.displayName = 'proto.professionalProfileApi.DeleteFormationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateVisiblesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateVisiblesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateVisiblesRequest.displayName = 'proto.professionalProfileApi.UpdateVisiblesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateVisiblesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateVisiblesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateVisiblesResponse.displayName = 'proto.professionalProfileApi.UpdateVisiblesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListProfessionalProfileResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalProfileResponse.displayName = 'proto.professionalProfileApi.ListProfessionalProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfileOfProfessional = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ProfileOfProfessional.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ProfileOfProfessional, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfileOfProfessional.displayName = 'proto.professionalProfileApi.ProfileOfProfessional';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListExperience = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListExperience, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListExperience.displayName = 'proto.professionalProfileApi.ListExperience';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListCertificate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListCertificate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListCertificate.displayName = 'proto.professionalProfileApi.ListCertificate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListAward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListAward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListAward.displayName = 'proto.professionalProfileApi.ListAward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPublication = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListPublication, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPublication.displayName = 'proto.professionalProfileApi.ListPublication';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListLanguage.displayName = 'proto.professionalProfileApi.ListLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListFormation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListFormation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListFormation.displayName = 'proto.professionalProfileApi.ListFormation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListDisease = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListDisease, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListDisease.displayName = 'proto.professionalProfileApi.ListDisease';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateBiographyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateBiographyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateBiographyRequest.displayName = 'proto.professionalProfileApi.UpdateBiographyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.UpdateBiographyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.UpdateBiographyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.UpdateBiographyResponse.displayName = 'proto.professionalProfileApi.UpdateBiographyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SeeAsPatientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SeeAsPatientResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SeeAsPatientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SeeAsPatientResponse.displayName = 'proto.professionalProfileApi.SeeAsPatientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.displayName = 'proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.displayName = 'proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.InfoProfessionals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.InfoProfessionals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.InfoProfessionals.displayName = 'proto.professionalProfileApi.InfoProfessionals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchProfessionalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SearchProfessionalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchProfessionalsRequest.displayName = 'proto.professionalProfileApi.SearchProfessionalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchProfessionalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchProfessionalsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchProfessionalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchProfessionalsResponse.displayName = 'proto.professionalProfileApi.SearchProfessionalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.InfoProf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.InfoProf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.InfoProf.displayName = 'proto.professionalProfileApi.InfoProf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddPartnershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddPartnershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddPartnershipRequest.displayName = 'proto.professionalProfileApi.AddPartnershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.AddPartnershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.AddPartnershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.AddPartnershipResponse.displayName = 'proto.professionalProfileApi.AddPartnershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeletePartnershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeletePartnershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeletePartnershipRequest.displayName = 'proto.professionalProfileApi.DeletePartnershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.DeletePartnershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.DeletePartnershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.DeletePartnershipResponse.displayName = 'proto.professionalProfileApi.DeletePartnershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListPartnershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListPartnershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListPartnershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListPartnershipsResponse.displayName = 'proto.professionalProfileApi.ListPartnershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ProfessionalPartner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ProfessionalPartner.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ProfessionalPartner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ProfessionalPartner.displayName = 'proto.professionalProfileApi.ProfessionalPartner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ServiceLocations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ServiceLocations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ServiceLocations.displayName = 'proto.professionalProfileApi.ServiceLocations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Professions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Professions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Professions.displayName = 'proto.professionalProfileApi.Professions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SpecialitiesPartner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SpecialitiesPartner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SpecialitiesPartner.displayName = 'proto.professionalProfileApi.SpecialitiesPartner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Diseases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Diseases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Diseases.displayName = 'proto.professionalProfileApi.Diseases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchListPartnershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.SearchListPartnershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchListPartnershipsRequest.displayName = 'proto.professionalProfileApi.SearchListPartnershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.SearchListPartnershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.SearchListPartnershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.SearchListPartnershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.SearchListPartnershipsResponse.displayName = 'proto.professionalProfileApi.SearchListPartnershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetPartnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GetPartnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetPartnerRequest.displayName = 'proto.professionalProfileApi.GetPartnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetPartnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.GetPartnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetPartnerResponse.displayName = 'proto.professionalProfileApi.GetPartnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.GetPartner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.GetPartner.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.GetPartner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.GetPartner.displayName = 'proto.professionalProfileApi.GetPartner';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListBlockedAvailableScheduleRequest;
  return proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GeneratedDaysProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GeneratedDaysProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GeneratedDaysProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GeneratedDaysProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GeneratedDaysProto}
 */
proto.professionalProfileApi.GeneratedDaysProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GeneratedDaysProto;
  return proto.professionalProfileApi.GeneratedDaysProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GeneratedDaysProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GeneratedDaysProto}
 */
proto.professionalProfileApi.GeneratedDaysProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GeneratedDaysProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GeneratedDaysProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GeneratedDaysProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GeneratedDaysProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.professionalProfileApi.GeneratedDaysProto.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GeneratedDaysProto} returns this
 */
proto.professionalProfileApi.GeneratedDaysProto.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 day_of_year = 2;
 * @return {number}
 */
proto.professionalProfileApi.GeneratedDaysProto.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.GeneratedDaysProto} returns this
 */
proto.professionalProfileApi.GeneratedDaysProto.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListDaysConflicts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListDaysConflicts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListDaysConflicts.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    daysAvailableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    blocked: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    holiday: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    description: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListDaysConflicts}
 */
proto.professionalProfileApi.ListDaysConflicts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListDaysConflicts;
  return proto.professionalProfileApi.ListDaysConflicts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListDaysConflicts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListDaysConflicts}
 */
proto.professionalProfileApi.ListDaysConflicts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDaysAvailableId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlocked(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoliday(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListDaysConflicts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListDaysConflicts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListDaysConflicts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDaysAvailableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBlocked();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHoliday();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string days_available_id = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getDaysAvailableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setDaysAvailableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_date = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string final_date = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool blocked = 5;
 * @return {boolean}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool holiday = 6;
 * @return {boolean}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getHoliday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setHoliday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysConflicts} returns this
 */
proto.professionalProfileApi.ListDaysConflicts.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetServiceTypeInformationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsRequest}
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetServiceTypeInformationsRequest;
  return proto.professionalProfileApi.GetServiceTypeInformationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsRequest}
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetServiceTypeInformationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsRequest} returns this
 */
proto.professionalProfileApi.GetServiceTypeInformationsRequest.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetServiceTypeInformationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    informations: (f = msg.getInformations()) && proto.professionalProfileApi.ServiceInformations.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetServiceTypeInformationsResponse;
  return proto.professionalProfileApi.GetServiceTypeInformationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceInformations;
      reader.readMessage(value,proto.professionalProfileApi.ServiceInformations.deserializeBinaryFromReader);
      msg.setInformations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetServiceTypeInformationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceInformations.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} returns this
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} returns this
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} returns this
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ServiceInformations informations = 4;
 * @return {?proto.professionalProfileApi.ServiceInformations}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.getInformations = function() {
  return /** @type{?proto.professionalProfileApi.ServiceInformations} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.ServiceInformations, 4));
};


/**
 * @param {?proto.professionalProfileApi.ServiceInformations|undefined} value
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} returns this
*/
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.setInformations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.GetServiceTypeInformationsResponse} returns this
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.clearInformations = function() {
  return this.setInformations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.GetServiceTypeInformationsResponse.prototype.hasInformations = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServiceInformations.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceInformations.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceInformations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceInformations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceInformations.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tuss: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tussTerm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sus: jspb.Message.getFieldWithDefault(msg, 6, ""),
    susProcedure: jspb.Message.getFieldWithDefault(msg, 7, ""),
    serviceReturn: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    serviceTimeReturn: jspb.Message.getFieldWithDefault(msg, 9, 0),
    occupation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.SpecialitiesProf.toObject, includeInstance),
    council: jspb.Message.getFieldWithDefault(msg, 13, ""),
    number: jspb.Message.getFieldWithDefault(msg, 14, ""),
    state: jspb.Message.getFieldWithDefault(msg, 15, ""),
    address: (f = msg.getAddress()) && proto.professionalProfileApi.AddressInfo.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceInformations}
 */
proto.professionalProfileApi.ServiceInformations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceInformations;
  return proto.professionalProfileApi.ServiceInformations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceInformations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceInformations}
 */
proto.professionalProfileApi.ServiceInformations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuss(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTussTerm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSusProcedure(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServiceReturn(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceTimeReturn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 11:
      var value = new proto.professionalProfileApi.SpecialitiesProf;
      reader.readMessage(value,proto.professionalProfileApi.SpecialitiesProf.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncil(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 16:
      var value = new proto.professionalProfileApi.AddressInfo;
      reader.readMessage(value,proto.professionalProfileApi.AddressInfo.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceInformations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceInformations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceInformations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceInformations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTuss();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTussTerm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSusProcedure();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getServiceReturn();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getServiceTimeReturn();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.professionalProfileApi.SpecialitiesProf.serializeBinaryToWriter
    );
  }
  f = message.getCouncil();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.professionalProfileApi.AddressInfo.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string service_description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getServiceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setServiceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tuss = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tuss_term = 5;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getTussTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setTussTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sus = 6;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getSus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setSus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sus_procedure = 7;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getSusProcedure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setSusProcedure = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool service_return = 8;
 * @return {boolean}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getServiceReturn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setServiceReturn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 service_time_return = 9;
 * @return {number}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getServiceTimeReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setServiceTimeReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string occupation = 10;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated SpecialitiesProf specialities = 11;
 * @return {!Array<!proto.professionalProfileApi.SpecialitiesProf>}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SpecialitiesProf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SpecialitiesProf, 11));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SpecialitiesProf>} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
*/
proto.professionalProfileApi.ServiceInformations.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.professionalProfileApi.SpecialitiesProf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SpecialitiesProf}
 */
proto.professionalProfileApi.ServiceInformations.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.professionalProfileApi.SpecialitiesProf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * optional string council = 13;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getCouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setCouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string number = 14;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string state = 15;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional AddressInfo address = 16;
 * @return {?proto.professionalProfileApi.AddressInfo}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getAddress = function() {
  return /** @type{?proto.professionalProfileApi.AddressInfo} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.AddressInfo, 16));
};


/**
 * @param {?proto.professionalProfileApi.AddressInfo|undefined} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
*/
proto.professionalProfileApi.ServiceInformations.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.ServiceInformations.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string phone = 17;
 * @return {string}
 */
proto.professionalProfileApi.ServiceInformations.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceInformations} returns this
 */
proto.professionalProfileApi.ServiceInformations.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddressInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddressInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddressInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddressInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 3, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddressInfo}
 */
proto.professionalProfileApi.AddressInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddressInfo;
  return proto.professionalProfileApi.AddressInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddressInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddressInfo}
 */
proto.professionalProfileApi.AddressInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddressInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddressInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddressInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddressInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddressInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddressInfo} returns this
 */
proto.professionalProfileApi.AddressInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddressInfo.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddressInfo} returns this
 */
proto.professionalProfileApi.AddressInfo.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string complement = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddressInfo.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddressInfo} returns this
 */
proto.professionalProfileApi.AddressInfo.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string neighborhood = 4;
 * @return {string}
 */
proto.professionalProfileApi.AddressInfo.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddressInfo} returns this
 */
proto.professionalProfileApi.AddressInfo.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.professionalProfileApi.AddressInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddressInfo} returns this
 */
proto.professionalProfileApi.AddressInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.professionalProfileApi.AddressInfo.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddressInfo} returns this
 */
proto.professionalProfileApi.AddressInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SpecialitiesProf.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SpecialitiesProf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SpecialitiesProf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesProf.toObject = function(includeInstance, msg) {
  var f, obj = {
    speciality: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionActuationsList: jspb.Message.toObjectList(msg.getActionActuationsList(),
    proto.professionalProfileApi.ActionActuationProf.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SpecialitiesProf}
 */
proto.professionalProfileApi.SpecialitiesProf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SpecialitiesProf;
  return proto.professionalProfileApi.SpecialitiesProf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SpecialitiesProf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SpecialitiesProf}
 */
proto.professionalProfileApi.SpecialitiesProf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeciality(value);
      break;
    case 2:
      var value = new proto.professionalProfileApi.ActionActuationProf;
      reader.readMessage(value,proto.professionalProfileApi.ActionActuationProf.deserializeBinaryFromReader);
      msg.addActionActuations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SpecialitiesProf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SpecialitiesProf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesProf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeciality();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionActuationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.professionalProfileApi.ActionActuationProf.serializeBinaryToWriter
    );
  }
};


/**
 * optional string speciality = 1;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.getSpeciality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesProf} returns this
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.setSpeciality = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ActionActuationProf action_actuations = 2;
 * @return {!Array<!proto.professionalProfileApi.ActionActuationProf>}
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.getActionActuationsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ActionActuationProf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ActionActuationProf, 2));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ActionActuationProf>} value
 * @return {!proto.professionalProfileApi.SpecialitiesProf} returns this
*/
proto.professionalProfileApi.SpecialitiesProf.prototype.setActionActuationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.professionalProfileApi.ActionActuationProf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ActionActuationProf}
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.addActionActuations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.professionalProfileApi.ActionActuationProf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SpecialitiesProf} returns this
 */
proto.professionalProfileApi.SpecialitiesProf.prototype.clearActionActuationsList = function() {
  return this.setActionActuationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ActionActuationProf.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ActionActuationProf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ActionActuationProf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionActuationProf.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionActuation: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ActionActuationProf}
 */
proto.professionalProfileApi.ActionActuationProf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ActionActuationProf;
  return proto.professionalProfileApi.ActionActuationProf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ActionActuationProf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ActionActuationProf}
 */
proto.professionalProfileApi.ActionActuationProf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ActionActuationProf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ActionActuationProf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ActionActuationProf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionActuationProf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionActuation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string action_actuation = 1;
 * @return {string}
 */
proto.professionalProfileApi.ActionActuationProf.prototype.getActionActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionActuationProf} returns this
 */
proto.professionalProfileApi.ActionActuationProf.prototype.setActionActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListToGenerateSugestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListToGenerateSugestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListToGenerateSugestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finalTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListToGenerateSugestion}
 */
proto.professionalProfileApi.ListToGenerateSugestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListToGenerateSugestion;
  return proto.professionalProfileApi.ListToGenerateSugestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListToGenerateSugestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListToGenerateSugestion}
 */
proto.professionalProfileApi.ListToGenerateSugestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListToGenerateSugestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListToGenerateSugestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListToGenerateSugestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinalTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string initial_time = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.getInitialTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListToGenerateSugestion} returns this
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.setInitialTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string final_time = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.getFinalTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListToGenerateSugestion} returns this
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.setFinalTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimated_time = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListToGenerateSugestion} returns this
 */
proto.professionalProfileApi.ListToGenerateSugestion.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalAvailableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.professionalProfileApi.LocationPatient.toObject(includeInstance, f),
    serviceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalAvailableRequest;
  return proto.professionalProfileApi.ListProfessionalAvailableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.LocationPatient;
      reader.readMessage(value,proto.professionalProfileApi.LocationPatient.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalAvailableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.professionalProfileApi.LocationPatient.serializeBinaryToWriter
    );
  }
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string professional_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_id = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LocationPatient location = 3;
 * @return {?proto.professionalProfileApi.LocationPatient}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.getLocation = function() {
  return /** @type{?proto.professionalProfileApi.LocationPatient} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.LocationPatient, 3));
};


/**
 * @param {?proto.professionalProfileApi.LocationPatient|undefined} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest} returns this
*/
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string service_id = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string service_type_id = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableRequest.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalAvailableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profile: (f = msg.getProfile()) && proto.professionalProfileApi.ProfileOfProfessionalAvailable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalAvailableResponse;
  return proto.professionalProfileApi.ListProfessionalAvailableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ProfileOfProfessionalAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ProfileOfProfessionalAvailable.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalAvailableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalAvailableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.professionalProfileApi.ProfileOfProfessionalAvailable.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ProfileOfProfessionalAvailable profile = 4;
 * @return {?proto.professionalProfileApi.ProfileOfProfessionalAvailable}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.getProfile = function() {
  return /** @type{?proto.professionalProfileApi.ProfileOfProfessionalAvailable} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.ProfileOfProfessionalAvailable, 4));
};


/**
 * @param {?proto.professionalProfileApi.ProfileOfProfessionalAvailable|undefined} value
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse} returns this
*/
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.ListProfessionalAvailableResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalAvailableResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.repeatedFields_ = [6,7,8,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfileOfProfessionalAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    biography: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    councilsList: jspb.Message.toObjectList(msg.getCouncilsList(),
    proto.professionalProfileApi.ListCouncil.toObject, includeInstance),
    diseasesList: jspb.Message.toObjectList(msg.getDiseasesList(),
    proto.professionalProfileApi.ListDisease.toObject, includeInstance),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.ListPlaceAvailable.toObject, includeInstance),
    sugestion: (f = msg.getSugestion()) && proto.professionalProfileApi.Sugestion.toObject(includeInstance, f),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ListServiceAvailable.toObject, includeInstance),
    serviceTypesList: jspb.Message.toObjectList(msg.getServiceTypesList(),
    proto.professionalProfileApi.ListServiceTypesDistinct.toObject, includeInstance),
    availables: (f = msg.getAvailables()) && proto.professionalProfileApi.AvailableProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfileOfProfessionalAvailable;
  return proto.professionalProfileApi.ProfileOfProfessionalAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiography(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.ListCouncil;
      reader.readMessage(value,proto.professionalProfileApi.ListCouncil.deserializeBinaryFromReader);
      msg.addCouncils(value);
      break;
    case 7:
      var value = new proto.professionalProfileApi.ListDisease;
      reader.readMessage(value,proto.professionalProfileApi.ListDisease.deserializeBinaryFromReader);
      msg.addDiseases(value);
      break;
    case 8:
      var value = new proto.professionalProfileApi.ListPlaceAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ListPlaceAvailable.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    case 9:
      var value = new proto.professionalProfileApi.Sugestion;
      reader.readMessage(value,proto.professionalProfileApi.Sugestion.deserializeBinaryFromReader);
      msg.setSugestion(value);
      break;
    case 10:
      var value = new proto.professionalProfileApi.ListServiceAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ListServiceAvailable.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 11:
      var value = new proto.professionalProfileApi.ListServiceTypesDistinct;
      reader.readMessage(value,proto.professionalProfileApi.ListServiceTypesDistinct.deserializeBinaryFromReader);
      msg.addServiceTypes(value);
      break;
    case 12:
      var value = new proto.professionalProfileApi.AvailableProto;
      reader.readMessage(value,proto.professionalProfileApi.AvailableProto.deserializeBinaryFromReader);
      msg.setAvailables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfileOfProfessionalAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBiography();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCouncilsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.ListCouncil.serializeBinaryToWriter
    );
  }
  f = message.getDiseasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.professionalProfileApi.ListDisease.serializeBinaryToWriter
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.professionalProfileApi.ListPlaceAvailable.serializeBinaryToWriter
    );
  }
  f = message.getSugestion();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.professionalProfileApi.Sugestion.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.professionalProfileApi.ListServiceAvailable.serializeBinaryToWriter
    );
  }
  f = message.getServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.professionalProfileApi.ListServiceTypesDistinct.serializeBinaryToWriter
    );
  }
  f = message.getAvailables();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.professionalProfileApi.AvailableProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string professional_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string biography = 3;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getBiography = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setBiography = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string picture_url = 5;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ListCouncil councils = 6;
 * @return {!Array<!proto.professionalProfileApi.ListCouncil>}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getCouncilsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListCouncil>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListCouncil, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListCouncil>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setCouncilsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.ListCouncil=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListCouncil}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.addCouncils = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.ListCouncil, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearCouncilsList = function() {
  return this.setCouncilsList([]);
};


/**
 * repeated ListDisease diseases = 7;
 * @return {!Array<!proto.professionalProfileApi.ListDisease>}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getDiseasesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListDisease>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListDisease, 7));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListDisease>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setDiseasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.professionalProfileApi.ListDisease=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListDisease}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.addDiseases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.professionalProfileApi.ListDisease, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearDiseasesList = function() {
  return this.setDiseasesList([]);
};


/**
 * repeated ListPlaceAvailable places = 8;
 * @return {!Array<!proto.professionalProfileApi.ListPlaceAvailable>}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListPlaceAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListPlaceAvailable, 8));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListPlaceAvailable>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.professionalProfileApi.ListPlaceAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListPlaceAvailable}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.professionalProfileApi.ListPlaceAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};


/**
 * optional Sugestion sugestion = 9;
 * @return {?proto.professionalProfileApi.Sugestion}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getSugestion = function() {
  return /** @type{?proto.professionalProfileApi.Sugestion} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.Sugestion, 9));
};


/**
 * @param {?proto.professionalProfileApi.Sugestion|undefined} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setSugestion = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearSugestion = function() {
  return this.setSugestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.hasSugestion = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated ListServiceAvailable services = 10;
 * @return {!Array<!proto.professionalProfileApi.ListServiceAvailable>}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListServiceAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListServiceAvailable, 10));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListServiceAvailable>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListServiceAvailable}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.professionalProfileApi.ListServiceAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated ListServiceTypesDistinct service_types = 11;
 * @return {!Array<!proto.professionalProfileApi.ListServiceTypesDistinct>}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListServiceTypesDistinct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListServiceTypesDistinct, 11));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListServiceTypesDistinct>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceTypesDistinct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListServiceTypesDistinct}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.addServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.professionalProfileApi.ListServiceTypesDistinct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearServiceTypesList = function() {
  return this.setServiceTypesList([]);
};


/**
 * optional AvailableProto availables = 12;
 * @return {?proto.professionalProfileApi.AvailableProto}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.getAvailables = function() {
  return /** @type{?proto.professionalProfileApi.AvailableProto} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.AvailableProto, 12));
};


/**
 * @param {?proto.professionalProfileApi.AvailableProto|undefined} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
*/
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.setAvailables = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.ProfileOfProfessionalAvailable} returns this
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.clearAvailables = function() {
  return this.setAvailables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessionalAvailable.prototype.hasAvailables = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.AvailableProto.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AvailableProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AvailableProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AvailableProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.professionalProfileApi.DateInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AvailableProto}
 */
proto.professionalProfileApi.AvailableProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AvailableProto;
  return proto.professionalProfileApi.AvailableProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AvailableProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AvailableProto}
 */
proto.professionalProfileApi.AvailableProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.professionalProfileApi.DateInfo;
      reader.readMessage(value,proto.professionalProfileApi.DateInfo.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AvailableProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AvailableProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AvailableProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.professionalProfileApi.DateInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DateInfo dates = 1;
 * @return {!Array<!proto.professionalProfileApi.DateInfo>}
 */
proto.professionalProfileApi.AvailableProto.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.DateInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.DateInfo, 1));
};


/**
 * @param {!Array<!proto.professionalProfileApi.DateInfo>} value
 * @return {!proto.professionalProfileApi.AvailableProto} returns this
*/
proto.professionalProfileApi.AvailableProto.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.professionalProfileApi.DateInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.DateInfo}
 */
proto.professionalProfileApi.AvailableProto.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.professionalProfileApi.DateInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.AvailableProto} returns this
 */
proto.professionalProfileApi.AvailableProto.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.DateInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hoursList: jspb.Message.toObjectList(msg.getHoursList(),
    proto.professionalProfileApi.Hour.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DateInfo}
 */
proto.professionalProfileApi.DateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DateInfo;
  return proto.professionalProfileApi.DateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DateInfo}
 */
proto.professionalProfileApi.DateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.Hour;
      reader.readMessage(value,proto.professionalProfileApi.Hour.deserializeBinaryFromReader);
      msg.addHours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHoursList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.Hour.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.professionalProfileApi.DateInfo.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DateInfo} returns this
 */
proto.professionalProfileApi.DateInfo.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimated_time = 2;
 * @return {string}
 */
proto.professionalProfileApi.DateInfo.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DateInfo} returns this
 */
proto.professionalProfileApi.DateInfo.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Hour hours = 3;
 * @return {!Array<!proto.professionalProfileApi.Hour>}
 */
proto.professionalProfileApi.DateInfo.prototype.getHoursList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Hour>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Hour, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Hour>} value
 * @return {!proto.professionalProfileApi.DateInfo} returns this
*/
proto.professionalProfileApi.DateInfo.prototype.setHoursList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.Hour=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Hour}
 */
proto.professionalProfileApi.DateInfo.prototype.addHours = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.Hour, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.DateInfo} returns this
 */
proto.professionalProfileApi.DateInfo.prototype.clearHoursList = function() {
  return this.setHoursList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Hour.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Hour.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Hour} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Hour.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialHour: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Hour}
 */
proto.professionalProfileApi.Hour.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Hour;
  return proto.professionalProfileApi.Hour.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Hour} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Hour}
 */
proto.professionalProfileApi.Hour.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialHour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Hour.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Hour.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Hour} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Hour.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialHour();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string initial_hour = 1;
 * @return {string}
 */
proto.professionalProfileApi.Hour.prototype.getInitialHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Hour} returns this
 */
proto.professionalProfileApi.Hour.prototype.setInitialHour = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceTypesDistinct.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceTypesDistinct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceTypesDistinct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceTypesDistinct.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceType: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceTypesDistinct}
 */
proto.professionalProfileApi.ListServiceTypesDistinct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceTypesDistinct;
  return proto.professionalProfileApi.ListServiceTypesDistinct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceTypesDistinct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceTypesDistinct}
 */
proto.professionalProfileApi.ListServiceTypesDistinct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceTypesDistinct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceTypesDistinct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceTypesDistinct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceTypesDistinct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string service_type = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceTypesDistinct.prototype.getServiceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceTypesDistinct} returns this
 */
proto.professionalProfileApi.ListServiceTypesDistinct.prototype.setServiceType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServiceAvailable.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pb_return: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    timeReturn: jspb.Message.getFieldWithDefault(msg, 5, 0),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ListServiceTypeAvailable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceAvailable}
 */
proto.professionalProfileApi.ListServiceAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceAvailable;
  return proto.professionalProfileApi.ListServiceAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceAvailable}
 */
proto.professionalProfileApi.ListServiceAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturn(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeReturn(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 7:
      var value = new proto.professionalProfileApi.ListServiceTypeAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ListServiceTypeAvailable.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReturn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTimeReturn();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.professionalProfileApi.ListServiceTypeAvailable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool return = 4;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getReturn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.setReturn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 time_return = 5;
 * @return {number}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getTimeReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.setTimeReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool selected = 6;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated ListServiceTypeAvailable serviceTypes = 7;
 * @return {!Array<!proto.professionalProfileApi.ListServiceTypeAvailable>}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListServiceTypeAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListServiceTypeAvailable, 7));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListServiceTypeAvailable>} value
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
*/
proto.professionalProfileApi.ListServiceAvailable.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceTypeAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable}
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.professionalProfileApi.ListServiceTypeAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceAvailable} returns this
 */
proto.professionalProfileApi.ListServiceAvailable.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServiceTypeAvailable.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceTypeAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceTypeAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceTypeAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicemode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.ListPlaceOfServiceMode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceTypeAvailable;
  return proto.professionalProfileApi.ListServiceTypeAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceTypeAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.ListPlaceOfServiceMode;
      reader.readMessage(value,proto.professionalProfileApi.ListPlaceOfServiceMode.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceTypeAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceTypeAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceTypeAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.ListPlaceOfServiceMode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serviceMode = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable} returns this
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool selected = 2;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable} returns this
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated ListPlaceOfServiceMode places = 3;
 * @return {!Array<!proto.professionalProfileApi.ListPlaceOfServiceMode>}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListPlaceOfServiceMode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListPlaceOfServiceMode, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListPlaceOfServiceMode>} value
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable} returns this
*/
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.ListPlaceOfServiceMode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListPlaceOfServiceMode}
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.ListPlaceOfServiceMode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceTypeAvailable} returns this
 */
proto.professionalProfileApi.ListServiceTypeAvailable.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPlaceOfServiceMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPlaceOfServiceMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPlaceOfServiceMode}
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPlaceOfServiceMode;
  return proto.professionalProfileApi.ListPlaceOfServiceMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPlaceOfServiceMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPlaceOfServiceMode}
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPlaceOfServiceMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPlaceOfServiceMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceOfServiceMode} returns this
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceOfServiceMode} returns this
 */
proto.professionalProfileApi.ListPlaceOfServiceMode.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPlaceAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPlaceAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlaceAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationState: jspb.Message.getFieldWithDefault(msg, 13, ""),
    country: jspb.Message.getFieldWithDefault(msg, 14, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 17, ""),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPlaceAvailable}
 */
proto.professionalProfileApi.ListPlaceAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPlaceAvailable;
  return proto.professionalProfileApi.ListPlaceAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPlaceAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPlaceAvailable}
 */
proto.professionalProfileApi.ListPlaceAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationState(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPlaceAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPlaceAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlaceAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationState();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string picture_url = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string neighborhood = 8;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_state = 13;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getLocationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setLocationState = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string country = 14;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional double latitude = 15;
 * @return {number}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double longitude = 16;
 * @return {number}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string status = 17;
 * @return {string}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional double distance = 18;
 * @return {number}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional bool selected = 19;
 * @return {boolean}
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListPlaceAvailable} returns this
 */
proto.professionalProfileApi.ListPlaceAvailable.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.Sugestion.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Sugestion.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Sugestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Sugestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Sugestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hour: jspb.Message.getFieldWithDefault(msg, 2, ""),
    place: (f = msg.getPlace()) && proto.professionalProfileApi.SugestionPlace.toObject(includeInstance, f),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.SugestionService.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Sugestion}
 */
proto.professionalProfileApi.Sugestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Sugestion;
  return proto.professionalProfileApi.Sugestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Sugestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Sugestion}
 */
proto.professionalProfileApi.Sugestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHour(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.SugestionPlace;
      reader.readMessage(value,proto.professionalProfileApi.SugestionPlace.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.SugestionService;
      reader.readMessage(value,proto.professionalProfileApi.SugestionService.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Sugestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Sugestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Sugestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Sugestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHour();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.professionalProfileApi.SugestionPlace.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.SugestionService.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.professionalProfileApi.Sugestion.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Sugestion} returns this
 */
proto.professionalProfileApi.Sugestion.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hour = 2;
 * @return {string}
 */
proto.professionalProfileApi.Sugestion.prototype.getHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Sugestion} returns this
 */
proto.professionalProfileApi.Sugestion.prototype.setHour = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SugestionPlace place = 3;
 * @return {?proto.professionalProfileApi.SugestionPlace}
 */
proto.professionalProfileApi.Sugestion.prototype.getPlace = function() {
  return /** @type{?proto.professionalProfileApi.SugestionPlace} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.SugestionPlace, 3));
};


/**
 * @param {?proto.professionalProfileApi.SugestionPlace|undefined} value
 * @return {!proto.professionalProfileApi.Sugestion} returns this
*/
proto.professionalProfileApi.Sugestion.prototype.setPlace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.Sugestion} returns this
 */
proto.professionalProfileApi.Sugestion.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.Sugestion.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated SugestionService services = 4;
 * @return {!Array<!proto.professionalProfileApi.SugestionService>}
 */
proto.professionalProfileApi.Sugestion.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SugestionService>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SugestionService, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SugestionService>} value
 * @return {!proto.professionalProfileApi.Sugestion} returns this
*/
proto.professionalProfileApi.Sugestion.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.SugestionService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SugestionService}
 */
proto.professionalProfileApi.Sugestion.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.SugestionService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.Sugestion} returns this
 */
proto.professionalProfileApi.Sugestion.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SugestionService.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SugestionService.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SugestionService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SugestionService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SugestionService.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.SugestionServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SugestionService}
 */
proto.professionalProfileApi.SugestionService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SugestionService;
  return proto.professionalProfileApi.SugestionService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SugestionService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SugestionService}
 */
proto.professionalProfileApi.SugestionService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.SugestionServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.SugestionServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SugestionService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SugestionService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SugestionService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SugestionService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.SugestionServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.SugestionService.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionService} returns this
 */
proto.professionalProfileApi.SugestionService.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.SugestionService.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionService} returns this
 */
proto.professionalProfileApi.SugestionService.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.SugestionService.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionService} returns this
 */
proto.professionalProfileApi.SugestionService.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool selected = 4;
 * @return {boolean}
 */
proto.professionalProfileApi.SugestionService.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SugestionService} returns this
 */
proto.professionalProfileApi.SugestionService.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SugestionServiceTypes serviceTypes = 5;
 * @return {!Array<!proto.professionalProfileApi.SugestionServiceTypes>}
 */
proto.professionalProfileApi.SugestionService.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SugestionServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SugestionServiceTypes, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SugestionServiceTypes>} value
 * @return {!proto.professionalProfileApi.SugestionService} returns this
*/
proto.professionalProfileApi.SugestionService.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.SugestionServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SugestionServiceTypes}
 */
proto.professionalProfileApi.SugestionService.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.SugestionServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SugestionService} returns this
 */
proto.professionalProfileApi.SugestionService.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SugestionServiceTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SugestionServiceTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SugestionServiceTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 6, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SugestionServiceTypes}
 */
proto.professionalProfileApi.SugestionServiceTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SugestionServiceTypes;
  return proto.professionalProfileApi.SugestionServiceTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SugestionServiceTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SugestionServiceTypes}
 */
proto.professionalProfileApi.SugestionServiceTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SugestionServiceTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SugestionServiceTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SugestionServiceTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serviceMode = 2;
 * @return {string}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimated_time = 3;
 * @return {string}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double value = 5;
 * @return {number}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 max_displacement = 6;
 * @return {number}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double valueKm = 7;
 * @return {number}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool selected = 8;
 * @return {boolean}
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SugestionServiceTypes} returns this
 */
proto.professionalProfileApi.SugestionServiceTypes.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SugestionPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SugestionPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SugestionPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SugestionPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationState: jspb.Message.getFieldWithDefault(msg, 13, ""),
    country: jspb.Message.getFieldWithDefault(msg, 14, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SugestionPlace}
 */
proto.professionalProfileApi.SugestionPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SugestionPlace;
  return proto.professionalProfileApi.SugestionPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SugestionPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SugestionPlace}
 */
proto.professionalProfileApi.SugestionPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationState(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SugestionPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SugestionPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SugestionPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SugestionPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationState();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string picture_url = 4;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string neighborhood = 8;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_state = 13;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getLocationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setLocationState = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string country = 14;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional double latitude = 15;
 * @return {number}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double longitude = 16;
 * @return {number}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string status = 17;
 * @return {string}
 */
proto.professionalProfileApi.SugestionPlace.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SugestionPlace} returns this
 */
proto.professionalProfileApi.SugestionPlace.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ProfessionalsProto.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionalsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionalsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    biography: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nearToYour: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    placeId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    councilsList: jspb.Message.toObjectList(msg.getCouncilsList(),
    proto.professionalProfileApi.ListCouncil.toObject, includeInstance),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.ListPlace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionalsProto}
 */
proto.professionalProfileApi.ProfessionalsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionalsProto;
  return proto.professionalProfileApi.ProfessionalsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionalsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionalsProto}
 */
proto.professionalProfileApi.ProfessionalsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiography(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNearToYour(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 9:
      var value = new proto.professionalProfileApi.ListCouncil;
      reader.readMessage(value,proto.professionalProfileApi.ListCouncil.deserializeBinaryFromReader);
      msg.addCouncils(value);
      break;
    case 10:
      var value = new proto.professionalProfileApi.ListPlace;
      reader.readMessage(value,proto.professionalProfileApi.ListPlace.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionalsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionalsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBiography();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNearToYour();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCouncilsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.professionalProfileApi.ListCouncil.serializeBinaryToWriter
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.professionalProfileApi.ListPlace.serializeBinaryToWriter
    );
  }
};


/**
 * optional string professional_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string biography = 3;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getBiography = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setBiography = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string picture_url = 5;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double near_to_your = 6;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getNearToYour = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setNearToYour = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string place_id = 7;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string place_name = 8;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ListCouncil councils = 9;
 * @return {!Array<!proto.professionalProfileApi.ListCouncil>}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getCouncilsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListCouncil>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListCouncil, 9));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListCouncil>} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
*/
proto.professionalProfileApi.ProfessionalsProto.prototype.setCouncilsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.professionalProfileApi.ListCouncil=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListCouncil}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.addCouncils = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.professionalProfileApi.ListCouncil, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.clearCouncilsList = function() {
  return this.setCouncilsList([]);
};


/**
 * repeated ListPlace places = 10;
 * @return {!Array<!proto.professionalProfileApi.ListPlace>}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListPlace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListPlace, 10));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListPlace>} value
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
*/
proto.professionalProfileApi.ProfessionalsProto.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.professionalProfileApi.ListPlace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListPlace}
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.professionalProfileApi.ListPlace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfessionalsProto} returns this
 */
proto.professionalProfileApi.ProfessionalsProto.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationState: jspb.Message.getFieldWithDefault(msg, 13, ""),
    country: jspb.Message.getFieldWithDefault(msg, 14, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 17, ""),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPlace}
 */
proto.professionalProfileApi.ListPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPlace;
  return proto.professionalProfileApi.ListPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPlace}
 */
proto.professionalProfileApi.ListPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationState(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationState();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string picture_url = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string neighborhood = 8;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_state = 13;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getLocationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setLocationState = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string country = 14;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional double latitude = 15;
 * @return {number}
 */
proto.professionalProfileApi.ListPlace.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double longitude = 16;
 * @return {number}
 */
proto.professionalProfileApi.ListPlace.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string status = 17;
 * @return {string}
 */
proto.professionalProfileApi.ListPlace.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional double distance = 18;
 * @return {number}
 */
proto.professionalProfileApi.ListPlace.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListPlace} returns this
 */
proto.professionalProfileApi.ListPlace.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListCouncil.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListCouncil.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListCouncil.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListCouncil} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCouncil.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    council: jspb.Message.getFieldWithDefault(msg, 2, ""),
    number: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    specialityList: jspb.Message.toObjectList(msg.getSpecialityList(),
    proto.professionalProfileApi.Speciality.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListCouncil}
 */
proto.professionalProfileApi.ListCouncil.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListCouncil;
  return proto.professionalProfileApi.ListCouncil.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListCouncil} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListCouncil}
 */
proto.professionalProfileApi.ListCouncil.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.Speciality;
      reader.readMessage(value,proto.professionalProfileApi.Speciality.deserializeBinaryFromReader);
      msg.addSpeciality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListCouncil.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListCouncil.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListCouncil} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCouncil.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouncil();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSpecialityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.Speciality.serializeBinaryToWriter
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncil.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
 */
proto.professionalProfileApi.ListCouncil.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string council = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncil.prototype.getCouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
 */
proto.professionalProfileApi.ListCouncil.prototype.setCouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string number = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncil.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
 */
proto.professionalProfileApi.ListCouncil.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncil.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
 */
proto.professionalProfileApi.ListCouncil.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncil.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
 */
proto.professionalProfileApi.ListCouncil.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Speciality speciality = 6;
 * @return {!Array<!proto.professionalProfileApi.Speciality>}
 */
proto.professionalProfileApi.ListCouncil.prototype.getSpecialityList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Speciality>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Speciality, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Speciality>} value
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
*/
proto.professionalProfileApi.ListCouncil.prototype.setSpecialityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.Speciality=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Speciality}
 */
proto.professionalProfileApi.ListCouncil.prototype.addSpeciality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.Speciality, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListCouncil} returns this
 */
proto.professionalProfileApi.ListCouncil.prototype.clearSpecialityList = function() {
  return this.setSpecialityList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchAppointmentRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstFilter: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    secondFilter: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    firstOptionList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    secondOptionList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest}
 */
proto.professionalProfileApi.SearchAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchAppointmentRequest;
  return proto.professionalProfileApi.SearchAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest}
 */
proto.professionalProfileApi.SearchAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFirstFilter(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSecondFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFirstOption(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSecondOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstFilter();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSecondFilter();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getFirstOptionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSecondOptionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bool first_filter = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.getFirstFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.setFirstFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool second_filter = 2;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.getSecondFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.setSecondFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string first_option = 3;
 * @return {!Array<string>}
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.getFirstOptionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.setFirstOptionList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.addFirstOption = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.clearFirstOptionList = function() {
  return this.setFirstOptionList([]);
};


/**
 * repeated string second_option = 4;
 * @return {!Array<string>}
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.getSecondOptionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.setSecondOptionList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.addSecondOption = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchAppointmentRequest} returns this
 */
proto.professionalProfileApi.SearchAppointmentRequest.prototype.clearSecondOptionList = function() {
  return this.setSecondOptionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchAppointmentResponse.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstListingList: jspb.Message.toObjectList(msg.getFirstListingList(),
    proto.professionalProfileApi.FirstListing.toObject, includeInstance),
    secondListingList: jspb.Message.toObjectList(msg.getSecondListingList(),
    proto.professionalProfileApi.SecondListing.toObject, includeInstance),
    professionalsList: jspb.Message.toObjectList(msg.getProfessionalsList(),
    proto.professionalProfileApi.ProfessionalsProto.toObject, includeInstance),
    location: (f = msg.getLocation()) && proto.professionalProfileApi.LocationPatient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse}
 */
proto.professionalProfileApi.SearchAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchAppointmentResponse;
  return proto.professionalProfileApi.SearchAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse}
 */
proto.professionalProfileApi.SearchAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.FirstListing;
      reader.readMessage(value,proto.professionalProfileApi.FirstListing.deserializeBinaryFromReader);
      msg.addFirstListing(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.SecondListing;
      reader.readMessage(value,proto.professionalProfileApi.SecondListing.deserializeBinaryFromReader);
      msg.addSecondListing(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.ProfessionalsProto;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalsProto.deserializeBinaryFromReader);
      msg.addProfessionals(value);
      break;
    case 7:
      var value = new proto.professionalProfileApi.LocationPatient;
      reader.readMessage(value,proto.professionalProfileApi.LocationPatient.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstListingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.FirstListing.serializeBinaryToWriter
    );
  }
  f = message.getSecondListingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.SecondListing.serializeBinaryToWriter
    );
  }
  f = message.getProfessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.ProfessionalsProto.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.professionalProfileApi.LocationPatient.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FirstListing first_listing = 4;
 * @return {!Array<!proto.professionalProfileApi.FirstListing>}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getFirstListingList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.FirstListing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.FirstListing, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.FirstListing>} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
*/
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setFirstListingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.FirstListing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.FirstListing}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.addFirstListing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.FirstListing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.clearFirstListingList = function() {
  return this.setFirstListingList([]);
};


/**
 * repeated SecondListing second_listing = 5;
 * @return {!Array<!proto.professionalProfileApi.SecondListing>}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getSecondListingList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SecondListing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SecondListing, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SecondListing>} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
*/
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setSecondListingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.SecondListing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SecondListing}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.addSecondListing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.SecondListing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.clearSecondListingList = function() {
  return this.setSecondListingList([]);
};


/**
 * repeated ProfessionalsProto professionals = 6;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalsProto>}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getProfessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalsProto, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalsProto>} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
*/
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setProfessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalsProto}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.addProfessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.ProfessionalsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.clearProfessionalsList = function() {
  return this.setProfessionalsList([]);
};


/**
 * optional LocationPatient location = 7;
 * @return {?proto.professionalProfileApi.LocationPatient}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.getLocation = function() {
  return /** @type{?proto.professionalProfileApi.LocationPatient} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.LocationPatient, 7));
};


/**
 * @param {?proto.professionalProfileApi.LocationPatient|undefined} value
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
*/
proto.professionalProfileApi.SearchAppointmentResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.SearchAppointmentResponse} returns this
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.SearchAppointmentResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.LocationPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.LocationPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.LocationPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.LocationPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.LocationPatient}
 */
proto.professionalProfileApi.LocationPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.LocationPatient;
  return proto.professionalProfileApi.LocationPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.LocationPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.LocationPatient}
 */
proto.professionalProfileApi.LocationPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.LocationPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.LocationPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.LocationPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.LocationPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.professionalProfileApi.LocationPatient.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.LocationPatient} returns this
 */
proto.professionalProfileApi.LocationPatient.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.professionalProfileApi.LocationPatient.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.LocationPatient} returns this
 */
proto.professionalProfileApi.LocationPatient.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SecondListing.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SecondListing.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SecondListing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SecondListing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SecondListing.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypesList: jspb.Message.toObjectList(msg.getServiceTypesList(),
    proto.professionalProfileApi.ServiceTypeProto.toObject, includeInstance),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto.professionalProfileApi.CityProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SecondListing}
 */
proto.professionalProfileApi.SecondListing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SecondListing;
  return proto.professionalProfileApi.SecondListing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SecondListing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SecondListing}
 */
proto.professionalProfileApi.SecondListing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.professionalProfileApi.ServiceTypeProto;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypeProto.deserializeBinaryFromReader);
      msg.addServiceTypes(value);
      break;
    case 2:
      var value = new proto.professionalProfileApi.CityProto;
      reader.readMessage(value,proto.professionalProfileApi.CityProto.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SecondListing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SecondListing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SecondListing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SecondListing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.professionalProfileApi.ServiceTypeProto.serializeBinaryToWriter
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.professionalProfileApi.CityProto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceTypeProto service_types = 1;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypeProto>}
 */
proto.professionalProfileApi.SecondListing.prototype.getServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypeProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypeProto, 1));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypeProto>} value
 * @return {!proto.professionalProfileApi.SecondListing} returns this
*/
proto.professionalProfileApi.SecondListing.prototype.setServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypeProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypeProto}
 */
proto.professionalProfileApi.SecondListing.prototype.addServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.professionalProfileApi.ServiceTypeProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SecondListing} returns this
 */
proto.professionalProfileApi.SecondListing.prototype.clearServiceTypesList = function() {
  return this.setServiceTypesList([]);
};


/**
 * repeated CityProto cities = 2;
 * @return {!Array<!proto.professionalProfileApi.CityProto>}
 */
proto.professionalProfileApi.SecondListing.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.CityProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.CityProto, 2));
};


/**
 * @param {!Array<!proto.professionalProfileApi.CityProto>} value
 * @return {!proto.professionalProfileApi.SecondListing} returns this
*/
proto.professionalProfileApi.SecondListing.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.professionalProfileApi.CityProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.CityProto}
 */
proto.professionalProfileApi.SecondListing.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.professionalProfileApi.CityProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SecondListing} returns this
 */
proto.professionalProfileApi.SecondListing.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceTypeProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceTypeProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceTypeProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypeProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    mode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceTypeProto}
 */
proto.professionalProfileApi.ServiceTypeProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceTypeProto;
  return proto.professionalProfileApi.ServiceTypeProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceTypeProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceTypeProto}
 */
proto.professionalProfileApi.ServiceTypeProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceTypeProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceTypeProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceTypeProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypeProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string mode = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypeProto.prototype.getMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypeProto} returns this
 */
proto.professionalProfileApi.ServiceTypeProto.prototype.setMode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.InfoProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.InfoProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.InfoProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.InfoProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    city: jspb.Message.getFieldWithDefault(msg, 1, ""),
    professionalid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.InfoProto}
 */
proto.professionalProfileApi.InfoProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.InfoProto;
  return proto.professionalProfileApi.InfoProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.InfoProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.InfoProto}
 */
proto.professionalProfileApi.InfoProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.InfoProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.InfoProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.InfoProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.InfoProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string city = 1;
 * @return {string}
 */
proto.professionalProfileApi.InfoProto.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProto} returns this
 */
proto.professionalProfileApi.InfoProto.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string professionalId = 2;
 * @return {string}
 */
proto.professionalProfileApi.InfoProto.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProto} returns this
 */
proto.professionalProfileApi.InfoProto.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.CityProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.CityProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.CityProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CityProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    city: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.CityProto}
 */
proto.professionalProfileApi.CityProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.CityProto;
  return proto.professionalProfileApi.CityProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.CityProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.CityProto}
 */
proto.professionalProfileApi.CityProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.CityProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.CityProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.CityProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CityProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string city = 1;
 * @return {string}
 */
proto.professionalProfileApi.CityProto.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CityProto} returns this
 */
proto.professionalProfileApi.CityProto.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.professionalProfileApi.CityProto.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.CityProto} returns this
 */
proto.professionalProfileApi.CityProto.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.FirstListing.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.FirstListing.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.FirstListing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.FirstListing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.FirstListing.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionsList: jspb.Message.toObjectList(msg.getProfessionsList(),
    proto.professionalProfileApi.ProfessionsProto.toObject, includeInstance),
    diseasesList: jspb.Message.toObjectList(msg.getDiseasesList(),
    proto.professionalProfileApi.DiseasesProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.FirstListing}
 */
proto.professionalProfileApi.FirstListing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.FirstListing;
  return proto.professionalProfileApi.FirstListing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.FirstListing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.FirstListing}
 */
proto.professionalProfileApi.FirstListing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.professionalProfileApi.ProfessionsProto;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionsProto.deserializeBinaryFromReader);
      msg.addProfessions(value);
      break;
    case 2:
      var value = new proto.professionalProfileApi.DiseasesProto;
      reader.readMessage(value,proto.professionalProfileApi.DiseasesProto.deserializeBinaryFromReader);
      msg.addDiseases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.FirstListing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.FirstListing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.FirstListing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.FirstListing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.professionalProfileApi.ProfessionsProto.serializeBinaryToWriter
    );
  }
  f = message.getDiseasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.professionalProfileApi.DiseasesProto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProfessionsProto professions = 1;
 * @return {!Array<!proto.professionalProfileApi.ProfessionsProto>}
 */
proto.professionalProfileApi.FirstListing.prototype.getProfessionsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionsProto, 1));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionsProto>} value
 * @return {!proto.professionalProfileApi.FirstListing} returns this
*/
proto.professionalProfileApi.FirstListing.prototype.setProfessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionsProto}
 */
proto.professionalProfileApi.FirstListing.prototype.addProfessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.professionalProfileApi.ProfessionsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.FirstListing} returns this
 */
proto.professionalProfileApi.FirstListing.prototype.clearProfessionsList = function() {
  return this.setProfessionsList([]);
};


/**
 * repeated DiseasesProto diseases = 2;
 * @return {!Array<!proto.professionalProfileApi.DiseasesProto>}
 */
proto.professionalProfileApi.FirstListing.prototype.getDiseasesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.DiseasesProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.DiseasesProto, 2));
};


/**
 * @param {!Array<!proto.professionalProfileApi.DiseasesProto>} value
 * @return {!proto.professionalProfileApi.FirstListing} returns this
*/
proto.professionalProfileApi.FirstListing.prototype.setDiseasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.professionalProfileApi.DiseasesProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.DiseasesProto}
 */
proto.professionalProfileApi.FirstListing.prototype.addDiseases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.professionalProfileApi.DiseasesProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.FirstListing} returns this
 */
proto.professionalProfileApi.FirstListing.prototype.clearDiseasesList = function() {
  return this.setDiseasesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DiseasesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DiseasesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DiseasesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DiseasesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    disease: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DiseasesProto}
 */
proto.professionalProfileApi.DiseasesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DiseasesProto;
  return proto.professionalProfileApi.DiseasesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DiseasesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DiseasesProto}
 */
proto.professionalProfileApi.DiseasesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DiseasesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DiseasesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DiseasesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DiseasesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisease();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string disease = 1;
 * @return {string}
 */
proto.professionalProfileApi.DiseasesProto.prototype.getDisease = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DiseasesProto} returns this
 */
proto.professionalProfileApi.DiseasesProto.prototype.setDisease = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    profession: jspb.Message.getFieldWithDefault(msg, 1, ""),
    speciality: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionsProto}
 */
proto.professionalProfileApi.ProfessionsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionsProto;
  return proto.professionalProfileApi.ProfessionsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionsProto}
 */
proto.professionalProfileApi.ProfessionsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeciality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfession();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpeciality();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string profession = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionsProto.prototype.getProfession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionsProto} returns this
 */
proto.professionalProfileApi.ProfessionsProto.prototype.setProfession = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string speciality = 2;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionsProto.prototype.getSpeciality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionsProto} returns this
 */
proto.professionalProfileApi.ProfessionsProto.prototype.setSpeciality = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetPartnershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetPartnershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetPartnershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetPartnershipsRequest}
 */
proto.professionalProfileApi.GetPartnershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetPartnershipsRequest;
  return proto.professionalProfileApi.GetPartnershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetPartnershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetPartnershipsRequest}
 */
proto.professionalProfileApi.GetPartnershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetPartnershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetPartnershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetPartnershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.professionalProfileApi.GetPartnershipsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartnershipsRequest} returns this
 */
proto.professionalProfileApi.GetPartnershipsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.GetPartnershipsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetPartnershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetPartnershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalList: jspb.Message.toObjectList(msg.getProfessionalList(),
    proto.professionalProfileApi.ProfessionalProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse}
 */
proto.professionalProfileApi.GetPartnershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetPartnershipsResponse;
  return proto.professionalProfileApi.GetPartnershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetPartnershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse}
 */
proto.professionalProfileApi.GetPartnershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ProfessionalProto;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalProto.deserializeBinaryFromReader);
      msg.addProfessional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetPartnershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetPartnershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ProfessionalProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse} returns this
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse} returns this
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse} returns this
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProfessionalProto professional = 5;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalProto>}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.getProfessionalList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalProto, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalProto>} value
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse} returns this
*/
proto.professionalProfileApi.GetPartnershipsResponse.prototype.setProfessionalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalProto}
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.addProfessional = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ProfessionalProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.GetPartnershipsResponse} returns this
 */
proto.professionalProfileApi.GetPartnershipsResponse.prototype.clearProfessionalList = function() {
  return this.setProfessionalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionalProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionalProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionalProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionalProto}
 */
proto.professionalProfileApi.ProfessionalProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionalProto;
  return proto.professionalProfileApi.ProfessionalProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionalProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionalProto}
 */
proto.professionalProfileApi.ProfessionalProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionalProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionalProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionalProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string professional_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalProto.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalProto} returns this
 */
proto.professionalProfileApi.ProfessionalProto.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalProto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalProto} returns this
 */
proto.professionalProfileApi.ProfessionalProto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string picture_url = 3;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalProto.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalProto} returns this
 */
proto.professionalProfileApi.ProfessionalProto.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.EmptyRequest}
 */
proto.professionalProfileApi.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.EmptyRequest;
  return proto.professionalProfileApi.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.EmptyRequest}
 */
proto.professionalProfileApi.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetInfoResponse}
 */
proto.professionalProfileApi.GetInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetInfoResponse;
  return proto.professionalProfileApi.GetInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetInfoResponse}
 */
proto.professionalProfileApi.GetInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.GetInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.GetInfoResponse} returns this
 */
proto.professionalProfileApi.GetInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.GetInfoResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetInfoResponse} returns this
 */
proto.professionalProfileApi.GetInfoResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.GetInfoResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetInfoResponse} returns this
 */
proto.professionalProfileApi.GetInfoResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    socialsecuritynumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    number: jspb.Message.getFieldWithDefault(msg, 5, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 6, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    state: jspb.Message.getFieldWithDefault(msg, 9, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateInfoRequest}
 */
proto.professionalProfileApi.UpdateInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateInfoRequest;
  return proto.professionalProfileApi.UpdateInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateInfoRequest}
 */
proto.professionalProfileApi.UpdateInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialsecuritynumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSocialsecuritynumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional string socialSecurityNumber = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getSocialsecuritynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setSocialsecuritynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identityNumber = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string birthDate = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string number = 5;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string complement = 6;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string neighborhood = 7;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string state = 9;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string postalCode = 10;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double latitude = 11;
 * @return {number}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double longitude = 12;
 * @return {number}
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateInfoRequest} returns this
 */
proto.professionalProfileApi.UpdateInfoRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateInfoResponse}
 */
proto.professionalProfileApi.UpdateInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateInfoResponse;
  return proto.professionalProfileApi.UpdateInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateInfoResponse}
 */
proto.professionalProfileApi.UpdateInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateInfoResponse} returns this
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoResponse} returns this
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateInfoResponse} returns this
 */
proto.professionalProfileApi.UpdateInfoResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddCouncilRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddCouncilRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddCouncilRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    council: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddCouncilRequest}
 */
proto.professionalProfileApi.AddCouncilRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddCouncilRequest;
  return proto.professionalProfileApi.AddCouncilRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddCouncilRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddCouncilRequest}
 */
proto.professionalProfileApi.AddCouncilRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncil(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddCouncilRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddCouncilRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddCouncilRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncil();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string council = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.getCouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddCouncilRequest} returns this
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.setCouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddCouncilRequest} returns this
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddCouncilRequest} returns this
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddCouncilRequest} returns this
 */
proto.professionalProfileApi.AddCouncilRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.NamesProfessional.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.NamesProfessional.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.NamesProfessional} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.NamesProfessional.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameommed: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namecfm: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.NamesProfessional}
 */
proto.professionalProfileApi.NamesProfessional.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.NamesProfessional;
  return proto.professionalProfileApi.NamesProfessional.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.NamesProfessional} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.NamesProfessional}
 */
proto.professionalProfileApi.NamesProfessional.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameommed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamecfm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.NamesProfessional.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.NamesProfessional.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.NamesProfessional} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.NamesProfessional.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameommed();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamecfm();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string nameOmmed = 1;
 * @return {string}
 */
proto.professionalProfileApi.NamesProfessional.prototype.getNameommed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.NamesProfessional} returns this
 */
proto.professionalProfileApi.NamesProfessional.prototype.setNameommed = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nameCfm = 2;
 * @return {string}
 */
proto.professionalProfileApi.NamesProfessional.prototype.getNamecfm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.NamesProfessional} returns this
 */
proto.professionalProfileApi.NamesProfessional.prototype.setNamecfm = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddCouncilResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddCouncilResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddCouncilResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddCouncilResponse}
 */
proto.professionalProfileApi.AddCouncilResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddCouncilResponse;
  return proto.professionalProfileApi.AddCouncilResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddCouncilResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddCouncilResponse}
 */
proto.professionalProfileApi.AddCouncilResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddCouncilResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddCouncilResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddCouncilResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddCouncilResponse} returns this
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddCouncilResponse} returns this
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddCouncilResponse} returns this
 */
proto.professionalProfileApi.AddCouncilResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteCouncilRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteCouncilRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteCouncilRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteCouncilRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteCouncilRequest}
 */
proto.professionalProfileApi.DeleteCouncilRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteCouncilRequest;
  return proto.professionalProfileApi.DeleteCouncilRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteCouncilRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteCouncilRequest}
 */
proto.professionalProfileApi.DeleteCouncilRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteCouncilRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteCouncilRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteCouncilRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteCouncilRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteCouncilRequest.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteCouncilRequest} returns this
 */
proto.professionalProfileApi.DeleteCouncilRequest.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteCouncilResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteCouncilResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteCouncilResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteCouncilResponse}
 */
proto.professionalProfileApi.DeleteCouncilResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteCouncilResponse;
  return proto.professionalProfileApi.DeleteCouncilResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteCouncilResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteCouncilResponse}
 */
proto.professionalProfileApi.DeleteCouncilResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteCouncilResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteCouncilResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteCouncilResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteCouncilResponse} returns this
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteCouncilResponse} returns this
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteCouncilResponse} returns this
 */
proto.professionalProfileApi.DeleteCouncilResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddSpecialtyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddSpecialtyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddSpecialtyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rqeNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddSpecialtyRequest}
 */
proto.professionalProfileApi.AddSpecialtyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddSpecialtyRequest;
  return proto.professionalProfileApi.AddSpecialtyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddSpecialtyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddSpecialtyRequest}
 */
proto.professionalProfileApi.AddSpecialtyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRqeNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddSpecialtyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddSpecialtyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddSpecialtyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRqeNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddSpecialtyRequest} returns this
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddSpecialtyRequest} returns this
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rqe_number = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.getRqeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddSpecialtyRequest} returns this
 */
proto.professionalProfileApi.AddSpecialtyRequest.prototype.setRqeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddSpecialtyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddSpecialtyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddSpecialtyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddSpecialtyResponse}
 */
proto.professionalProfileApi.AddSpecialtyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddSpecialtyResponse;
  return proto.professionalProfileApi.AddSpecialtyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddSpecialtyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddSpecialtyResponse}
 */
proto.professionalProfileApi.AddSpecialtyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddSpecialtyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddSpecialtyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddSpecialtyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddSpecialtyResponse} returns this
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddSpecialtyResponse} returns this
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddSpecialtyResponse} returns this
 */
proto.professionalProfileApi.AddSpecialtyResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteSpecialtyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteSpecialtyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    specialtyid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteSpecialtyRequest}
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteSpecialtyRequest;
  return proto.professionalProfileApi.DeleteSpecialtyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteSpecialtyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteSpecialtyRequest}
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialtyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteSpecialtyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteSpecialtyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpecialtyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string SpecialtyId = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.prototype.getSpecialtyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteSpecialtyRequest} returns this
 */
proto.professionalProfileApi.DeleteSpecialtyRequest.prototype.setSpecialtyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteSpecialtyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteSpecialtyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteSpecialtyResponse}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteSpecialtyResponse;
  return proto.professionalProfileApi.DeleteSpecialtyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteSpecialtyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteSpecialtyResponse}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteSpecialtyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteSpecialtyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteSpecialtyResponse} returns this
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteSpecialtyResponse} returns this
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteSpecialtyResponse} returns this
 */
proto.professionalProfileApi.DeleteSpecialtyResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ActionActuation.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ActionActuation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ActionActuation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionActuation.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rqeNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exclusive: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ActionActuation}
 */
proto.professionalProfileApi.ActionActuation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ActionActuation;
  return proto.professionalProfileApi.ActionActuation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ActionActuation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ActionActuation}
 */
proto.professionalProfileApi.ActionActuation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRqeNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExclusive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ActionActuation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ActionActuation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ActionActuation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionActuation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRqeNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExclusive();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string actionActuationId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ActionActuation.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionActuation} returns this
 */
proto.professionalProfileApi.ActionActuation.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rqe_number = 2;
 * @return {string}
 */
proto.professionalProfileApi.ActionActuation.prototype.getRqeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionActuation} returns this
 */
proto.professionalProfileApi.ActionActuation.prototype.setRqeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ActionActuation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionActuation} returns this
 */
proto.professionalProfileApi.ActionActuation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 exclusive = 4;
 * @return {number}
 */
proto.professionalProfileApi.ActionActuation.prototype.getExclusive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ActionActuation} returns this
 */
proto.professionalProfileApi.ActionActuation.prototype.setExclusive = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.Speciality.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Speciality.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Speciality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Speciality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Speciality.toObject = function(includeInstance, msg) {
  var f, obj = {
    specialtyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rqeNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actuationList: jspb.Message.toObjectList(msg.getActuationList(),
    proto.professionalProfileApi.ActionActuation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Speciality}
 */
proto.professionalProfileApi.Speciality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Speciality;
  return proto.professionalProfileApi.Speciality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Speciality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Speciality}
 */
proto.professionalProfileApi.Speciality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialtyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRqeNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ActionActuation;
      reader.readMessage(value,proto.professionalProfileApi.ActionActuation.deserializeBinaryFromReader);
      msg.addActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Speciality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Speciality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Speciality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Speciality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpecialtyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRqeNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ActionActuation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string specialtyId = 1;
 * @return {string}
 */
proto.professionalProfileApi.Speciality.prototype.getSpecialtyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Speciality} returns this
 */
proto.professionalProfileApi.Speciality.prototype.setSpecialtyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rqe_number = 2;
 * @return {string}
 */
proto.professionalProfileApi.Speciality.prototype.getRqeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Speciality} returns this
 */
proto.professionalProfileApi.Speciality.prototype.setRqeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.Speciality.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Speciality} returns this
 */
proto.professionalProfileApi.Speciality.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ActionActuation actuation = 5;
 * @return {!Array<!proto.professionalProfileApi.ActionActuation>}
 */
proto.professionalProfileApi.Speciality.prototype.getActuationList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ActionActuation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ActionActuation, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ActionActuation>} value
 * @return {!proto.professionalProfileApi.Speciality} returns this
*/
proto.professionalProfileApi.Speciality.prototype.setActuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ActionActuation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ActionActuation}
 */
proto.professionalProfileApi.Speciality.prototype.addActuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ActionActuation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.Speciality} returns this
 */
proto.professionalProfileApi.Speciality.prototype.clearActuationList = function() {
  return this.setActuationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.CouncilProfessional.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.CouncilProfessional.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.CouncilProfessional} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CouncilProfessional.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    professionalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    council: jspb.Message.getFieldWithDefault(msg, 3, ""),
    number: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    validation: jspb.Message.getFieldWithDefault(msg, 8, ""),
    specialityList: jspb.Message.toObjectList(msg.getSpecialityList(),
    proto.professionalProfileApi.Speciality.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.CouncilProfessional}
 */
proto.professionalProfileApi.CouncilProfessional.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.CouncilProfessional;
  return proto.professionalProfileApi.CouncilProfessional.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.CouncilProfessional} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.CouncilProfessional}
 */
proto.professionalProfileApi.CouncilProfessional.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncil(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidation(value);
      break;
    case 9:
      var value = new proto.professionalProfileApi.Speciality;
      reader.readMessage(value,proto.professionalProfileApi.Speciality.deserializeBinaryFromReader);
      msg.addSpeciality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.CouncilProfessional.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.CouncilProfessional} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CouncilProfessional.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouncil();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getValidation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSpecialityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.professionalProfileApi.Speciality.serializeBinaryToWriter
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string professionalId = 2;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string council = 3;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getCouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setCouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string number = 4;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string validation = 8;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.setValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Speciality speciality = 9;
 * @return {!Array<!proto.professionalProfileApi.Speciality>}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.getSpecialityList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Speciality>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Speciality, 9));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Speciality>} value
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
*/
proto.professionalProfileApi.CouncilProfessional.prototype.setSpecialityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.professionalProfileApi.Speciality=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Speciality}
 */
proto.professionalProfileApi.CouncilProfessional.prototype.addSpeciality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.professionalProfileApi.Speciality, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.CouncilProfessional} returns this
 */
proto.professionalProfileApi.CouncilProfessional.prototype.clearSpecialityList = function() {
  return this.setSpecialityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListCouncilRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListCouncilRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListCouncilRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCouncilRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListCouncilRequest}
 */
proto.professionalProfileApi.ListCouncilRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListCouncilRequest;
  return proto.professionalProfileApi.ListCouncilRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListCouncilRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListCouncilRequest}
 */
proto.professionalProfileApi.ListCouncilRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListCouncilRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListCouncilRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListCouncilRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCouncilRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListCouncilResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListCouncilResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListCouncilResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCouncilResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    councilprofessionalsList: jspb.Message.toObjectList(msg.getCouncilprofessionalsList(),
    proto.professionalProfileApi.CouncilProfessional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListCouncilResponse}
 */
proto.professionalProfileApi.ListCouncilResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListCouncilResponse;
  return proto.professionalProfileApi.ListCouncilResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListCouncilResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListCouncilResponse}
 */
proto.professionalProfileApi.ListCouncilResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.CouncilProfessional;
      reader.readMessage(value,proto.professionalProfileApi.CouncilProfessional.deserializeBinaryFromReader);
      msg.addCouncilprofessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListCouncilResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListCouncilResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCouncilResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouncilprofessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.CouncilProfessional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListCouncilResponse} returns this
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncilResponse} returns this
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ErrorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCouncilResponse} returns this
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CouncilProfessional councilProfessionals = 4;
 * @return {!Array<!proto.professionalProfileApi.CouncilProfessional>}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.getCouncilprofessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.CouncilProfessional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.CouncilProfessional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.CouncilProfessional>} value
 * @return {!proto.professionalProfileApi.ListCouncilResponse} returns this
*/
proto.professionalProfileApi.ListCouncilResponse.prototype.setCouncilprofessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.CouncilProfessional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.CouncilProfessional}
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.addCouncilprofessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.CouncilProfessional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListCouncilResponse} returns this
 */
proto.professionalProfileApi.ListCouncilResponse.prototype.clearCouncilprofessionalsList = function() {
  return this.setCouncilprofessionalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteActionActuationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteActionActuationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteActionActuationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteActionActuationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteActionActuationRequest}
 */
proto.professionalProfileApi.DeleteActionActuationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteActionActuationRequest;
  return proto.professionalProfileApi.DeleteActionActuationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteActionActuationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteActionActuationRequest}
 */
proto.professionalProfileApi.DeleteActionActuationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteActionActuationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteActionActuationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteActionActuationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteActionActuationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string actionActuationId = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteActionActuationRequest.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteActionActuationRequest} returns this
 */
proto.professionalProfileApi.DeleteActionActuationRequest.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteActionActuationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteActionActuationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteActionActuationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteActionActuationResponse}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteActionActuationResponse;
  return proto.professionalProfileApi.DeleteActionActuationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteActionActuationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteActionActuationResponse}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteActionActuationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteActionActuationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteActionActuationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteActionActuationResponse} returns this
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteActionActuationResponse} returns this
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteActionActuationResponse} returns this
 */
proto.professionalProfileApi.DeleteActionActuationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.EditActionActuationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.EditActionActuationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.EditActionActuationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rqeNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.EditActionActuationRequest}
 */
proto.professionalProfileApi.EditActionActuationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.EditActionActuationRequest;
  return proto.professionalProfileApi.EditActionActuationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.EditActionActuationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.EditActionActuationRequest}
 */
proto.professionalProfileApi.EditActionActuationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRqeNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.EditActionActuationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.EditActionActuationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.EditActionActuationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRqeNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string actionActuationId = 1;
 * @return {string}
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.EditActionActuationRequest} returns this
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.EditActionActuationRequest} returns this
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rqe_number = 3;
 * @return {string}
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.getRqeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.EditActionActuationRequest} returns this
 */
proto.professionalProfileApi.EditActionActuationRequest.prototype.setRqeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.EditActionActuationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.EditActionActuationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.EditActionActuationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.EditActionActuationResponse}
 */
proto.professionalProfileApi.EditActionActuationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.EditActionActuationResponse;
  return proto.professionalProfileApi.EditActionActuationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.EditActionActuationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.EditActionActuationResponse}
 */
proto.professionalProfileApi.EditActionActuationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.EditActionActuationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.EditActionActuationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.EditActionActuationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.EditActionActuationResponse} returns this
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.EditActionActuationResponse} returns this
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.EditActionActuationResponse} returns this
 */
proto.professionalProfileApi.EditActionActuationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateNameRequest}
 */
proto.professionalProfileApi.UpdateNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateNameRequest;
  return proto.professionalProfileApi.UpdateNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateNameRequest}
 */
proto.professionalProfileApi.UpdateNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateNameRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateNameRequest} returns this
 */
proto.professionalProfileApi.UpdateNameRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lastName = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateNameRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateNameRequest} returns this
 */
proto.professionalProfileApi.UpdateNameRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateNameResponse}
 */
proto.professionalProfileApi.UpdateNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateNameResponse;
  return proto.professionalProfileApi.UpdateNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateNameResponse}
 */
proto.professionalProfileApi.UpdateNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateNameResponse} returns this
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateNameResponse} returns this
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateNameResponse} returns this
 */
proto.professionalProfileApi.UpdateNameResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Professional.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Professional.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Professional} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Professional.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    socialsecuritynumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 9, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 10, ""),
    picturefileid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.Professional.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Professional;
  return proto.professionalProfileApi.Professional.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Professional} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.Professional.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialsecuritynumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicturefileid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Professional.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Professional.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Professional} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Professional.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSocialsecuritynumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPicturefileid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string socialSecurityNumber = 5;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getSocialsecuritynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setSocialsecuritynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string identityNumber = 6;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string birthDate = 7;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phoneNumber = 8;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdAt = 9;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string updatedAt = 10;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string pictureFileId = 11;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getPicturefileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setPicturefileid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string pictureUrl = 12;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalsRequest}
 */
proto.professionalProfileApi.ListProfessionalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalsRequest;
  return proto.professionalProfileApi.ListProfessionalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalsRequest}
 */
proto.professionalProfileApi.ListProfessionalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListProfessionalsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalsList: jspb.Message.toObjectList(msg.getProfessionalsList(),
    proto.professionalProfileApi.Professional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse}
 */
proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalsResponse;
  return proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse}
 */
proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Professional;
      reader.readMessage(value,proto.professionalProfileApi.Professional.deserializeBinaryFromReader);
      msg.addProfessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Professional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Professional professionals = 4;
 * @return {!Array<!proto.professionalProfileApi.Professional>}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getProfessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Professional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Professional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Professional>} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
*/
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setProfessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Professional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.addProfessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Professional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.clearProfessionalsList = function() {
  return this.setProfessionalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddLocationRequest}
 */
proto.professionalProfileApi.AddLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddLocationRequest;
  return proto.professionalProfileApi.AddLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddLocationRequest}
 */
proto.professionalProfileApi.AddLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional string color = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string neighborhood = 8;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postalCode = 11;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double latitude = 12;
 * @return {number}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double longitude = 13;
 * @return {number}
 */
proto.professionalProfileApi.AddLocationRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.AddLocationRequest} returns this
 */
proto.professionalProfileApi.AddLocationRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddLocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddLocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddLocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddLocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddLocationResponse}
 */
proto.professionalProfileApi.AddLocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddLocationResponse;
  return proto.professionalProfileApi.AddLocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddLocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddLocationResponse}
 */
proto.professionalProfileApi.AddLocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddLocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddLocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddLocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddLocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddLocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddLocationResponse} returns this
 */
proto.professionalProfileApi.AddLocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationResponse} returns this
 */
proto.professionalProfileApi.AddLocationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddLocationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddLocationResponse} returns this
 */
proto.professionalProfileApi.AddLocationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 3, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Address}
 */
proto.professionalProfileApi.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Address;
  return proto.professionalProfileApi.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Address}
 */
proto.professionalProfileApi.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string complement = 3;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string neighborhood = 4;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postalCode = 7;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Council.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Council.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Council} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Council.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Council}
 */
proto.professionalProfileApi.Council.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Council;
  return proto.professionalProfileApi.Council.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Council} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Council}
 */
proto.professionalProfileApi.Council.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Council.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Council.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Council} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Council.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListActiveRegistriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListActiveRegistriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListActiveRegistriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListActiveRegistriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListActiveRegistriesRequest}
 */
proto.professionalProfileApi.ListActiveRegistriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListActiveRegistriesRequest;
  return proto.professionalProfileApi.ListActiveRegistriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListActiveRegistriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListActiveRegistriesRequest}
 */
proto.professionalProfileApi.ListActiveRegistriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListActiveRegistriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListActiveRegistriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListActiveRegistriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListActiveRegistriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListActiveRegistriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListActiveRegistriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    councilprofessionalsList: jspb.Message.toObjectList(msg.getCouncilprofessionalsList(),
    proto.professionalProfileApi.CouncilProfessional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListActiveRegistriesResponse;
  return proto.professionalProfileApi.ListActiveRegistriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListActiveRegistriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.CouncilProfessional;
      reader.readMessage(value,proto.professionalProfileApi.CouncilProfessional.deserializeBinaryFromReader);
      msg.addCouncilprofessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListActiveRegistriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListActiveRegistriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouncilprofessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.CouncilProfessional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse} returns this
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse} returns this
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ErrorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse} returns this
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CouncilProfessional councilProfessionals = 4;
 * @return {!Array<!proto.professionalProfileApi.CouncilProfessional>}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.getCouncilprofessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.CouncilProfessional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.CouncilProfessional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.CouncilProfessional>} value
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse} returns this
*/
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.setCouncilprofessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.CouncilProfessional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.CouncilProfessional}
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.addCouncilprofessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.CouncilProfessional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListActiveRegistriesResponse} returns this
 */
proto.professionalProfileApi.ListActiveRegistriesResponse.prototype.clearCouncilprofessionalsList = function() {
  return this.setCouncilprofessionalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceHasSpecialities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceHasSpecialities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceHasSpecialities.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    specialityid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceHasSpecialities}
 */
proto.professionalProfileApi.ServiceHasSpecialities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceHasSpecialities;
  return proto.professionalProfileApi.ServiceHasSpecialities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceHasSpecialities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceHasSpecialities}
 */
proto.professionalProfileApi.ServiceHasSpecialities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialityid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceHasSpecialities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceHasSpecialities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceHasSpecialities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpecialityid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string specialityId = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.getSpecialityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.setSpecialityid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string actionActuationId = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.ServiceHasSpecialities.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionalServiceTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalServiceTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicemode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 4, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionalServiceTypes;
  return proto.professionalProfileApi.ProfessionalServiceTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionalServiceTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalServiceTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string serviceMode = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 estimated_time = 2;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 max_displacement = 4;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double valueKm = 5;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypes.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.AddServiceRequest.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tuss: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tussterm: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sus: jspb.Message.getFieldWithDefault(msg, 4, ""),
    susprocedure: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_return: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    timeReturn: jspb.Message.getFieldWithDefault(msg, 8, 0),
    serviceHasSpecialitiesList: jspb.Message.toObjectList(msg.getServiceHasSpecialitiesList(),
    proto.professionalProfileApi.ServiceHasSpecialities.toObject, includeInstance),
    professionalServiceTypesList: jspb.Message.toObjectList(msg.getProfessionalServiceTypesList(),
    proto.professionalProfileApi.ProfessionalServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddServiceRequest}
 */
proto.professionalProfileApi.AddServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddServiceRequest;
  return proto.professionalProfileApi.AddServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddServiceRequest}
 */
proto.professionalProfileApi.AddServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuss(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTussterm(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSusprocedure(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturn(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeReturn(value);
      break;
    case 9:
      var value = new proto.professionalProfileApi.ServiceHasSpecialities;
      reader.readMessage(value,proto.professionalProfileApi.ServiceHasSpecialities.deserializeBinaryFromReader);
      msg.addServiceHasSpecialities(value);
      break;
    case 10:
      var value = new proto.professionalProfileApi.ProfessionalServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalServiceTypes.deserializeBinaryFromReader);
      msg.addProfessionalServiceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTuss();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTussterm();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSusprocedure();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReturn();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTimeReturn();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getServiceHasSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.professionalProfileApi.ServiceHasSpecialities.serializeBinaryToWriter
    );
  }
  f = message.getProfessionalServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.professionalProfileApi.ProfessionalServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tuss = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tussTerm = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getTussterm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setTussterm = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sus = 4;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getSus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setSus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string susProcedure = 5;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getSusprocedure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setSusprocedure = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool return = 7;
 * @return {boolean}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getReturn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setReturn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 time_return = 8;
 * @return {number}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getTimeReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.setTimeReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated ServiceHasSpecialities service_has_specialities = 9;
 * @return {!Array<!proto.professionalProfileApi.ServiceHasSpecialities>}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getServiceHasSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceHasSpecialities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceHasSpecialities, 9));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceHasSpecialities>} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
*/
proto.professionalProfileApi.AddServiceRequest.prototype.setServiceHasSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceHasSpecialities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceHasSpecialities}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.addServiceHasSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.professionalProfileApi.ServiceHasSpecialities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.clearServiceHasSpecialitiesList = function() {
  return this.setServiceHasSpecialitiesList([]);
};


/**
 * repeated ProfessionalServiceTypes professional_service_types = 10;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalServiceTypes>}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.getProfessionalServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalServiceTypes, 10));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalServiceTypes>} value
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
*/
proto.professionalProfileApi.AddServiceRequest.prototype.setProfessionalServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypes}
 */
proto.professionalProfileApi.AddServiceRequest.prototype.addProfessionalServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.professionalProfileApi.ProfessionalServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.AddServiceRequest} returns this
 */
proto.professionalProfileApi.AddServiceRequest.prototype.clearProfessionalServiceTypesList = function() {
  return this.setProfessionalServiceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddServiceResponse}
 */
proto.professionalProfileApi.AddServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddServiceResponse;
  return proto.professionalProfileApi.AddServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddServiceResponse}
 */
proto.professionalProfileApi.AddServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddServiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddServiceResponse} returns this
 */
proto.professionalProfileApi.AddServiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceResponse} returns this
 */
proto.professionalProfileApi.AddServiceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceResponse} returns this
 */
proto.professionalProfileApi.AddServiceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceRequest}
 */
proto.professionalProfileApi.ListServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceRequest;
  return proto.professionalProfileApi.ListServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceRequest}
 */
proto.professionalProfileApi.ListServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceRequest} returns this
 */
proto.professionalProfileApi.ListServiceRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServiceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ListService.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceResponse}
 */
proto.professionalProfileApi.ListServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceResponse;
  return proto.professionalProfileApi.ListServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceResponse}
 */
proto.professionalProfileApi.ListServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ListService;
      reader.readMessage(value,proto.professionalProfileApi.ListService.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ListService.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceResponse} returns this
 */
proto.professionalProfileApi.ListServiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceResponse} returns this
 */
proto.professionalProfileApi.ListServiceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceResponse} returns this
 */
proto.professionalProfileApi.ListServiceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListService services = 4;
 * @return {!Array<!proto.professionalProfileApi.ListService>}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListService>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListService, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListService>} value
 * @return {!proto.professionalProfileApi.ListServiceResponse} returns this
*/
proto.professionalProfileApi.ListServiceResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ListService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListService}
 */
proto.professionalProfileApi.ListServiceResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ListService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceResponse} returns this
 */
proto.professionalProfileApi.ListServiceResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Specialities.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Specialities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Specialities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Specialities.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profcouncilid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    desccouncil: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Specialities}
 */
proto.professionalProfileApi.Specialities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Specialities;
  return proto.professionalProfileApi.Specialities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Specialities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Specialities}
 */
proto.professionalProfileApi.Specialities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfcouncilid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesccouncil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Specialities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Specialities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Specialities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Specialities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfcouncilid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDesccouncil();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Specialities.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Specialities} returns this
 */
proto.professionalProfileApi.Specialities.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profCouncilId = 2;
 * @return {string}
 */
proto.professionalProfileApi.Specialities.prototype.getProfcouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Specialities} returns this
 */
proto.professionalProfileApi.Specialities.prototype.setProfcouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.professionalProfileApi.Specialities.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Specialities} returns this
 */
proto.professionalProfileApi.Specialities.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string descCouncil = 4;
 * @return {string}
 */
proto.professionalProfileApi.Specialities.prototype.getDesccouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Specialities} returns this
 */
proto.professionalProfileApi.Specialities.prototype.setDesccouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 6, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    valuepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServiceTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceTypes;
  return proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuepercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getValuepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serviceMode = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 estimated_time = 3;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double value = 5;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 max_displacement = 6;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double valueKm = 7;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double valuePercentage = 8;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypes.prototype.getValuepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypes} returns this
 */
proto.professionalProfileApi.ServiceTypes.prototype.setValuepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 6, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceTypes}
 */
proto.professionalProfileApi.ListServiceTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceTypes;
  return proto.professionalProfileApi.ListServiceTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceTypes}
 */
proto.professionalProfileApi.ListServiceTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serviceMode = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 estimated_time = 3;
 * @return {number}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double value = 5;
 * @return {number}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 max_displacement = 6;
 * @return {number}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double valueKm = 7;
 * @return {number}
 */
proto.professionalProfileApi.ListServiceTypes.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListServiceTypes} returns this
 */
proto.professionalProfileApi.ListServiceTypes.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListService.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListService.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListService.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.Specialities.toObject, includeInstance),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ListServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListService}
 */
proto.professionalProfileApi.ListService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListService;
  return proto.professionalProfileApi.ListService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListService}
 */
proto.professionalProfileApi.ListService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.Specialities;
      reader.readMessage(value,proto.professionalProfileApi.Specialities.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.ListServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ListServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.Specialities.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.ListServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListService.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListService} returns this
 */
proto.professionalProfileApi.ListService.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListService.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListService} returns this
 */
proto.professionalProfileApi.ListService.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListService.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListService} returns this
 */
proto.professionalProfileApi.ListService.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListService.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListService} returns this
 */
proto.professionalProfileApi.ListService.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Specialities specialities = 5;
 * @return {!Array<!proto.professionalProfileApi.Specialities>}
 */
proto.professionalProfileApi.ListService.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Specialities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Specialities, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Specialities>} value
 * @return {!proto.professionalProfileApi.ListService} returns this
*/
proto.professionalProfileApi.ListService.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.Specialities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Specialities}
 */
proto.professionalProfileApi.ListService.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.Specialities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListService} returns this
 */
proto.professionalProfileApi.ListService.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * repeated ListServiceTypes serviceTypes = 6;
 * @return {!Array<!proto.professionalProfileApi.ListServiceTypes>}
 */
proto.professionalProfileApi.ListService.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListServiceTypes, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListServiceTypes>} value
 * @return {!proto.professionalProfileApi.ListService} returns this
*/
proto.professionalProfileApi.ListService.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.ListServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListServiceTypes}
 */
proto.professionalProfileApi.ListService.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.ListServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListService} returns this
 */
proto.professionalProfileApi.ListService.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.Service.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.Specialities.toObject, includeInstance),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Service}
 */
proto.professionalProfileApi.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Service;
  return proto.professionalProfileApi.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Service}
 */
proto.professionalProfileApi.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.Specialities;
      reader.readMessage(value,proto.professionalProfileApi.Specialities.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.ServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.Specialities.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Service.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Service} returns this
 */
proto.professionalProfileApi.Service.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.Service.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Service} returns this
 */
proto.professionalProfileApi.Service.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.Service.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Service} returns this
 */
proto.professionalProfileApi.Service.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.Service.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Service} returns this
 */
proto.professionalProfileApi.Service.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Specialities specialities = 5;
 * @return {!Array<!proto.professionalProfileApi.Specialities>}
 */
proto.professionalProfileApi.Service.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Specialities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Specialities, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Specialities>} value
 * @return {!proto.professionalProfileApi.Service} returns this
*/
proto.professionalProfileApi.Service.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.Specialities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Specialities}
 */
proto.professionalProfileApi.Service.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.Specialities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.Service} returns this
 */
proto.professionalProfileApi.Service.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * repeated ServiceTypes serviceTypes = 6;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypes>}
 */
proto.professionalProfileApi.Service.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypes, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypes>} value
 * @return {!proto.professionalProfileApi.Service} returns this
*/
proto.professionalProfileApi.Service.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.Service.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.ServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.Service} returns this
 */
proto.professionalProfileApi.Service.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchListServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchListServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchListServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    word: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchListServiceRequest}
 */
proto.professionalProfileApi.SearchListServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchListServiceRequest;
  return proto.professionalProfileApi.SearchListServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchListServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchListServiceRequest}
 */
proto.professionalProfileApi.SearchListServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchListServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchListServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchListServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.professionalProfileApi.SearchListServiceRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListServiceRequest} returns this
 */
proto.professionalProfileApi.SearchListServiceRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string word = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchListServiceRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListServiceRequest} returns this
 */
proto.professionalProfileApi.SearchListServiceRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchListServiceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchListServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchListServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchListServiceResponse}
 */
proto.professionalProfileApi.SearchListServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchListServiceResponse;
  return proto.professionalProfileApi.SearchListServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchListServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchListServiceResponse}
 */
proto.professionalProfileApi.SearchListServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Service;
      reader.readMessage(value,proto.professionalProfileApi.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchListServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchListServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Service.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchListServiceResponse} returns this
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListServiceResponse} returns this
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListServiceResponse} returns this
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Service services = 4;
 * @return {!Array<!proto.professionalProfileApi.Service>}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Service, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Service>} value
 * @return {!proto.professionalProfileApi.SearchListServiceResponse} returns this
*/
proto.professionalProfileApi.SearchListServiceResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Service}
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchListServiceResponse} returns this
 */
proto.professionalProfileApi.SearchListServiceResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceIdRequest}
 */
proto.professionalProfileApi.ListServiceIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceIdRequest;
  return proto.professionalProfileApi.ListServiceIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceIdRequest}
 */
proto.professionalProfileApi.ListServiceIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdRequest} returns this
 */
proto.professionalProfileApi.ListServiceIdRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceIdLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceIdLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceIdLocationRequest}
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceIdLocationRequest;
  return proto.professionalProfileApi.ListServiceIdLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceIdLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceIdLocationRequest}
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceIdLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceIdLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdLocationRequest} returns this
 */
proto.professionalProfileApi.ListServiceIdLocationRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServiceIdResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServiceList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceIdResponse}
 */
proto.professionalProfileApi.ListServiceIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceIdResponse;
  return proto.professionalProfileApi.ListServiceIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceIdResponse}
 */
proto.professionalProfileApi.ListServiceIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceList;
      reader.readMessage(value,proto.professionalProfileApi.ServiceList.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceIdResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceList services = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceList>}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceList, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceList>} value
 * @return {!proto.professionalProfileApi.ListServiceIdResponse} returns this
*/
proto.professionalProfileApi.ListServiceIdResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceList}
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceIdResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceIdLocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceIdLocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServiceListLocation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceIdLocationResponse;
  return proto.professionalProfileApi.ListServiceIdLocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceIdLocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceListLocation;
      reader.readMessage(value,proto.professionalProfileApi.ServiceListLocation.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceIdLocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceIdLocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceListLocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceListLocation services = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceListLocation>}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceListLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceListLocation, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceListLocation>} value
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse} returns this
*/
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceListLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceListLocation}
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceListLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceIdLocationResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdLocationResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServiceList.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceList.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tuss: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tussterm: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sus: jspb.Message.getFieldWithDefault(msg, 7, ""),
    susprocedure: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pb_return: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeReturn: jspb.Message.getFieldWithDefault(msg, 10, 0),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.SpecialitiesList.toObject, includeInstance),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceList}
 */
proto.professionalProfileApi.ServiceList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceList;
  return proto.professionalProfileApi.ServiceList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceList}
 */
proto.professionalProfileApi.ServiceList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuss(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTussterm(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSusprocedure(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReturn(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeReturn(value);
      break;
    case 11:
      var value = new proto.professionalProfileApi.SpecialitiesList;
      reader.readMessage(value,proto.professionalProfileApi.SpecialitiesList.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 12:
      var value = new proto.professionalProfileApi.ServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTuss();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTussterm();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSusprocedure();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReturn();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTimeReturn();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.professionalProfileApi.SpecialitiesList.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tuss = 5;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tussTerm = 6;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getTussterm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setTussterm = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sus = 7;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getSus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setSus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string susProcedure = 8;
 * @return {string}
 */
proto.professionalProfileApi.ServiceList.prototype.getSusprocedure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setSusprocedure = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 return = 9;
 * @return {number}
 */
proto.professionalProfileApi.ServiceList.prototype.getReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 time_return = 10;
 * @return {number}
 */
proto.professionalProfileApi.ServiceList.prototype.getTimeReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.setTimeReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated SpecialitiesList specialities = 11;
 * @return {!Array<!proto.professionalProfileApi.SpecialitiesList>}
 */
proto.professionalProfileApi.ServiceList.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SpecialitiesList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SpecialitiesList, 11));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SpecialitiesList>} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
*/
proto.professionalProfileApi.ServiceList.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.professionalProfileApi.SpecialitiesList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SpecialitiesList}
 */
proto.professionalProfileApi.ServiceList.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.professionalProfileApi.SpecialitiesList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * repeated ServiceTypes serviceTypes = 12;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypes>}
 */
proto.professionalProfileApi.ServiceList.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypes, 12));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypes>} value
 * @return {!proto.professionalProfileApi.ServiceList} returns this
*/
proto.professionalProfileApi.ServiceList.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServiceList.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.professionalProfileApi.ServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceList} returns this
 */
proto.professionalProfileApi.ServiceList.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServiceListLocation.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceListLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceListLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceListLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tuss: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tussterm: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sus: jspb.Message.getFieldWithDefault(msg, 7, ""),
    susprocedure: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pb_return: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeReturn: jspb.Message.getFieldWithDefault(msg, 10, 0),
    councilList: jspb.Message.toObjectList(msg.getCouncilList(),
    proto.professionalProfileApi.CouncilProf.toObject, includeInstance),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceListLocation}
 */
proto.professionalProfileApi.ServiceListLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceListLocation;
  return proto.professionalProfileApi.ServiceListLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceListLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceListLocation}
 */
proto.professionalProfileApi.ServiceListLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuss(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTussterm(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSusprocedure(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReturn(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeReturn(value);
      break;
    case 11:
      var value = new proto.professionalProfileApi.CouncilProf;
      reader.readMessage(value,proto.professionalProfileApi.CouncilProf.deserializeBinaryFromReader);
      msg.addCouncil(value);
      break;
    case 12:
      var value = new proto.professionalProfileApi.ServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceListLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceListLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceListLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTuss();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTussterm();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSusprocedure();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReturn();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTimeReturn();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCouncilList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.professionalProfileApi.CouncilProf.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tuss = 5;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tussTerm = 6;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getTussterm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setTussterm = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sus = 7;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getSus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setSus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string susProcedure = 8;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getSusprocedure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setSusprocedure = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 return = 9;
 * @return {number}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 time_return = 10;
 * @return {number}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getTimeReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.setTimeReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated CouncilProf council = 11;
 * @return {!Array<!proto.professionalProfileApi.CouncilProf>}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getCouncilList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.CouncilProf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.CouncilProf, 11));
};


/**
 * @param {!Array<!proto.professionalProfileApi.CouncilProf>} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
*/
proto.professionalProfileApi.ServiceListLocation.prototype.setCouncilList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.professionalProfileApi.CouncilProf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.CouncilProf}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.addCouncil = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.professionalProfileApi.CouncilProf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.clearCouncilList = function() {
  return this.setCouncilList([]);
};


/**
 * repeated ServiceTypes serviceTypes = 12;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypes>}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypes, 12));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypes>} value
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
*/
proto.professionalProfileApi.ServiceListLocation.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServiceListLocation.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.professionalProfileApi.ServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceListLocation} returns this
 */
proto.professionalProfileApi.ServiceListLocation.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.CouncilProf.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.CouncilProf.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.CouncilProf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.CouncilProf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CouncilProf.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    council: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    specialityList: jspb.Message.toObjectList(msg.getSpecialityList(),
    proto.professionalProfileApi.Spec.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.CouncilProf}
 */
proto.professionalProfileApi.CouncilProf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.CouncilProf;
  return proto.professionalProfileApi.CouncilProf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.CouncilProf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.CouncilProf}
 */
proto.professionalProfileApi.CouncilProf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Spec;
      reader.readMessage(value,proto.professionalProfileApi.Spec.deserializeBinaryFromReader);
      msg.addSpeciality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.CouncilProf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.CouncilProf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.CouncilProf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CouncilProf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouncil();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpecialityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Spec.serializeBinaryToWriter
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProf.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProf} returns this
 */
proto.professionalProfileApi.CouncilProf.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string council = 2;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProf.prototype.getCouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProf} returns this
 */
proto.professionalProfileApi.CouncilProf.prototype.setCouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.professionalProfileApi.CouncilProf.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CouncilProf} returns this
 */
proto.professionalProfileApi.CouncilProf.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Spec speciality = 4;
 * @return {!Array<!proto.professionalProfileApi.Spec>}
 */
proto.professionalProfileApi.CouncilProf.prototype.getSpecialityList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Spec>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Spec, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Spec>} value
 * @return {!proto.professionalProfileApi.CouncilProf} returns this
*/
proto.professionalProfileApi.CouncilProf.prototype.setSpecialityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Spec=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Spec}
 */
proto.professionalProfileApi.CouncilProf.prototype.addSpeciality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Spec, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.CouncilProf} returns this
 */
proto.professionalProfileApi.CouncilProf.prototype.clearSpecialityList = function() {
  return this.setSpecialityList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.Spec.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Spec.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Spec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Spec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Spec.toObject = function(includeInstance, msg) {
  var f, obj = {
    specialtyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actuationList: jspb.Message.toObjectList(msg.getActuationList(),
    proto.professionalProfileApi.ActionAct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Spec}
 */
proto.professionalProfileApi.Spec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Spec;
  return proto.professionalProfileApi.Spec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Spec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Spec}
 */
proto.professionalProfileApi.Spec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialtyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.ActionAct;
      reader.readMessage(value,proto.professionalProfileApi.ActionAct.deserializeBinaryFromReader);
      msg.addActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Spec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Spec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Spec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Spec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpecialtyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.ActionAct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string specialtyId = 1;
 * @return {string}
 */
proto.professionalProfileApi.Spec.prototype.getSpecialtyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Spec} returns this
 */
proto.professionalProfileApi.Spec.prototype.setSpecialtyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.Spec.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Spec} returns this
 */
proto.professionalProfileApi.Spec.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ActionAct actuation = 3;
 * @return {!Array<!proto.professionalProfileApi.ActionAct>}
 */
proto.professionalProfileApi.Spec.prototype.getActuationList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ActionAct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ActionAct, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ActionAct>} value
 * @return {!proto.professionalProfileApi.Spec} returns this
*/
proto.professionalProfileApi.Spec.prototype.setActuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.ActionAct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ActionAct}
 */
proto.professionalProfileApi.Spec.prototype.addActuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.ActionAct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.Spec} returns this
 */
proto.professionalProfileApi.Spec.prototype.clearActuationList = function() {
  return this.setActuationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ActionAct.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ActionAct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ActionAct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionAct.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ActionAct}
 */
proto.professionalProfileApi.ActionAct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ActionAct;
  return proto.professionalProfileApi.ActionAct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ActionAct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ActionAct}
 */
proto.professionalProfileApi.ActionAct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ActionAct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ActionAct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ActionAct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionAct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string actionActuationId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ActionAct.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionAct} returns this
 */
proto.professionalProfileApi.ActionAct.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.ActionAct.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionAct} returns this
 */
proto.professionalProfileApi.ActionAct.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SpecialitiesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SpecialitiesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profcouncilid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    desccouncil: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specialityid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    descriptionspeciality: jspb.Message.getFieldWithDefault(msg, 6, ""),
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    descriptionactionactuation: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SpecialitiesList}
 */
proto.professionalProfileApi.SpecialitiesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SpecialitiesList;
  return proto.professionalProfileApi.SpecialitiesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SpecialitiesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SpecialitiesList}
 */
proto.professionalProfileApi.SpecialitiesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfcouncilid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesccouncil(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialityid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionspeciality(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionactionactuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SpecialitiesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SpecialitiesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfcouncilid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDesccouncil();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecialityid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescriptionspeciality();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescriptionactionactuation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profCouncilId = 2;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getProfcouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setProfcouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string descCouncil = 4;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getDesccouncil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setDesccouncil = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string specialityId = 5;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getSpecialityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setSpecialityid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string descriptionSpeciality = 6;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getDescriptionspeciality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setDescriptionspeciality = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string actionActuationId = 7;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string descriptionActionActuation = 8;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesList.prototype.getDescriptionactionactuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesList} returns this
 */
proto.professionalProfileApi.SpecialitiesList.prototype.setDescriptionactionactuation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.UpdateServiceRequest.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tuss: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tussterm: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sus: jspb.Message.getFieldWithDefault(msg, 5, ""),
    susprocedure: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pb_return: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    timeReturn: jspb.Message.getFieldWithDefault(msg, 9, 0),
    serviceHasSpecialitiesList: jspb.Message.toObjectList(msg.getServiceHasSpecialitiesList(),
    proto.professionalProfileApi.UpdateServiceHasSpecialities.toObject, includeInstance),
    professionalServiceTypesList: jspb.Message.toObjectList(msg.getProfessionalServiceTypesList(),
    proto.professionalProfileApi.UpdateProfessionalServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateServiceRequest}
 */
proto.professionalProfileApi.UpdateServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateServiceRequest;
  return proto.professionalProfileApi.UpdateServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateServiceRequest}
 */
proto.professionalProfileApi.UpdateServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuss(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTussterm(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSusprocedure(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturn(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeReturn(value);
      break;
    case 10:
      var value = new proto.professionalProfileApi.UpdateServiceHasSpecialities;
      reader.readMessage(value,proto.professionalProfileApi.UpdateServiceHasSpecialities.deserializeBinaryFromReader);
      msg.addServiceHasSpecialities(value);
      break;
    case 11:
      var value = new proto.professionalProfileApi.UpdateProfessionalServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.UpdateProfessionalServiceTypes.deserializeBinaryFromReader);
      msg.addProfessionalServiceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTuss();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTussterm();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSusprocedure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReturn();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTimeReturn();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getServiceHasSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.professionalProfileApi.UpdateServiceHasSpecialities.serializeBinaryToWriter
    );
  }
  f = message.getProfessionalServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.professionalProfileApi.UpdateProfessionalServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tuss = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tussTerm = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getTussterm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setTussterm = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sus = 5;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getSus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setSus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string susProcedure = 6;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getSusprocedure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setSusprocedure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool return = 8;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getReturn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setReturn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 time_return = 9;
 * @return {number}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getTimeReturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.setTimeReturn = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated UpdateServiceHasSpecialities service_has_specialities = 10;
 * @return {!Array<!proto.professionalProfileApi.UpdateServiceHasSpecialities>}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getServiceHasSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.UpdateServiceHasSpecialities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.UpdateServiceHasSpecialities, 10));
};


/**
 * @param {!Array<!proto.professionalProfileApi.UpdateServiceHasSpecialities>} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
*/
proto.professionalProfileApi.UpdateServiceRequest.prototype.setServiceHasSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.professionalProfileApi.UpdateServiceHasSpecialities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.addServiceHasSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.professionalProfileApi.UpdateServiceHasSpecialities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.clearServiceHasSpecialitiesList = function() {
  return this.setServiceHasSpecialitiesList([]);
};


/**
 * repeated UpdateProfessionalServiceTypes professional_service_types = 11;
 * @return {!Array<!proto.professionalProfileApi.UpdateProfessionalServiceTypes>}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.getProfessionalServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.UpdateProfessionalServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.UpdateProfessionalServiceTypes, 11));
};


/**
 * @param {!Array<!proto.professionalProfileApi.UpdateProfessionalServiceTypes>} value
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
*/
proto.professionalProfileApi.UpdateServiceRequest.prototype.setProfessionalServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.professionalProfileApi.UpdateProfessionalServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes}
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.addProfessionalServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.professionalProfileApi.UpdateProfessionalServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.UpdateServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceRequest.prototype.clearProfessionalServiceTypesList = function() {
  return this.setProfessionalServiceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateServiceResponse}
 */
proto.professionalProfileApi.UpdateServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateServiceResponse;
  return proto.professionalProfileApi.UpdateServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateServiceResponse}
 */
proto.professionalProfileApi.UpdateServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateServiceResponse} returns this
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceResponse} returns this
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceResponse} returns this
 */
proto.professionalProfileApi.UpdateServiceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateServiceHasSpecialities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateServiceHasSpecialities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.toObject = function(includeInstance, msg) {
  var f, obj = {
    councilid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    specialityid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionactuationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateServiceHasSpecialities;
  return proto.professionalProfileApi.UpdateServiceHasSpecialities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateServiceHasSpecialities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncilid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialityid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionactuationid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateServiceHasSpecialities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateServiceHasSpecialities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouncilid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpecialityid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionactuationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string councilId = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.getCouncilid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.setCouncilid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string specialityId = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.getSpecialityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.setSpecialityid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string actionActuationId = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.getActionactuationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.setActionactuationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceHasSpecialities} returns this
 */
proto.professionalProfileApi.UpdateServiceHasSpecialities.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateProfessionalServiceTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicemode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 5, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateProfessionalServiceTypes;
  return proto.professionalProfileApi.UpdateProfessionalServiceTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateProfessionalServiceTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string serviceMode = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 estimated_time = 3;
 * @return {number}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double value = 4;
 * @return {number}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 max_displacement = 5;
 * @return {number}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double valueKm = 6;
 * @return {number}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string id = 7;
 * @return {string}
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateProfessionalServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateProfessionalServiceTypes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateStatusServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateStatusServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateStatusServiceRequest}
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateStatusServiceRequest;
  return proto.professionalProfileApi.UpdateStatusServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateStatusServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateStatusServiceRequest}
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateStatusServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateStatusServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusServiceRequest} returns this
 */
proto.professionalProfileApi.UpdateStatusServiceRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateStatusServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateStatusServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateStatusServiceResponse}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateStatusServiceResponse;
  return proto.professionalProfileApi.UpdateStatusServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateStatusServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateStatusServiceResponse}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateStatusServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateStatusServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateStatusServiceResponse} returns this
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusServiceResponse} returns this
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusServiceResponse} returns this
 */
proto.professionalProfileApi.UpdateStatusServiceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyUfActiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyUfActiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyUfActiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyUfActiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyUfActiveRequest}
 */
proto.professionalProfileApi.VerifyUfActiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyUfActiveRequest;
  return proto.professionalProfileApi.VerifyUfActiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyUfActiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyUfActiveRequest}
 */
proto.professionalProfileApi.VerifyUfActiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyUfActiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyUfActiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyUfActiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyUfActiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.VerifyUfActiveRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyUfActiveRequest} returns this
 */
proto.professionalProfileApi.VerifyUfActiveRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyUfActiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyUfActiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyUfActiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyUfActiveResponse}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyUfActiveResponse;
  return proto.professionalProfileApi.VerifyUfActiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyUfActiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyUfActiveResponse}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyUfActiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyUfActiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyUfActiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.VerifyUfActiveResponse} returns this
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyUfActiveResponse} returns this
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyUfActiveResponse} returns this
 */
proto.professionalProfileApi.VerifyUfActiveResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListLastsServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListLastsServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListLastsServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListLastsServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListLastsServicesRequest}
 */
proto.professionalProfileApi.ListLastsServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListLastsServicesRequest;
  return proto.professionalProfileApi.ListLastsServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListLastsServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListLastsServicesRequest}
 */
proto.professionalProfileApi.ListLastsServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListLastsServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListLastsServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListLastsServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListLastsServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListLastsServicesRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListLastsServicesRequest} returns this
 */
proto.professionalProfileApi.ListLastsServicesRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListLastsServicesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListLastsServicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListLastsServicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListLastsServicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicenamesList: jspb.Message.toObjectList(msg.getServicenamesList(),
    proto.professionalProfileApi.ServiceName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse}
 */
proto.professionalProfileApi.ListLastsServicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListLastsServicesResponse;
  return proto.professionalProfileApi.ListLastsServicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListLastsServicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse}
 */
proto.professionalProfileApi.ListLastsServicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceName;
      reader.readMessage(value,proto.professionalProfileApi.ServiceName.deserializeBinaryFromReader);
      msg.addServicenames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListLastsServicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListLastsServicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListLastsServicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicenamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceName.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse} returns this
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse} returns this
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse} returns this
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceName serviceNames = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceName>}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.getServicenamesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceName, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceName>} value
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse} returns this
*/
proto.professionalProfileApi.ListLastsServicesResponse.prototype.setServicenamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceName}
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.addServicenames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListLastsServicesResponse} returns this
 */
proto.professionalProfileApi.ListLastsServicesResponse.prototype.clearServicenamesList = function() {
  return this.setServicenamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceName.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceName.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceName}
 */
proto.professionalProfileApi.ServiceName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceName;
  return proto.professionalProfileApi.ServiceName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceName}
 */
proto.professionalProfileApi.ServiceName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceName.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceName} returns this
 */
proto.professionalProfileApi.ServiceName.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceName} returns this
 */
proto.professionalProfileApi.ServiceName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchActiveServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchActiveServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchActiveServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchActiveServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchActiveServiceRequest}
 */
proto.professionalProfileApi.SearchActiveServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchActiveServiceRequest;
  return proto.professionalProfileApi.SearchActiveServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchActiveServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchActiveServiceRequest}
 */
proto.professionalProfileApi.SearchActiveServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchActiveServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchActiveServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchActiveServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchActiveServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.professionalProfileApi.SearchActiveServiceRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchActiveServiceRequest} returns this
 */
proto.professionalProfileApi.SearchActiveServiceRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressPlaceId = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchActiveServiceRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchActiveServiceRequest} returns this
 */
proto.professionalProfileApi.SearchActiveServiceRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchActiveServiceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchActiveServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchActiveServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchActiveServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceactivenamesList: jspb.Message.toObjectList(msg.getServiceactivenamesList(),
    proto.professionalProfileApi.ServiceName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchActiveServiceResponse;
  return proto.professionalProfileApi.SearchActiveServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchActiveServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceName;
      reader.readMessage(value,proto.professionalProfileApi.ServiceName.deserializeBinaryFromReader);
      msg.addServiceactivenames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchActiveServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchActiveServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchActiveServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceactivenamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceName.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse} returns this
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse} returns this
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse} returns this
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceName serviceActiveNames = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceName>}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.getServiceactivenamesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceName, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceName>} value
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse} returns this
*/
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.setServiceactivenamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceName}
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.addServiceactivenames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchActiveServiceResponse} returns this
 */
proto.professionalProfileApi.SearchActiveServiceResponse.prototype.clearServiceactivenamesList = function() {
  return this.setServiceactivenamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionalServiceTypesPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicemode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 5, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    valuepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionalServiceTypesPlace;
  return proto.professionalProfileApi.ProfessionalServiceTypesPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuepercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionalServiceTypesPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getValuepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string serviceMode = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 estimated_time = 2;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 max_displacement = 5;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double valueKm = 6;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double valuePercentage = 7;
 * @return {number}
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.getValuepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypesPlace.prototype.setValuepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.AddServiceTypeRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddServiceTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddServiceTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceTypePlacesList: jspb.Message.toObjectList(msg.getServiceTypePlacesList(),
    proto.professionalProfileApi.ProfessionalServiceTypesPlace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddServiceTypeRequest}
 */
proto.professionalProfileApi.AddServiceTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddServiceTypeRequest;
  return proto.professionalProfileApi.AddServiceTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddServiceTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddServiceTypeRequest}
 */
proto.professionalProfileApi.AddServiceTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.ProfessionalServiceTypesPlace;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalServiceTypesPlace.deserializeBinaryFromReader);
      msg.addServiceTypePlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddServiceTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddServiceTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceTypePlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.ProfessionalServiceTypesPlace.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceTypeRequest} returns this
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressPlaceId = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceTypeRequest} returns this
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ProfessionalServiceTypesPlace service_type_places = 3;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalServiceTypesPlace>}
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.getServiceTypePlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalServiceTypesPlace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalServiceTypesPlace, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalServiceTypesPlace>} value
 * @return {!proto.professionalProfileApi.AddServiceTypeRequest} returns this
*/
proto.professionalProfileApi.AddServiceTypeRequest.prototype.setServiceTypePlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypesPlace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypesPlace}
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.addServiceTypePlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.ProfessionalServiceTypesPlace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.AddServiceTypeRequest} returns this
 */
proto.professionalProfileApi.AddServiceTypeRequest.prototype.clearServiceTypePlacesList = function() {
  return this.setServiceTypePlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddServiceTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddServiceTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddServiceTypeResponse}
 */
proto.professionalProfileApi.AddServiceTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddServiceTypeResponse;
  return proto.professionalProfileApi.AddServiceTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddServiceTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddServiceTypeResponse}
 */
proto.professionalProfileApi.AddServiceTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddServiceTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddServiceTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddServiceTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddServiceTypeResponse} returns this
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceTypeResponse} returns this
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddServiceTypeResponse} returns this
 */
proto.professionalProfileApi.AddServiceTypeResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPlacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPlacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPlacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPlacesRequest}
 */
proto.professionalProfileApi.ListPlacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPlacesRequest;
  return proto.professionalProfileApi.ListPlacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPlacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPlacesRequest}
 */
proto.professionalProfileApi.ListPlacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPlacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPlacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPlacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListPlacesRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlacesRequest} returns this
 */
proto.professionalProfileApi.ListPlacesRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListPlacesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPlacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPlacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.Place.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPlacesResponse}
 */
proto.professionalProfileApi.ListPlacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPlacesResponse;
  return proto.professionalProfileApi.ListPlacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPlacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPlacesResponse}
 */
proto.professionalProfileApi.ListPlacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Place;
      reader.readMessage(value,proto.professionalProfileApi.Place.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPlacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPlacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Place.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListPlacesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlacesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlacesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Place places = 4;
 * @return {!Array<!proto.professionalProfileApi.Place>}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Place>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Place, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Place>} value
 * @return {!proto.professionalProfileApi.ListPlacesResponse} returns this
*/
proto.professionalProfileApi.ListPlacesResponse.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Place=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Place}
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Place, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListPlacesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesResponse.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Place.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Place.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Place} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Place.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationState: jspb.Message.getFieldWithDefault(msg, 13, ""),
    country: jspb.Message.getFieldWithDefault(msg, 14, ""),
    latitude: jspb.Message.getFieldWithDefault(msg, 15, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 16, ""),
    status: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Place}
 */
proto.professionalProfileApi.Place.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Place;
  return proto.professionalProfileApi.Place.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Place} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Place}
 */
proto.professionalProfileApi.Place.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationState(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Place.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Place.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Place} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Place.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationState();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string picture_url = 4;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string neighborhood = 8;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_state = 13;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getLocationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setLocationState = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string country = 14;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string latitude = 15;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string longitude = 16;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string status = 17;
 * @return {string}
 */
proto.professionalProfileApi.Place.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Place} returns this
 */
proto.professionalProfileApi.Place.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdatePlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdatePlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    number: jspb.Message.getFieldWithDefault(msg, 7, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 8, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 9, ""),
    city: jspb.Message.getFieldWithDefault(msg, 10, ""),
    state: jspb.Message.getFieldWithDefault(msg, 11, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest}
 */
proto.professionalProfileApi.UpdatePlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdatePlaceRequest;
  return proto.professionalProfileApi.UpdatePlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdatePlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest}
 */
proto.professionalProfileApi.UpdatePlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdatePlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdatePlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string number = 7;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string complement = 8;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string neighborhood = 9;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string city = 10;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string state = 11;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string postalCode = 12;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double latitude = 13;
 * @return {number}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double longitude = 14;
 * @return {number}
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdatePlaceRequest} returns this
 */
proto.professionalProfileApi.UpdatePlaceRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdatePlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdatePlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdatePlaceResponse}
 */
proto.professionalProfileApi.UpdatePlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdatePlaceResponse;
  return proto.professionalProfileApi.UpdatePlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdatePlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdatePlaceResponse}
 */
proto.professionalProfileApi.UpdatePlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdatePlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdatePlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdatePlaceResponse} returns this
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceResponse} returns this
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePlaceResponse} returns this
 */
proto.professionalProfileApi.UpdatePlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateStatusPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceRequest}
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateStatusPlaceRequest;
  return proto.professionalProfileApi.UpdateStatusPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceRequest}
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateStatusPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceRequest} returns this
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceRequest} returns this
 */
proto.professionalProfileApi.UpdateStatusPlaceRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateStatusPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceResponse}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateStatusPlaceResponse;
  return proto.professionalProfileApi.UpdateStatusPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceResponse}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateStatusPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateStatusPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceResponse} returns this
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceResponse} returns this
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateStatusPlaceResponse} returns this
 */
proto.professionalProfileApi.UpdateStatusPlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeletePlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeletePlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeletePlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeletePlaceRequest}
 */
proto.professionalProfileApi.DeletePlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeletePlaceRequest;
  return proto.professionalProfileApi.DeletePlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeletePlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeletePlaceRequest}
 */
proto.professionalProfileApi.DeletePlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeletePlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeletePlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeletePlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeletePlaceRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePlaceRequest} returns this
 */
proto.professionalProfileApi.DeletePlaceRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeletePlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeletePlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeletePlaceResponse}
 */
proto.professionalProfileApi.DeletePlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeletePlaceResponse;
  return proto.professionalProfileApi.DeletePlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeletePlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeletePlaceResponse}
 */
proto.professionalProfileApi.DeletePlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeletePlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeletePlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeletePlaceResponse} returns this
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePlaceResponse} returns this
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePlaceResponse} returns this
 */
proto.professionalProfileApi.DeletePlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServicesPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServicesPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServicesPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServicesPlaceRequest}
 */
proto.professionalProfileApi.ListServicesPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServicesPlaceRequest;
  return proto.professionalProfileApi.ListServicesPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServicesPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServicesPlaceRequest}
 */
proto.professionalProfileApi.ListServicesPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServicesPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServicesPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServicesPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServicesPlaceRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServicesPlaceRequest} returns this
 */
proto.professionalProfileApi.ListServicesPlaceRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServicesPlaceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServicesPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServicesPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServiceListPlace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServicesPlaceResponse;
  return proto.professionalProfileApi.ListServicesPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServicesPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceListPlace;
      reader.readMessage(value,proto.professionalProfileApi.ServiceListPlace.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServicesPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServicesPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceListPlace.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceListPlace services = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceListPlace>}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceListPlace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceListPlace, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceListPlace>} value
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse} returns this
*/
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceListPlace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceListPlace}
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceListPlace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServicesPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesPlaceResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServiceListPlace.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceListPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceListPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceListPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.Specialities.toObject, includeInstance),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ServiceTypes.toObject, includeInstance),
    statusserviceoffered: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    tuss: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tussterm: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sus: jspb.Message.getFieldWithDefault(msg, 10, ""),
    susprocedure: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pb_return: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    timereturn: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceListPlace}
 */
proto.professionalProfileApi.ServiceListPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceListPlace;
  return proto.professionalProfileApi.ServiceListPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceListPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceListPlace}
 */
proto.professionalProfileApi.ServiceListPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.Specialities;
      reader.readMessage(value,proto.professionalProfileApi.Specialities.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.ServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatusserviceoffered(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTuss(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTussterm(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSusprocedure(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturn(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimereturn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceListPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceListPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceListPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.Specialities.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter
    );
  }
  f = message.getStatusserviceoffered();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTuss();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTussterm();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSusprocedure();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReturn();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getTimereturn();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Specialities specialities = 5;
 * @return {!Array<!proto.professionalProfileApi.Specialities>}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Specialities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Specialities, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Specialities>} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
*/
proto.professionalProfileApi.ServiceListPlace.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.Specialities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Specialities}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.Specialities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * repeated ServiceTypes serviceTypes = 6;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypes>}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypes, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypes>} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
*/
proto.professionalProfileApi.ServiceListPlace.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.ServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};


/**
 * optional bool statusServiceOffered = 7;
 * @return {boolean}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getStatusserviceoffered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setStatusserviceoffered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string tuss = 8;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tussTerm = 9;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getTussterm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setTussterm = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sus = 10;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getSus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setSus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string susProcedure = 11;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getSusprocedure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setSusprocedure = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool return = 12;
 * @return {boolean}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getReturn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setReturn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 timeReturn = 13;
 * @return {number}
 */
proto.professionalProfileApi.ServiceListPlace.prototype.getTimereturn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceListPlace} returns this
 */
proto.professionalProfileApi.ServiceListPlace.prototype.setTimereturn = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchListPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchListPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchListPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    word: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchListPlaceRequest}
 */
proto.professionalProfileApi.SearchListPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchListPlaceRequest;
  return proto.professionalProfileApi.SearchListPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchListPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchListPlaceRequest}
 */
proto.professionalProfileApi.SearchListPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchListPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchListPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchListPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPlaceRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPlaceRequest} returns this
 */
proto.professionalProfileApi.SearchListPlaceRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string word = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPlaceRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPlaceRequest} returns this
 */
proto.professionalProfileApi.SearchListPlaceRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchListPlaceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchListPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchListPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.Place.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse}
 */
proto.professionalProfileApi.SearchListPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchListPlaceResponse;
  return proto.professionalProfileApi.SearchListPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchListPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse}
 */
proto.professionalProfileApi.SearchListPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Place;
      reader.readMessage(value,proto.professionalProfileApi.Place.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchListPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchListPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Place.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse} returns this
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse} returns this
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse} returns this
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Place places = 4;
 * @return {!Array<!proto.professionalProfileApi.Place>}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Place>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Place, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Place>} value
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse} returns this
*/
proto.professionalProfileApi.SearchListPlaceResponse.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Place=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Place}
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Place, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchListPlaceResponse} returns this
 */
proto.professionalProfileApi.SearchListPlaceResponse.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceIdPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceRequest}
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceIdPlaceRequest;
  return proto.professionalProfileApi.ListServiceIdPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceRequest}
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceIdPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceRequest} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serviceId = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceRequest} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServiceIdPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServiceListIdPlace.toObject, includeInstance),
    servicetypesofferedList: jspb.Message.toObjectList(msg.getServicetypesofferedList(),
    proto.professionalProfileApi.ServiceTypesOffered.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServiceIdPlaceResponse;
  return proto.professionalProfileApi.ListServiceIdPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceListIdPlace;
      reader.readMessage(value,proto.professionalProfileApi.ServiceListIdPlace.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ServiceTypesOffered;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypesOffered.deserializeBinaryFromReader);
      msg.addServicetypesoffered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServiceIdPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServiceIdPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceListIdPlace.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesofferedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ServiceTypesOffered.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceListIdPlace services = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceListIdPlace>}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceListIdPlace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceListIdPlace, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceListIdPlace>} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
*/
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceListIdPlace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceListIdPlace}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceListIdPlace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated ServiceTypesOffered serviceTypesOffered = 5;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypesOffered>}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.getServicetypesofferedList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypesOffered>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypesOffered, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypesOffered>} value
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
*/
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.setServicetypesofferedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypesOffered=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypesOffered}
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.addServicetypesoffered = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ServiceTypesOffered, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServiceIdPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServiceIdPlaceResponse.prototype.clearServicetypesofferedList = function() {
  return this.setServicetypesofferedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServiceListIdPlace.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceListIdPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceListIdPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceListIdPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    councilList: jspb.Message.toObjectList(msg.getCouncilList(),
    proto.professionalProfileApi.CouncilProf.toObject, includeInstance),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceListIdPlace}
 */
proto.professionalProfileApi.ServiceListIdPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceListIdPlace;
  return proto.professionalProfileApi.ServiceListIdPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceListIdPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceListIdPlace}
 */
proto.professionalProfileApi.ServiceListIdPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.CouncilProf;
      reader.readMessage(value,proto.professionalProfileApi.CouncilProf.deserializeBinaryFromReader);
      msg.addCouncil(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.ServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceListIdPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceListIdPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceListIdPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCouncilList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.CouncilProf.serializeBinaryToWriter
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CouncilProf council = 5;
 * @return {!Array<!proto.professionalProfileApi.CouncilProf>}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.getCouncilList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.CouncilProf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.CouncilProf, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.CouncilProf>} value
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
*/
proto.professionalProfileApi.ServiceListIdPlace.prototype.setCouncilList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.CouncilProf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.CouncilProf}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.addCouncil = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.CouncilProf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.clearCouncilList = function() {
  return this.setCouncilList([]);
};


/**
 * repeated ServiceTypes serviceTypes = 6;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypes>}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypes, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypes>} value
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
*/
proto.professionalProfileApi.ServiceListIdPlace.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.ServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceListIdPlace} returns this
 */
proto.professionalProfileApi.ServiceListIdPlace.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceTypesOffered.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceTypesOffered} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypesOffered.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servicemode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 6, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    valuepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceTypesOffered}
 */
proto.professionalProfileApi.ServiceTypesOffered.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceTypesOffered;
  return proto.professionalProfileApi.ServiceTypesOffered.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceTypesOffered} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceTypesOffered}
 */
proto.professionalProfileApi.ServiceTypesOffered.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuepercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceTypesOffered.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceTypesOffered} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypesOffered.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getValuepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serviceMode = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 estimated_time = 3;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double value = 5;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 max_displacement = 6;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double valueKm = 7;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double valuePercentage = 8;
 * @return {number}
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.getValuepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceTypesOffered} returns this
 */
proto.professionalProfileApi.ServiceTypesOffered.prototype.setValuepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateServiceTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateServiceTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.UpdateServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateServiceTypeRequest}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateServiceTypeRequest;
  return proto.professionalProfileApi.UpdateServiceTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateServiceTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateServiceTypeRequest}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.UpdateServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.UpdateServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateServiceTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateServiceTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.UpdateServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypeRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressPlaceId = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypeRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UpdateServiceTypes serviceTypes = 3;
 * @return {!Array<!proto.professionalProfileApi.UpdateServiceTypes>}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.UpdateServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.UpdateServiceTypes, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.UpdateServiceTypes>} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypeRequest} returns this
*/
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.UpdateServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.UpdateServiceTypes}
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.UpdateServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.UpdateServiceTypeRequest} returns this
 */
proto.professionalProfileApi.UpdateServiceTypeRequest.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateServiceTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateServiceTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateServiceTypeResponse}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateServiceTypeResponse;
  return proto.professionalProfileApi.UpdateServiceTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateServiceTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateServiceTypeResponse}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateServiceTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateServiceTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypeResponse} returns this
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypeResponse} returns this
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypeResponse} returns this
 */
proto.professionalProfileApi.UpdateServiceTypeResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateServiceTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateServiceTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicemode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maxDisplacement: jspb.Message.getFieldWithDefault(msg, 5, 0),
    valuekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    valuepercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateServiceTypes}
 */
proto.professionalProfileApi.UpdateServiceTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateServiceTypes;
  return proto.professionalProfileApi.UpdateServiceTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateServiceTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateServiceTypes}
 */
proto.professionalProfileApi.UpdateServiceTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicemode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEstimatedTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDisplacement(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuekm(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuepercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateServiceTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateServiceTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateServiceTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicemode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMaxDisplacement();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getValuekm();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getValuepercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string serviceMode = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getServicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setServicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 estimated_time = 2;
 * @return {number}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getEstimatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double value = 4;
 * @return {number}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 max_displacement = 5;
 * @return {number}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getMaxDisplacement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setMaxDisplacement = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double valueKm = 6;
 * @return {number}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getValuekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setValuekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double valuePercentage = 7;
 * @return {number}
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.getValuepercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.UpdateServiceTypes} returns this
 */
proto.professionalProfileApi.UpdateServiceTypes.prototype.setValuepercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ChangeStatusToInactiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveRequest}
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ChangeStatusToInactiveRequest;
  return proto.professionalProfileApi.ChangeStatusToInactiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveRequest}
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ChangeStatusToInactiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} returns this
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressPlaceId = 2;
 * @return {string}
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveRequest} returns this
 */
proto.professionalProfileApi.ChangeStatusToInactiveRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ChangeStatusToInactiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveResponse}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ChangeStatusToInactiveResponse;
  return proto.professionalProfileApi.ChangeStatusToInactiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveResponse}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ChangeStatusToInactiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ChangeStatusToInactiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveResponse} returns this
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveResponse} returns this
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ChangeStatusToInactiveResponse} returns this
 */
proto.professionalProfileApi.ChangeStatusToInactiveResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyChangeStatusToActiveRequest;
  return proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string serviceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.prototype.getServiceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} returns this
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.prototype.setServiceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressPlaceId = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveRequest} returns this
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyChangeStatusToActiveResponse;
  return proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse} returns this
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse} returns this
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyChangeStatusToActiveResponse} returns this
 */
proto.professionalProfileApi.VerifyChangeStatusToActiveResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListColorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListColorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListColorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListColorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListColorsResponse}
 */
proto.professionalProfileApi.ListColorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListColorsResponse;
  return proto.professionalProfileApi.ListColorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListColorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListColorsResponse}
 */
proto.professionalProfileApi.ListColorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListColorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListColorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListColorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListColorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListColorsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListColorsResponse} returns this
 */
proto.professionalProfileApi.ListColorsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListColorsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListColorsResponse} returns this
 */
proto.professionalProfileApi.ListColorsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListColorsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListColorsResponse} returns this
 */
proto.professionalProfileApi.ListColorsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    period: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddBlockedAvailableScheduleRequest;
  return proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPeriod();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string initial_date = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string final_date = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool period = 6;
 * @return {boolean}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.getPeriod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddBlockedAvailableScheduleResponse;
  return proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.AddBlockedAvailableScheduleResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListPlacesActivesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPlacesActivesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPlacesActivesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlacesActivesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.PlaceName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPlacesActivesResponse;
  return proto.professionalProfileApi.ListPlacesActivesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPlacesActivesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.PlaceName;
      reader.readMessage(value,proto.professionalProfileApi.PlaceName.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPlacesActivesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPlacesActivesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPlacesActivesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.PlaceName.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PlaceName places = 4;
 * @return {!Array<!proto.professionalProfileApi.PlaceName>}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.PlaceName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.PlaceName, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.PlaceName>} value
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse} returns this
*/
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.PlaceName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.PlaceName}
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.PlaceName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListPlacesActivesResponse} returns this
 */
proto.professionalProfileApi.ListPlacesActivesResponse.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.PlaceName.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.PlaceName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.PlaceName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.PlaceName.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.PlaceName}
 */
proto.professionalProfileApi.PlaceName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.PlaceName;
  return proto.professionalProfileApi.PlaceName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.PlaceName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.PlaceName}
 */
proto.professionalProfileApi.PlaceName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.PlaceName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.PlaceName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.PlaceName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.PlaceName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.PlaceName.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.PlaceName} returns this
 */
proto.professionalProfileApi.PlaceName.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.PlaceName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.PlaceName} returns this
 */
proto.professionalProfileApi.PlaceName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServicesToPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServicesToPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressplaceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServicesToPlaceRequest}
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServicesToPlaceRequest;
  return proto.professionalProfileApi.ListServicesToPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServicesToPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServicesToPlaceRequest}
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressplaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServicesToPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServicesToPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string addressPlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.prototype.getAddressplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServicesToPlaceRequest} returns this
 */
proto.professionalProfileApi.ListServicesToPlaceRequest.prototype.setAddressplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListServicesToPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListServicesToPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServicePlaces.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListServicesToPlaceResponse;
  return proto.professionalProfileApi.ListServicesToPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListServicesToPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServicePlaces;
      reader.readMessage(value,proto.professionalProfileApi.ServicePlaces.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListServicesToPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListServicesToPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServicePlaces.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServicePlaces services = 4;
 * @return {!Array<!proto.professionalProfileApi.ServicePlaces>}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServicePlaces>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServicePlaces, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServicePlaces>} value
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse} returns this
*/
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServicePlaces=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServicePlaces}
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServicePlaces, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListServicesToPlaceResponse} returns this
 */
proto.professionalProfileApi.ListServicesToPlaceResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServicePlaces.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServicePlaces.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServicePlaces.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServicePlaces} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServicePlaces.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceplaceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    servicetypesList: jspb.Message.toObjectList(msg.getServicetypesList(),
    proto.professionalProfileApi.ServiceTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServicePlaces}
 */
proto.professionalProfileApi.ServicePlaces.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServicePlaces;
  return proto.professionalProfileApi.ServicePlaces.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServicePlaces} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServicePlaces}
 */
proto.professionalProfileApi.ServicePlaces.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceplaceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ServiceTypes;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypes.deserializeBinaryFromReader);
      msg.addServicetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServicePlaces.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServicePlaces.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServicePlaces} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServicePlaces.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceplaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServicetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ServiceTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string servicePlaceId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServicePlaces.prototype.getServiceplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServicePlaces} returns this
 */
proto.professionalProfileApi.ServicePlaces.prototype.setServiceplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServicePlaces.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServicePlaces} returns this
 */
proto.professionalProfileApi.ServicePlaces.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServicePlaces.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServicePlaces} returns this
 */
proto.professionalProfileApi.ServicePlaces.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServicePlaces.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServicePlaces} returns this
 */
proto.professionalProfileApi.ServicePlaces.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ServiceTypes serviceTypes = 5;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypes>}
 */
proto.professionalProfileApi.ServicePlaces.prototype.getServicetypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypes, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypes>} value
 * @return {!proto.professionalProfileApi.ServicePlaces} returns this
*/
proto.professionalProfileApi.ServicePlaces.prototype.setServicetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypes}
 */
proto.professionalProfileApi.ServicePlaces.prototype.addServicetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ServiceTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServicePlaces} returns this
 */
proto.professionalProfileApi.ServicePlaces.prototype.clearServicetypesList = function() {
  return this.setServicetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyMinTimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyMinTimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyMinTimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressPlaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialHour: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finalHour: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyMinTimeRequest}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyMinTimeRequest;
  return proto.professionalProfileApi.VerifyMinTimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyMinTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyMinTimeRequest}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressPlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialHour(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalHour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyMinTimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyMinTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyMinTimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialHour();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinalHour();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string address_place_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.getAddressPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyMinTimeRequest} returns this
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.setAddressPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_hour = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.getInitialHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyMinTimeRequest} returns this
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.setInitialHour = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string final_hour = 3;
 * @return {string}
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.getFinalHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyMinTimeRequest} returns this
 */
proto.professionalProfileApi.VerifyMinTimeRequest.prototype.setFinalHour = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyMinTimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyMinTimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyMinTimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyMinTimeResponse}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyMinTimeResponse;
  return proto.professionalProfileApi.VerifyMinTimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyMinTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyMinTimeResponse}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyMinTimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyMinTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyMinTimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.VerifyMinTimeResponse} returns this
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyMinTimeResponse} returns this
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyMinTimeResponse} returns this
 */
proto.professionalProfileApi.VerifyMinTimeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.CreateAvailableScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    availableScheduleHasDayList: jspb.Message.toObjectList(msg.getAvailableScheduleHasDayList(),
    proto.professionalProfileApi.AvailableScheduleHasDayProto.toObject, includeInstance),
    professionalServiceTypeHasAvailableScheduleList: jspb.Message.toObjectList(msg.getProfessionalServiceTypeHasAvailableScheduleList(),
    proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.toObject, includeInstance),
    holidaysList: jspb.Message.toObjectList(msg.getHolidaysList(),
    proto.professionalProfileApi.Holiday.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.CreateAvailableScheduleRequest;
  return proto.professionalProfileApi.CreateAvailableScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.AvailableScheduleHasDayProto;
      reader.readMessage(value,proto.professionalProfileApi.AvailableScheduleHasDayProto.deserializeBinaryFromReader);
      msg.addAvailableScheduleHasDay(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.deserializeBinaryFromReader);
      msg.addProfessionalServiceTypeHasAvailableSchedule(value);
      break;
    case 6:
      var value = new proto.professionalProfileApi.Holiday;
      reader.readMessage(value,proto.professionalProfileApi.Holiday.deserializeBinaryFromReader);
      msg.addHolidays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.CreateAvailableScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvailableScheduleHasDayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.AvailableScheduleHasDayProto.serializeBinaryToWriter
    );
  }
  f = message.getProfessionalServiceTypeHasAvailableScheduleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.serializeBinaryToWriter
    );
  }
  f = message.getHolidaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.professionalProfileApi.Holiday.serializeBinaryToWriter
    );
  }
};


/**
 * optional string initial_date = 1;
 * @return {string}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string final_date = 2;
 * @return {string}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string frequency = 3;
 * @return {string}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AvailableScheduleHasDayProto available_schedule_has_day = 4;
 * @return {!Array<!proto.professionalProfileApi.AvailableScheduleHasDayProto>}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.getAvailableScheduleHasDayList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.AvailableScheduleHasDayProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.AvailableScheduleHasDayProto, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.AvailableScheduleHasDayProto>} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
*/
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.setAvailableScheduleHasDayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.AvailableScheduleHasDayProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.AvailableScheduleHasDayProto}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.addAvailableScheduleHasDay = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.AvailableScheduleHasDayProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.clearAvailableScheduleHasDayList = function() {
  return this.setAvailableScheduleHasDayList([]);
};


/**
 * repeated ProfessionalServiceTypeHasAvailableScheduleProto professional_service_type_has_available_schedule = 5;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto>}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.getProfessionalServiceTypeHasAvailableScheduleList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto>} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
*/
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.setProfessionalServiceTypeHasAvailableScheduleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.addProfessionalServiceTypeHasAvailableSchedule = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.clearProfessionalServiceTypeHasAvailableScheduleList = function() {
  return this.setProfessionalServiceTypeHasAvailableScheduleList([]);
};


/**
 * repeated Holiday holidays = 6;
 * @return {!Array<!proto.professionalProfileApi.Holiday>}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.getHolidaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Holiday>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Holiday, 6));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Holiday>} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
*/
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.setHolidaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.professionalProfileApi.Holiday=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Holiday}
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.addHolidays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.professionalProfileApi.Holiday, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleRequest.prototype.clearHolidaysList = function() {
  return this.setHolidaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Holiday.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Holiday.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Holiday} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Holiday.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Holiday}
 */
proto.professionalProfileApi.Holiday.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Holiday;
  return proto.professionalProfileApi.Holiday.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Holiday} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Holiday}
 */
proto.professionalProfileApi.Holiday.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Holiday.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Holiday.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Holiday} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Holiday.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.professionalProfileApi.Holiday.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holiday} returns this
 */
proto.professionalProfileApi.Holiday.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.Holiday.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holiday} returns this
 */
proto.professionalProfileApi.Holiday.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Holidays.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Holidays.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Holidays} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Holidays.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Holidays}
 */
proto.professionalProfileApi.Holidays.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Holidays;
  return proto.professionalProfileApi.Holidays.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Holidays} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Holidays}
 */
proto.professionalProfileApi.Holidays.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Holidays.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Holidays.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Holidays} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Holidays.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.professionalProfileApi.Holidays.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holidays} returns this
 */
proto.professionalProfileApi.Holidays.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.Holidays.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holidays} returns this
 */
proto.professionalProfileApi.Holidays.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.Holidays.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holidays} returns this
 */
proto.professionalProfileApi.Holidays.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.Holidays.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holidays} returns this
 */
proto.professionalProfileApi.Holidays.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.professionalProfileApi.Holidays.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Holidays} returns this
 */
proto.professionalProfileApi.Holidays.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AvailableScheduleHasDayProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AvailableScheduleHasDayProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AvailableScheduleHasDayProto}
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AvailableScheduleHasDayProto;
  return proto.professionalProfileApi.AvailableScheduleHasDayProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AvailableScheduleHasDayProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AvailableScheduleHasDayProto}
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AvailableScheduleHasDayProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AvailableScheduleHasDayProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 day_id = 1;
 * @return {number}
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.prototype.getDayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHasDayProto} returns this
 */
proto.professionalProfileApi.AvailableScheduleHasDayProto.prototype.setDayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalServiceTypeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto}
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto;
  return proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto}
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalServiceTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string professional_service_type_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.prototype.getProfessionalServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto} returns this
 */
proto.professionalProfileApi.ProfessionalServiceTypeHasAvailableScheduleProto.prototype.setProfessionalServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.CreateAvailableScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.professionalProfileApi.ListDaysConflicts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.CreateAvailableScheduleResponse;
  return proto.professionalProfileApi.CreateAvailableScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ListDaysConflicts;
      reader.readMessage(value,proto.professionalProfileApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.CreateAvailableScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.CreateAvailableScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.professionalProfileApi.ListDaysConflicts>}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListDaysConflicts>} value
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse} returns this
*/
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListDaysConflicts}
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.CreateAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.CreateAvailableScheduleResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddDayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddDayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddDayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddDayRequest}
 */
proto.professionalProfileApi.AddDayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddDayRequest;
  return proto.professionalProfileApi.AddDayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddDayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddDayRequest}
 */
proto.professionalProfileApi.AddDayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddDayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddDayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddDayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddDayRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddDayRequest} returns this
 */
proto.professionalProfileApi.AddDayRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddDayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddDayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddDayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddDayResponse}
 */
proto.professionalProfileApi.AddDayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddDayResponse;
  return proto.professionalProfileApi.AddDayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddDayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddDayResponse}
 */
proto.professionalProfileApi.AddDayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddDayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddDayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddDayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddDayResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddDayResponse} returns this
 */
proto.professionalProfileApi.AddDayResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddDayResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddDayResponse} returns this
 */
proto.professionalProfileApi.AddDayResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddDayResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddDayResponse} returns this
 */
proto.professionalProfileApi.AddDayResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListOfDays.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListOfDays.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListOfDays} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListOfDays.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockedavailableschedulesid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    availablescheduleid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    daysavailableid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, ""),
    day: jspb.Message.getFieldWithDefault(msg, 5, ""),
    initialhour: jspb.Message.getFieldWithDefault(msg, 6, ""),
    finalhour: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dayofyear: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListOfDays}
 */
proto.professionalProfileApi.ListOfDays.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListOfDays;
  return proto.professionalProfileApi.ListOfDays.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListOfDays} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListOfDays}
 */
proto.professionalProfileApi.ListOfDays.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedavailableschedulesid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailablescheduleid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDaysavailableid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialhour(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalhour(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofyear(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofweek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListOfDays.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListOfDays.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListOfDays} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListOfDays.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockedavailableschedulesid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvailablescheduleid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDaysavailableid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInitialhour();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFinalhour();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDayofyear();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDayofweek();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string blockedAvailableSchedulesId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getBlockedavailableschedulesid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setBlockedavailableschedulesid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string availableScheduleId = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getAvailablescheduleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setAvailablescheduleid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string daysAvailableId = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getDaysavailableid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setDaysavailableid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string day = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string initialHour = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getInitialhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setInitialhour = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string finalHour = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDays.prototype.getFinalhour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setFinalhour = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 dayOfYear = 8;
 * @return {number}
 */
proto.professionalProfileApi.ListOfDays.prototype.getDayofyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setDayofyear = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 dayOfWeek = 9;
 * @return {number}
 */
proto.professionalProfileApi.ListOfDays.prototype.getDayofweek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListOfDays} returns this
 */
proto.professionalProfileApi.ListOfDays.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListDaysResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListDaysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListDaysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListDaysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.professionalProfileApi.Days.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListDaysResponse}
 */
proto.professionalProfileApi.ListDaysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListDaysResponse;
  return proto.professionalProfileApi.ListDaysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListDaysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListDaysResponse}
 */
proto.professionalProfileApi.ListDaysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Days;
      reader.readMessage(value,proto.professionalProfileApi.Days.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListDaysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListDaysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListDaysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Days.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListDaysResponse} returns this
 */
proto.professionalProfileApi.ListDaysResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysResponse} returns this
 */
proto.professionalProfileApi.ListDaysResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDaysResponse} returns this
 */
proto.professionalProfileApi.ListDaysResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Days days = 4;
 * @return {!Array<!proto.professionalProfileApi.Days>}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Days>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Days, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Days>} value
 * @return {!proto.professionalProfileApi.ListDaysResponse} returns this
*/
proto.professionalProfileApi.ListDaysResponse.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Days=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Days}
 */
proto.professionalProfileApi.ListDaysResponse.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Days, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListDaysResponse} returns this
 */
proto.professionalProfileApi.ListDaysResponse.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Days.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Days.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Days} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Days.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Days}
 */
proto.professionalProfileApi.Days.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Days;
  return proto.professionalProfileApi.Days.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Days} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Days}
 */
proto.professionalProfileApi.Days.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofweek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Days.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Days.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Days} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Days.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDayofweek();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Days.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Days} returns this
 */
proto.professionalProfileApi.Days.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.Days.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Days} returns this
 */
proto.professionalProfileApi.Days.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 dayOfWeek = 3;
 * @return {number}
 */
proto.professionalProfileApi.Days.prototype.getDayofweek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.Days} returns this
 */
proto.professionalProfileApi.Days.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleRequest}
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleRequest;
  return proto.professionalProfileApi.ListAvailableScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleRequest}
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string initial_date = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string final_date = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleRequest} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleRequest.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listOfDaysList: jspb.Message.toObjectList(msg.getListOfDaysList(),
    proto.professionalProfileApi.ListOfDayAvailable.toObject, includeInstance),
    listOfBlockedDaysList: jspb.Message.toObjectList(msg.getListOfBlockedDaysList(),
    proto.professionalProfileApi.ListOfBlockedDay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleResponse;
  return proto.professionalProfileApi.ListAvailableScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ListOfDayAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ListOfDayAvailable.deserializeBinaryFromReader);
      msg.addListOfDays(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ListOfBlockedDay;
      reader.readMessage(value,proto.professionalProfileApi.ListOfBlockedDay.deserializeBinaryFromReader);
      msg.addListOfBlockedDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListOfDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ListOfDayAvailable.serializeBinaryToWriter
    );
  }
  f = message.getListOfBlockedDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ListOfBlockedDay.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListOfDayAvailable list_of_days = 4;
 * @return {!Array<!proto.professionalProfileApi.ListOfDayAvailable>}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.getListOfDaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListOfDayAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListOfDayAvailable, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListOfDayAvailable>} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
*/
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.setListOfDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ListOfDayAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListOfDayAvailable}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.addListOfDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ListOfDayAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.clearListOfDaysList = function() {
  return this.setListOfDaysList([]);
};


/**
 * repeated ListOfBlockedDay list_of_blocked_days = 5;
 * @return {!Array<!proto.professionalProfileApi.ListOfBlockedDay>}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.getListOfBlockedDaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListOfBlockedDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListOfBlockedDay, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListOfBlockedDay>} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
*/
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.setListOfBlockedDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ListOfBlockedDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListOfBlockedDay}
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.addListOfBlockedDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ListOfBlockedDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleResponse.prototype.clearListOfBlockedDaysList = function() {
  return this.setListOfBlockedDaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListOfBlockedDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListOfBlockedDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListOfBlockedDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockedAvailableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    day: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 4, 0),
    initialHour: jspb.Message.getFieldWithDefault(msg, 5, ""),
    finalHour: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    holiday: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListOfBlockedDay}
 */
proto.professionalProfileApi.ListOfBlockedDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListOfBlockedDay;
  return proto.professionalProfileApi.ListOfBlockedDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListOfBlockedDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListOfBlockedDay}
 */
proto.professionalProfileApi.ListOfBlockedDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfWeek(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialHour(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalHour(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoliday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListOfBlockedDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListOfBlockedDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListOfBlockedDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockedAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getInitialHour();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFinalHour();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHoliday();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string blocked_available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getBlockedAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setBlockedAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string day = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 day_of_year = 3;
 * @return {number}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 day_of_week = 4;
 * @return {number}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getDayOfWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string initial_hour = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getInitialHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setInitialHour = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string final_hour = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getFinalHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setFinalHour = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool holiday = 8;
 * @return {boolean}
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.getHoliday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListOfBlockedDay} returns this
 */
proto.professionalProfileApi.ListOfBlockedDay.prototype.setHoliday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListOfDayAvailable.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListOfDayAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListOfDayAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListOfDayAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    daysAvailableId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    day: jspb.Message.getFieldWithDefault(msg, 3, ""),
    initialHour: jspb.Message.getFieldWithDefault(msg, 4, ""),
    finalHour: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 7, 0),
    placeId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    color: jspb.Message.getFieldWithDefault(msg, 9, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServicePlacesListAvailable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListOfDayAvailable}
 */
proto.professionalProfileApi.ListOfDayAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListOfDayAvailable;
  return proto.professionalProfileApi.ListOfDayAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListOfDayAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListOfDayAvailable}
 */
proto.professionalProfileApi.ListOfDayAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDaysAvailableId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialHour(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalHour(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfWeek(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 13:
      var value = new proto.professionalProfileApi.ServicePlacesListAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ServicePlacesListAvailable.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListOfDayAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListOfDayAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListOfDayAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDaysAvailableId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInitialHour();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFinalHour();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.professionalProfileApi.ServicePlacesListAvailable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string days_available_id = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getDaysAvailableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setDaysAvailableId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string day = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string initial_hour = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getInitialHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setInitialHour = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string final_hour = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getFinalHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setFinalHour = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 day_of_year = 6;
 * @return {number}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 day_of_week = 7;
 * @return {number}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getDayOfWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string place_id = 8;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string color = 9;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string place_name = 10;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string occupation = 12;
 * @return {string}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated ServicePlacesListAvailable services = 13;
 * @return {!Array<!proto.professionalProfileApi.ServicePlacesListAvailable>}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServicePlacesListAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServicePlacesListAvailable, 13));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServicePlacesListAvailable>} value
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
*/
proto.professionalProfileApi.ListOfDayAvailable.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.professionalProfileApi.ServicePlacesListAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable}
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.professionalProfileApi.ServicePlacesListAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListOfDayAvailable} returns this
 */
proto.professionalProfileApi.ListOfDayAvailable.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServicePlacesListAvailable.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServicePlacesListAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServicePlacesListAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServicePlacesListAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceTypesList: jspb.Message.toObjectList(msg.getServiceTypesList(),
    proto.professionalProfileApi.ServiceTypesListAvailable.toObject, includeInstance),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.SpecialitiesListAvailable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServicePlacesListAvailable;
  return proto.professionalProfileApi.ServicePlacesListAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServicePlacesListAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.ServiceTypesListAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypesListAvailable.deserializeBinaryFromReader);
      msg.addServiceTypes(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.SpecialitiesListAvailable;
      reader.readMessage(value,proto.professionalProfileApi.SpecialitiesListAvailable.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServicePlacesListAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServicePlacesListAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServicePlacesListAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.ServiceTypesListAvailable.serializeBinaryToWriter
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.SpecialitiesListAvailable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable} returns this
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable} returns this
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ServiceTypesListAvailable service_types = 3;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypesListAvailable>}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.getServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypesListAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypesListAvailable, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypesListAvailable>} value
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable} returns this
*/
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.setServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypesListAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypesListAvailable}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.addServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.ServiceTypesListAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable} returns this
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.clearServiceTypesList = function() {
  return this.setServiceTypesList([]);
};


/**
 * repeated SpecialitiesListAvailable specialities = 4;
 * @return {!Array<!proto.professionalProfileApi.SpecialitiesListAvailable>}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SpecialitiesListAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SpecialitiesListAvailable, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SpecialitiesListAvailable>} value
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable} returns this
*/
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.SpecialitiesListAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable}
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.SpecialitiesListAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServicePlacesListAvailable} returns this
 */
proto.professionalProfileApi.ServicePlacesListAvailable.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceTypesListAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceTypesListAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceTypesListAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypesListAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceTypesListAvailable}
 */
proto.professionalProfileApi.ServiceTypesListAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceTypesListAvailable;
  return proto.professionalProfileApi.ServiceTypesListAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceTypesListAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceTypesListAvailable}
 */
proto.professionalProfileApi.ServiceTypesListAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceTypesListAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceTypesListAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceTypesListAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypesListAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesListAvailable.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesListAvailable} returns this
 */
proto.professionalProfileApi.ServiceTypesListAvailable.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_mode = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesListAvailable.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesListAvailable} returns this
 */
proto.professionalProfileApi.ServiceTypesListAvailable.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SpecialitiesListAvailable.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SpecialitiesListAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SpecialitiesListAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesListAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    specialityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actuationList: jspb.Message.toObjectList(msg.getActuationList(),
    proto.professionalProfileApi.ActionActuationListAvailable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SpecialitiesListAvailable;
  return proto.professionalProfileApi.SpecialitiesListAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SpecialitiesListAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.professionalProfileApi.ActionActuationListAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ActionActuationListAvailable.deserializeBinaryFromReader);
      msg.addActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SpecialitiesListAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SpecialitiesListAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesListAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpecialityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.professionalProfileApi.ActionActuationListAvailable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string speciality_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.getSpecialityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable} returns this
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.setSpecialityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable} returns this
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ActionActuationListAvailable actuation = 3;
 * @return {!Array<!proto.professionalProfileApi.ActionActuationListAvailable>}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.getActuationList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ActionActuationListAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ActionActuationListAvailable, 3));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ActionActuationListAvailable>} value
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable} returns this
*/
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.setActuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.professionalProfileApi.ActionActuationListAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ActionActuationListAvailable}
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.addActuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.professionalProfileApi.ActionActuationListAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SpecialitiesListAvailable} returns this
 */
proto.professionalProfileApi.SpecialitiesListAvailable.prototype.clearActuationList = function() {
  return this.setActuationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ActionActuationListAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ActionActuationListAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ActionActuationListAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionActuationListAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionActuationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ActionActuationListAvailable}
 */
proto.professionalProfileApi.ActionActuationListAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ActionActuationListAvailable;
  return proto.professionalProfileApi.ActionActuationListAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ActionActuationListAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ActionActuationListAvailable}
 */
proto.professionalProfileApi.ActionActuationListAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionActuationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ActionActuationListAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ActionActuationListAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ActionActuationListAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ActionActuationListAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionActuationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string action_actuation_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ActionActuationListAvailable.prototype.getActionActuationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionActuationListAvailable} returns this
 */
proto.professionalProfileApi.ActionActuationListAvailable.prototype.setActionActuationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.ActionActuationListAvailable.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ActionActuationListAvailable} returns this
 */
proto.professionalProfileApi.ActionActuationListAvailable.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blockedsList: jspb.Message.toObjectList(msg.getBlockedsList(),
    proto.professionalProfileApi.Blockeds.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListBlockedAvailableScheduleResponse;
  return proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Blockeds;
      reader.readMessage(value,proto.professionalProfileApi.Blockeds.deserializeBinaryFromReader);
      msg.addBlockeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlockedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Blockeds.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Blockeds blockeds = 4;
 * @return {!Array<!proto.professionalProfileApi.Blockeds>}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.getBlockedsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Blockeds>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Blockeds, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Blockeds>} value
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} returns this
*/
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.setBlockedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Blockeds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Blockeds}
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.addBlockeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Blockeds, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListBlockedAvailableScheduleResponse} returns this
 */
proto.professionalProfileApi.ListBlockedAvailableScheduleResponse.prototype.clearBlockedsList = function() {
  return this.setBlockedsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Blockeds.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Blockeds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Blockeds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Blockeds.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockedAvailableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    period: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Blockeds}
 */
proto.professionalProfileApi.Blockeds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Blockeds;
  return proto.professionalProfileApi.Blockeds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Blockeds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Blockeds}
 */
proto.professionalProfileApi.Blockeds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Blockeds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Blockeds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Blockeds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Blockeds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockedAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeriod();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string blocked_available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Blockeds.prototype.getBlockedAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Blockeds} returns this
 */
proto.professionalProfileApi.Blockeds.prototype.setBlockedAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.professionalProfileApi.Blockeds.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Blockeds} returns this
 */
proto.professionalProfileApi.Blockeds.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string final_date = 3;
 * @return {string}
 */
proto.professionalProfileApi.Blockeds.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Blockeds} returns this
 */
proto.professionalProfileApi.Blockeds.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.professionalProfileApi.Blockeds.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Blockeds} returns this
 */
proto.professionalProfileApi.Blockeds.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool period = 5;
 * @return {boolean}
 */
proto.professionalProfileApi.Blockeds.prototype.getPeriod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.Blockeds} returns this
 */
proto.professionalProfileApi.Blockeds.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    word: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleHistoryRequest;
  return proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string word = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryRequest} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    availableSchedulesList: jspb.Message.toObjectList(msg.getAvailableSchedulesList(),
    proto.professionalProfileApi.AvailableScheduleHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleHistoryResponse;
  return proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.AvailableScheduleHistory;
      reader.readMessage(value,proto.professionalProfileApi.AvailableScheduleHistory.deserializeBinaryFromReader);
      msg.addAvailableSchedules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvailableSchedulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.AvailableScheduleHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AvailableScheduleHistory available_schedules = 4;
 * @return {!Array<!proto.professionalProfileApi.AvailableScheduleHistory>}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.getAvailableSchedulesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.AvailableScheduleHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.AvailableScheduleHistory, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.AvailableScheduleHistory>} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} returns this
*/
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.setAvailableSchedulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.AvailableScheduleHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory}
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.addAvailableSchedules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.AvailableScheduleHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleHistoryResponse.prototype.clearAvailableSchedulesList = function() {
  return this.setAvailableSchedulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.AvailableScheduleHistory.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AvailableScheduleHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AvailableScheduleHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableScheduleHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    placeColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.professionalProfileApi.AvailableDaysId.toObject, includeInstance),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ServiceHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory}
 */
proto.professionalProfileApi.AvailableScheduleHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AvailableScheduleHistory;
  return proto.professionalProfileApi.AvailableScheduleHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AvailableScheduleHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory}
 */
proto.professionalProfileApi.AvailableScheduleHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceColor(value);
      break;
    case 8:
      var value = new proto.professionalProfileApi.AvailableDaysId;
      reader.readMessage(value,proto.professionalProfileApi.AvailableDaysId.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    case 9:
      var value = new proto.professionalProfileApi.ServiceHistory;
      reader.readMessage(value,proto.professionalProfileApi.ServiceHistory.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AvailableScheduleHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AvailableScheduleHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableScheduleHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlaceColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.professionalProfileApi.AvailableDaysId.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.professionalProfileApi.ServiceHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_date = 2;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string final_date = 3;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string place_id = 5;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string place_name = 6;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string place_color = 7;
 * @return {string}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getPlaceColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setPlaceColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated AvailableDaysId days = 8;
 * @return {!Array<!proto.professionalProfileApi.AvailableDaysId>}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.AvailableDaysId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.AvailableDaysId, 8));
};


/**
 * @param {!Array<!proto.professionalProfileApi.AvailableDaysId>} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
*/
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.professionalProfileApi.AvailableDaysId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.AvailableDaysId}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.professionalProfileApi.AvailableDaysId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};


/**
 * repeated ServiceHistory services = 9;
 * @return {!Array<!proto.professionalProfileApi.ServiceHistory>}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceHistory, 9));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceHistory>} value
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
*/
proto.professionalProfileApi.AvailableScheduleHistory.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceHistory}
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.professionalProfileApi.ServiceHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.AvailableScheduleHistory} returns this
 */
proto.professionalProfileApi.AvailableScheduleHistory.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ServiceHistory.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.SpecialityHistory.toObject, includeInstance),
    serviceTypesList: jspb.Message.toObjectList(msg.getServiceTypesList(),
    proto.professionalProfileApi.ServiceTypesHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceHistory}
 */
proto.professionalProfileApi.ServiceHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceHistory;
  return proto.professionalProfileApi.ServiceHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceHistory}
 */
proto.professionalProfileApi.ServiceHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.SpecialityHistory;
      reader.readMessage(value,proto.professionalProfileApi.SpecialityHistory.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.ServiceTypesHistory;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypesHistory.deserializeBinaryFromReader);
      msg.addServiceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.SpecialityHistory.serializeBinaryToWriter
    );
  }
  f = message.getServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.ServiceTypesHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceHistory.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
 */
proto.professionalProfileApi.ServiceHistory.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceHistory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
 */
proto.professionalProfileApi.ServiceHistory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceHistory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
 */
proto.professionalProfileApi.ServiceHistory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SpecialityHistory specialities = 4;
 * @return {!Array<!proto.professionalProfileApi.SpecialityHistory>}
 */
proto.professionalProfileApi.ServiceHistory.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SpecialityHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SpecialityHistory, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SpecialityHistory>} value
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
*/
proto.professionalProfileApi.ServiceHistory.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.SpecialityHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SpecialityHistory}
 */
proto.professionalProfileApi.ServiceHistory.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.SpecialityHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
 */
proto.professionalProfileApi.ServiceHistory.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * repeated ServiceTypesHistory service_types = 5;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypesHistory>}
 */
proto.professionalProfileApi.ServiceHistory.prototype.getServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypesHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypesHistory, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypesHistory>} value
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
*/
proto.professionalProfileApi.ServiceHistory.prototype.setServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypesHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypesHistory}
 */
proto.professionalProfileApi.ServiceHistory.prototype.addServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.ServiceTypesHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ServiceHistory} returns this
 */
proto.professionalProfileApi.ServiceHistory.prototype.clearServiceTypesList = function() {
  return this.setServiceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceTypesHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceTypesHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceTypesHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypesHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceTypesHistory}
 */
proto.professionalProfileApi.ServiceTypesHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceTypesHistory;
  return proto.professionalProfileApi.ServiceTypesHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceTypesHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceTypesHistory}
 */
proto.professionalProfileApi.ServiceTypesHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceTypesHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceTypesHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceTypesHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypesHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string service_type_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesHistory.prototype.getServiceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesHistory} returns this
 */
proto.professionalProfileApi.ServiceTypesHistory.prototype.setServiceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_mode = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypesHistory.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypesHistory} returns this
 */
proto.professionalProfileApi.ServiceTypesHistory.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SpecialityHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SpecialityHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SpecialityHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialityHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    specialityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    speciality: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SpecialityHistory}
 */
proto.professionalProfileApi.SpecialityHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SpecialityHistory;
  return proto.professionalProfileApi.SpecialityHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SpecialityHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SpecialityHistory}
 */
proto.professionalProfileApi.SpecialityHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeciality(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SpecialityHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SpecialityHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SpecialityHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialityHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpecialityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpeciality();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string speciality_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.SpecialityHistory.prototype.getSpecialityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialityHistory} returns this
 */
proto.professionalProfileApi.SpecialityHistory.prototype.setSpecialityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string occupation = 2;
 * @return {string}
 */
proto.professionalProfileApi.SpecialityHistory.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialityHistory} returns this
 */
proto.professionalProfileApi.SpecialityHistory.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string speciality = 3;
 * @return {string}
 */
proto.professionalProfileApi.SpecialityHistory.prototype.getSpeciality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialityHistory} returns this
 */
proto.professionalProfileApi.SpecialityHistory.prototype.setSpeciality = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actuation = 4;
 * @return {string}
 */
proto.professionalProfileApi.SpecialityHistory.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialityHistory} returns this
 */
proto.professionalProfileApi.SpecialityHistory.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest;
  return proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest} returns this
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryRequest.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse;
  return proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse} returns this
 */
proto.professionalProfileApi.DeleteAvailableScheduleHistoryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdRequest}
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleIdRequest;
  return proto.professionalProfileApi.ListAvailableScheduleIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdRequest}
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdRequest} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleIdRequest.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    availableSchedule: (f = msg.getAvailableSchedule()) && proto.professionalProfileApi.ListAvailableScheduleId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleIdResponse;
  return proto.professionalProfileApi.ListAvailableScheduleIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ListAvailableScheduleId;
      reader.readMessage(value,proto.professionalProfileApi.ListAvailableScheduleId.deserializeBinaryFromReader);
      msg.setAvailableSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvailableSchedule();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.professionalProfileApi.ListAvailableScheduleId.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListAvailableScheduleId available_schedule = 4;
 * @return {?proto.professionalProfileApi.ListAvailableScheduleId}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.getAvailableSchedule = function() {
  return /** @type{?proto.professionalProfileApi.ListAvailableScheduleId} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.ListAvailableScheduleId, 4));
};


/**
 * @param {?proto.professionalProfileApi.ListAvailableScheduleId|undefined} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} returns this
*/
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.setAvailableSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleIdResponse} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.clearAvailableSchedule = function() {
  return this.setAvailableSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.ListAvailableScheduleIdResponse.prototype.hasAvailableSchedule = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListAvailableScheduleId.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableScheduleId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableScheduleId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleId.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableScheduleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.professionalProfileApi.ListAvailableInfo.toObject, includeInstance),
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.professionalProfileApi.AvailableDaysId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId}
 */
proto.professionalProfileApi.ListAvailableScheduleId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableScheduleId;
  return proto.professionalProfileApi.ListAvailableScheduleId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId}
 */
proto.professionalProfileApi.ListAvailableScheduleId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableScheduleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 7:
      var value = new proto.professionalProfileApi.ListAvailableInfo;
      reader.readMessage(value,proto.professionalProfileApi.ListAvailableInfo.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 8:
      var value = new proto.professionalProfileApi.AvailableDaysId;
      reader.readMessage(value,proto.professionalProfileApi.AvailableDaysId.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableScheduleId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableScheduleId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableScheduleId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.professionalProfileApi.ListAvailableInfo.serializeBinaryToWriter
    );
  }
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.professionalProfileApi.AvailableDaysId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string available_schedule_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getAvailableScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setAvailableScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string place_id = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string initial_date = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string final_date = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string frequency = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ListAvailableInfo services = 7;
 * @return {!Array<!proto.professionalProfileApi.ListAvailableInfo>}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListAvailableInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListAvailableInfo, 7));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListAvailableInfo>} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
*/
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.professionalProfileApi.ListAvailableInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListAvailableInfo}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.professionalProfileApi.ListAvailableInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated AvailableDaysId days = 8;
 * @return {!Array<!proto.professionalProfileApi.AvailableDaysId>}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.AvailableDaysId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.AvailableDaysId, 8));
};


/**
 * @param {!Array<!proto.professionalProfileApi.AvailableDaysId>} value
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
*/
proto.professionalProfileApi.ListAvailableScheduleId.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.professionalProfileApi.AvailableDaysId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.AvailableDaysId}
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.professionalProfileApi.AvailableDaysId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListAvailableScheduleId} returns this
 */
proto.professionalProfileApi.ListAvailableScheduleId.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListAvailableInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAvailableInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAvailableInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceTypesList: jspb.Message.toObjectList(msg.getServiceTypesList(),
    proto.professionalProfileApi.ServiceTypesListAvailable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAvailableInfo}
 */
proto.professionalProfileApi.ListAvailableInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAvailableInfo;
  return proto.professionalProfileApi.ListAvailableInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAvailableInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAvailableInfo}
 */
proto.professionalProfileApi.ListAvailableInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ServiceTypesListAvailable;
      reader.readMessage(value,proto.professionalProfileApi.ServiceTypesListAvailable.deserializeBinaryFromReader);
      msg.addServiceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAvailableInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAvailableInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAvailableInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ServiceTypesListAvailable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableInfo} returns this
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableInfo} returns this
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAvailableInfo} returns this
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ServiceTypesListAvailable service_types = 4;
 * @return {!Array<!proto.professionalProfileApi.ServiceTypesListAvailable>}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.getServiceTypesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceTypesListAvailable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceTypesListAvailable, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceTypesListAvailable>} value
 * @return {!proto.professionalProfileApi.ListAvailableInfo} returns this
*/
proto.professionalProfileApi.ListAvailableInfo.prototype.setServiceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceTypesListAvailable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceTypesListAvailable}
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.addServiceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ServiceTypesListAvailable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListAvailableInfo} returns this
 */
proto.professionalProfileApi.ListAvailableInfo.prototype.clearServiceTypesList = function() {
  return this.setServiceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceMode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceTypeInfo}
 */
proto.professionalProfileApi.ServiceTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceTypeInfo;
  return proto.professionalProfileApi.ServiceTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceTypeInfo}
 */
proto.professionalProfileApi.ServiceTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceMode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypeInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypeInfo} returns this
 */
proto.professionalProfileApi.ServiceTypeInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_mode = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceTypeInfo.prototype.getServiceMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceTypeInfo} returns this
 */
proto.professionalProfileApi.ServiceTypeInfo.prototype.setServiceMode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AvailableDaysId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AvailableDaysId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableDaysId.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysAvailableId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    day: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialHour: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finalHour: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dayOfYear: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AvailableDaysId}
 */
proto.professionalProfileApi.AvailableDaysId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AvailableDaysId;
  return proto.professionalProfileApi.AvailableDaysId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AvailableDaysId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AvailableDaysId}
 */
proto.professionalProfileApi.AvailableDaysId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDaysAvailableId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialHour(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalHour(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfYear(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayOfWeek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AvailableDaysId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AvailableDaysId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableDaysId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysAvailableId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialHour();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinalHour();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDayOfYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string days_available_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.getDaysAvailableId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableDaysId} returns this
 */
proto.professionalProfileApi.AvailableDaysId.prototype.setDaysAvailableId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string day = 2;
 * @return {string}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableDaysId} returns this
 */
proto.professionalProfileApi.AvailableDaysId.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_hour = 3;
 * @return {string}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.getInitialHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableDaysId} returns this
 */
proto.professionalProfileApi.AvailableDaysId.prototype.setInitialHour = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string final_hour = 4;
 * @return {string}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.getFinalHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableDaysId} returns this
 */
proto.professionalProfileApi.AvailableDaysId.prototype.setFinalHour = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 day_of_year = 5;
 * @return {number}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.getDayOfYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.AvailableDaysId} returns this
 */
proto.professionalProfileApi.AvailableDaysId.prototype.setDayOfYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 day_of_week = 6;
 * @return {number}
 */
proto.professionalProfileApi.AvailableDaysId.prototype.getDayOfWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.AvailableDaysId} returns this
 */
proto.professionalProfileApi.AvailableDaysId.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.VerifyHolidayRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyHolidayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyHolidayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyHolidayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    availableScheduleHasDayList: jspb.Message.toObjectList(msg.getAvailableScheduleHasDayList(),
    proto.professionalProfileApi.AvailableScheduleHasDayProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest}
 */
proto.professionalProfileApi.VerifyHolidayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyHolidayRequest;
  return proto.professionalProfileApi.VerifyHolidayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyHolidayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest}
 */
proto.professionalProfileApi.VerifyHolidayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.AvailableScheduleHasDayProto;
      reader.readMessage(value,proto.professionalProfileApi.AvailableScheduleHasDayProto.deserializeBinaryFromReader);
      msg.addAvailableScheduleHasDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyHolidayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyHolidayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyHolidayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvailableScheduleHasDayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.AvailableScheduleHasDayProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string initial_date = 1;
 * @return {string}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest} returns this
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string final_date = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest} returns this
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string frequency = 3;
 * @return {string}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest} returns this
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AvailableScheduleHasDayProto available_schedule_has_day = 4;
 * @return {!Array<!proto.professionalProfileApi.AvailableScheduleHasDayProto>}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.getAvailableScheduleHasDayList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.AvailableScheduleHasDayProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.AvailableScheduleHasDayProto, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.AvailableScheduleHasDayProto>} value
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest} returns this
*/
proto.professionalProfileApi.VerifyHolidayRequest.prototype.setAvailableScheduleHasDayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.AvailableScheduleHasDayProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.AvailableScheduleHasDayProto}
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.addAvailableScheduleHasDay = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.AvailableScheduleHasDayProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.VerifyHolidayRequest} returns this
 */
proto.professionalProfileApi.VerifyHolidayRequest.prototype.clearAvailableScheduleHasDayList = function() {
  return this.setAvailableScheduleHasDayList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.VerifyHolidayResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.VerifyHolidayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.VerifyHolidayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyHolidayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listConflictsList: jspb.Message.toObjectList(msg.getListConflictsList(),
    proto.professionalProfileApi.ListDaysConflicts.toObject, includeInstance),
    holidaysList: jspb.Message.toObjectList(msg.getHolidaysList(),
    proto.professionalProfileApi.Holidays.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse}
 */
proto.professionalProfileApi.VerifyHolidayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.VerifyHolidayResponse;
  return proto.professionalProfileApi.VerifyHolidayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.VerifyHolidayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse}
 */
proto.professionalProfileApi.VerifyHolidayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ListDaysConflicts;
      reader.readMessage(value,proto.professionalProfileApi.ListDaysConflicts.deserializeBinaryFromReader);
      msg.addListConflicts(value);
      break;
    case 5:
      var value = new proto.professionalProfileApi.Holidays;
      reader.readMessage(value,proto.professionalProfileApi.Holidays.deserializeBinaryFromReader);
      msg.addHolidays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.VerifyHolidayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.VerifyHolidayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.VerifyHolidayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ListDaysConflicts.serializeBinaryToWriter
    );
  }
  f = message.getHolidaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.professionalProfileApi.Holidays.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDaysConflicts list_conflicts = 4;
 * @return {!Array<!proto.professionalProfileApi.ListDaysConflicts>}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.getListConflictsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListDaysConflicts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListDaysConflicts, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListDaysConflicts>} value
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
*/
proto.professionalProfileApi.VerifyHolidayResponse.prototype.setListConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ListDaysConflicts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListDaysConflicts}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.addListConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ListDaysConflicts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.clearListConflictsList = function() {
  return this.setListConflictsList([]);
};


/**
 * repeated Holidays holidays = 5;
 * @return {!Array<!proto.professionalProfileApi.Holidays>}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.getHolidaysList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Holidays>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Holidays, 5));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Holidays>} value
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
*/
proto.professionalProfileApi.VerifyHolidayResponse.prototype.setHolidaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.professionalProfileApi.Holidays=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Holidays}
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.addHolidays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.professionalProfileApi.Holidays, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.VerifyHolidayResponse} returns this
 */
proto.professionalProfileApi.VerifyHolidayResponse.prototype.clearHolidaysList = function() {
  return this.setHolidaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AvailableIdProto.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AvailableIdProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AvailableIdProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableIdProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    availablescheduleid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AvailableIdProto}
 */
proto.professionalProfileApi.AvailableIdProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AvailableIdProto;
  return proto.professionalProfileApi.AvailableIdProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AvailableIdProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AvailableIdProto}
 */
proto.professionalProfileApi.AvailableIdProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailablescheduleid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AvailableIdProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AvailableIdProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AvailableIdProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AvailableIdProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailablescheduleid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string availableScheduleId = 1;
 * @return {string}
 */
proto.professionalProfileApi.AvailableIdProto.prototype.getAvailablescheduleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AvailableIdProto} returns this
 */
proto.professionalProfileApi.AvailableIdProto.prototype.setAvailablescheduleid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DisplayMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DisplayMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DisplayMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DisplayMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DisplayMessageResponse}
 */
proto.professionalProfileApi.DisplayMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DisplayMessageResponse;
  return proto.professionalProfileApi.DisplayMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DisplayMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DisplayMessageResponse}
 */
proto.professionalProfileApi.DisplayMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DisplayMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DisplayMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DisplayMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DisplayMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DisplayMessageResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DisplayMessageResponse} returns this
 */
proto.professionalProfileApi.DisplayMessageResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddAwardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddAwardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddAwardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uf: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    year: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddAwardRequest}
 */
proto.professionalProfileApi.AddAwardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddAwardRequest;
  return proto.professionalProfileApi.AddAwardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddAwardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddAwardRequest}
 */
proto.professionalProfileApi.AddAwardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUf(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddAwardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddAwardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddAwardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUf();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardRequest} returns this
 */
proto.professionalProfileApi.AddAwardRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardRequest} returns this
 */
proto.professionalProfileApi.AddAwardRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardRequest} returns this
 */
proto.professionalProfileApi.AddAwardRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uf = 4;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.getUf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardRequest} returns this
 */
proto.professionalProfileApi.AddAwardRequest.prototype.setUf = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardRequest} returns this
 */
proto.professionalProfileApi.AddAwardRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string year = 6;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardRequest} returns this
 */
proto.professionalProfileApi.AddAwardRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddAwardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddAwardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddAwardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddAwardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddAwardResponse}
 */
proto.professionalProfileApi.AddAwardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddAwardResponse;
  return proto.professionalProfileApi.AddAwardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddAwardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddAwardResponse}
 */
proto.professionalProfileApi.AddAwardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddAwardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddAwardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddAwardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddAwardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddAwardResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddAwardResponse} returns this
 */
proto.professionalProfileApi.AddAwardResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardResponse} returns this
 */
proto.professionalProfileApi.AddAwardResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddAwardResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddAwardResponse} returns this
 */
proto.professionalProfileApi.AddAwardResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateAwardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateAwardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateAwardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uf: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    year: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateAwardRequest}
 */
proto.professionalProfileApi.UpdateAwardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateAwardRequest;
  return proto.professionalProfileApi.UpdateAwardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateAwardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateAwardRequest}
 */
proto.professionalProfileApi.UpdateAwardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUf(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateAwardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateAwardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateAwardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUf();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string uf = 5;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getUf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setUf = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string year = 7;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardRequest} returns this
 */
proto.professionalProfileApi.UpdateAwardRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateAwardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateAwardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateAwardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateAwardResponse}
 */
proto.professionalProfileApi.UpdateAwardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateAwardResponse;
  return proto.professionalProfileApi.UpdateAwardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateAwardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateAwardResponse}
 */
proto.professionalProfileApi.UpdateAwardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateAwardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateAwardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateAwardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateAwardResponse} returns this
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardResponse} returns this
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateAwardResponse} returns this
 */
proto.professionalProfileApi.UpdateAwardResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteAwardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteAwardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteAwardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAwardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteAwardRequest}
 */
proto.professionalProfileApi.DeleteAwardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteAwardRequest;
  return proto.professionalProfileApi.DeleteAwardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteAwardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteAwardRequest}
 */
proto.professionalProfileApi.DeleteAwardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteAwardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteAwardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteAwardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAwardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteAwardRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteAwardRequest} returns this
 */
proto.professionalProfileApi.DeleteAwardRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteAwardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteAwardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAwardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteAwardResponse}
 */
proto.professionalProfileApi.DeleteAwardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteAwardResponse;
  return proto.professionalProfileApi.DeleteAwardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteAwardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteAwardResponse}
 */
proto.professionalProfileApi.DeleteAwardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteAwardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteAwardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteAwardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteAwardResponse} returns this
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteAwardResponse} returns this
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteAwardResponse} returns this
 */
proto.professionalProfileApi.DeleteAwardResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddIdiomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddIdiomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddIdiomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddIdiomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idiom: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddIdiomRequest}
 */
proto.professionalProfileApi.AddIdiomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddIdiomRequest;
  return proto.professionalProfileApi.AddIdiomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddIdiomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddIdiomRequest}
 */
proto.professionalProfileApi.AddIdiomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdiom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddIdiomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddIdiomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddIdiomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddIdiomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdiom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string idiom = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddIdiomRequest.prototype.getIdiom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddIdiomRequest} returns this
 */
proto.professionalProfileApi.AddIdiomRequest.prototype.setIdiom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddIdiomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddIdiomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddIdiomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddIdiomResponse}
 */
proto.professionalProfileApi.AddIdiomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddIdiomResponse;
  return proto.professionalProfileApi.AddIdiomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddIdiomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddIdiomResponse}
 */
proto.professionalProfileApi.AddIdiomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddIdiomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddIdiomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddIdiomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddIdiomResponse} returns this
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddIdiomResponse} returns this
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddIdiomResponse} returns this
 */
proto.professionalProfileApi.AddIdiomResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteIdiomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteIdiomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteIdiomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteIdiomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idiom: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteIdiomRequest}
 */
proto.professionalProfileApi.DeleteIdiomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteIdiomRequest;
  return proto.professionalProfileApi.DeleteIdiomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteIdiomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteIdiomRequest}
 */
proto.professionalProfileApi.DeleteIdiomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdiom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteIdiomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteIdiomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteIdiomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteIdiomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdiom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string idiom = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteIdiomRequest.prototype.getIdiom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteIdiomRequest} returns this
 */
proto.professionalProfileApi.DeleteIdiomRequest.prototype.setIdiom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteIdiomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteIdiomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteIdiomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteIdiomResponse}
 */
proto.professionalProfileApi.DeleteIdiomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteIdiomResponse;
  return proto.professionalProfileApi.DeleteIdiomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteIdiomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteIdiomResponse}
 */
proto.professionalProfileApi.DeleteIdiomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteIdiomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteIdiomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteIdiomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteIdiomResponse} returns this
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteIdiomResponse} returns this
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteIdiomResponse} returns this
 */
proto.professionalProfileApi.DeleteIdiomResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddExperienceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddExperienceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddExperienceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institution: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    current: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    startYear: jspb.Message.getFieldWithDefault(msg, 5, ""),
    endYear: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddExperienceRequest}
 */
proto.professionalProfileApi.AddExperienceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddExperienceRequest;
  return proto.professionalProfileApi.AddExperienceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddExperienceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddExperienceRequest}
 */
proto.professionalProfileApi.AddExperienceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitution(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddExperienceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddExperienceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddExperienceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitution();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStartYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEndYear();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string institution = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.getInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceRequest} returns this
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.setInstitution = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceRequest} returns this
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceRequest} returns this
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool current = 4;
 * @return {boolean}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddExperienceRequest} returns this
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string start_year = 5;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.getStartYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceRequest} returns this
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.setStartYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string end_year = 6;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.getEndYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceRequest} returns this
 */
proto.professionalProfileApi.AddExperienceRequest.prototype.setEndYear = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddExperienceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddExperienceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddExperienceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddExperienceResponse}
 */
proto.professionalProfileApi.AddExperienceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddExperienceResponse;
  return proto.professionalProfileApi.AddExperienceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddExperienceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddExperienceResponse}
 */
proto.professionalProfileApi.AddExperienceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddExperienceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddExperienceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddExperienceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddExperienceResponse} returns this
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceResponse} returns this
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddExperienceResponse} returns this
 */
proto.professionalProfileApi.AddExperienceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateExperienceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateExperienceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateExperienceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institution: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    current: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    startYear: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endYear: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest}
 */
proto.professionalProfileApi.UpdateExperienceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateExperienceRequest;
  return proto.professionalProfileApi.UpdateExperienceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateExperienceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest}
 */
proto.professionalProfileApi.UpdateExperienceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitution(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateExperienceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateExperienceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateExperienceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitution();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStartYear();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndYear();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setInstitution = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool current = 5;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string start_year = 6;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getStartYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setStartYear = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string end_year = 7;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.getEndYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceRequest} returns this
 */
proto.professionalProfileApi.UpdateExperienceRequest.prototype.setEndYear = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateExperienceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateExperienceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateExperienceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateExperienceResponse}
 */
proto.professionalProfileApi.UpdateExperienceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateExperienceResponse;
  return proto.professionalProfileApi.UpdateExperienceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateExperienceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateExperienceResponse}
 */
proto.professionalProfileApi.UpdateExperienceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateExperienceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateExperienceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateExperienceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateExperienceResponse} returns this
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceResponse} returns this
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateExperienceResponse} returns this
 */
proto.professionalProfileApi.UpdateExperienceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteExperienceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteExperienceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteExperienceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteExperienceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteExperienceRequest}
 */
proto.professionalProfileApi.DeleteExperienceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteExperienceRequest;
  return proto.professionalProfileApi.DeleteExperienceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteExperienceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteExperienceRequest}
 */
proto.professionalProfileApi.DeleteExperienceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteExperienceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteExperienceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteExperienceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteExperienceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteExperienceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteExperienceRequest} returns this
 */
proto.professionalProfileApi.DeleteExperienceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteExperienceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteExperienceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteExperienceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteExperienceResponse}
 */
proto.professionalProfileApi.DeleteExperienceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteExperienceResponse;
  return proto.professionalProfileApi.DeleteExperienceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteExperienceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteExperienceResponse}
 */
proto.professionalProfileApi.DeleteExperienceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteExperienceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteExperienceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteExperienceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteExperienceResponse} returns this
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteExperienceResponse} returns this
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteExperienceResponse} returns this
 */
proto.professionalProfileApi.DeleteExperienceResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddPublicationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddPublicationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPublicationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddPublicationRequest}
 */
proto.professionalProfileApi.AddPublicationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddPublicationRequest;
  return proto.professionalProfileApi.AddPublicationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddPublicationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddPublicationRequest}
 */
proto.professionalProfileApi.AddPublicationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddPublicationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddPublicationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPublicationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPublicationRequest} returns this
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPublicationRequest} returns this
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPublicationRequest} returns this
 */
proto.professionalProfileApi.AddPublicationRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddPublicationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddPublicationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPublicationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddPublicationResponse}
 */
proto.professionalProfileApi.AddPublicationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddPublicationResponse;
  return proto.professionalProfileApi.AddPublicationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddPublicationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddPublicationResponse}
 */
proto.professionalProfileApi.AddPublicationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddPublicationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddPublicationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPublicationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddPublicationResponse} returns this
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPublicationResponse} returns this
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPublicationResponse} returns this
 */
proto.professionalProfileApi.AddPublicationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdatePublicationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdatePublicationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePublicationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdatePublicationRequest}
 */
proto.professionalProfileApi.UpdatePublicationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdatePublicationRequest;
  return proto.professionalProfileApi.UpdatePublicationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdatePublicationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdatePublicationRequest}
 */
proto.professionalProfileApi.UpdatePublicationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdatePublicationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdatePublicationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePublicationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePublicationRequest} returns this
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePublicationRequest} returns this
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePublicationRequest} returns this
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePublicationRequest} returns this
 */
proto.professionalProfileApi.UpdatePublicationRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdatePublicationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdatePublicationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePublicationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdatePublicationResponse}
 */
proto.professionalProfileApi.UpdatePublicationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdatePublicationResponse;
  return proto.professionalProfileApi.UpdatePublicationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdatePublicationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdatePublicationResponse}
 */
proto.professionalProfileApi.UpdatePublicationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdatePublicationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdatePublicationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdatePublicationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdatePublicationResponse} returns this
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePublicationResponse} returns this
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdatePublicationResponse} returns this
 */
proto.professionalProfileApi.UpdatePublicationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeletePublicationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeletePublicationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeletePublicationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePublicationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeletePublicationRequest}
 */
proto.professionalProfileApi.DeletePublicationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeletePublicationRequest;
  return proto.professionalProfileApi.DeletePublicationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeletePublicationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeletePublicationRequest}
 */
proto.professionalProfileApi.DeletePublicationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeletePublicationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeletePublicationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeletePublicationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePublicationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeletePublicationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePublicationRequest} returns this
 */
proto.professionalProfileApi.DeletePublicationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeletePublicationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeletePublicationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePublicationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeletePublicationResponse}
 */
proto.professionalProfileApi.DeletePublicationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeletePublicationResponse;
  return proto.professionalProfileApi.DeletePublicationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeletePublicationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeletePublicationResponse}
 */
proto.professionalProfileApi.DeletePublicationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeletePublicationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeletePublicationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePublicationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeletePublicationResponse} returns this
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePublicationResponse} returns this
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePublicationResponse} returns this
 */
proto.professionalProfileApi.DeletePublicationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddDiseaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddDiseaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddDiseaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDiseaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddDiseaseRequest}
 */
proto.professionalProfileApi.AddDiseaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddDiseaseRequest;
  return proto.professionalProfileApi.AddDiseaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddDiseaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddDiseaseRequest}
 */
proto.professionalProfileApi.AddDiseaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddDiseaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddDiseaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddDiseaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDiseaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddDiseaseRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddDiseaseRequest} returns this
 */
proto.professionalProfileApi.AddDiseaseRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddDiseaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddDiseaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDiseaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddDiseaseResponse}
 */
proto.professionalProfileApi.AddDiseaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddDiseaseResponse;
  return proto.professionalProfileApi.AddDiseaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddDiseaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddDiseaseResponse}
 */
proto.professionalProfileApi.AddDiseaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddDiseaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddDiseaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddDiseaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddDiseaseResponse} returns this
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddDiseaseResponse} returns this
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddDiseaseResponse} returns this
 */
proto.professionalProfileApi.AddDiseaseResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteDiseaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteDiseaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteDiseaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteDiseaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteDiseaseRequest}
 */
proto.professionalProfileApi.DeleteDiseaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteDiseaseRequest;
  return proto.professionalProfileApi.DeleteDiseaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteDiseaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteDiseaseRequest}
 */
proto.professionalProfileApi.DeleteDiseaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteDiseaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteDiseaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteDiseaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteDiseaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteDiseaseRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteDiseaseRequest} returns this
 */
proto.professionalProfileApi.DeleteDiseaseRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteDiseaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteDiseaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteDiseaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteDiseaseResponse}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteDiseaseResponse;
  return proto.professionalProfileApi.DeleteDiseaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteDiseaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteDiseaseResponse}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteDiseaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteDiseaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteDiseaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteDiseaseResponse} returns this
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteDiseaseResponse} returns this
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteDiseaseResponse} returns this
 */
proto.professionalProfileApi.DeleteDiseaseResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddFormationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddFormationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddFormationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddFormationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    specialization: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institution: jspb.Message.getFieldWithDefault(msg, 3, ""),
    year: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddFormationRequest}
 */
proto.professionalProfileApi.AddFormationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddFormationRequest;
  return proto.professionalProfileApi.AddFormationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddFormationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddFormationRequest}
 */
proto.professionalProfileApi.AddFormationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialization(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitution(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddFormationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddFormationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddFormationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddFormationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpecialization();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitution();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddFormationRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddFormationRequest} returns this
 */
proto.professionalProfileApi.AddFormationRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string specialization = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddFormationRequest.prototype.getSpecialization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddFormationRequest} returns this
 */
proto.professionalProfileApi.AddFormationRequest.prototype.setSpecialization = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddFormationRequest.prototype.getInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddFormationRequest} returns this
 */
proto.professionalProfileApi.AddFormationRequest.prototype.setInstitution = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string year = 4;
 * @return {string}
 */
proto.professionalProfileApi.AddFormationRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddFormationRequest} returns this
 */
proto.professionalProfileApi.AddFormationRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddFormationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddFormationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddFormationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddFormationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddFormationResponse}
 */
proto.professionalProfileApi.AddFormationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddFormationResponse;
  return proto.professionalProfileApi.AddFormationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddFormationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddFormationResponse}
 */
proto.professionalProfileApi.AddFormationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddFormationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddFormationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddFormationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddFormationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddFormationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddFormationResponse} returns this
 */
proto.professionalProfileApi.AddFormationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddFormationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddFormationResponse} returns this
 */
proto.professionalProfileApi.AddFormationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddFormationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddFormationResponse} returns this
 */
proto.professionalProfileApi.AddFormationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateFormationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateFormationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateFormationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    specialization: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institution: jspb.Message.getFieldWithDefault(msg, 3, ""),
    year: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateFormationRequest}
 */
proto.professionalProfileApi.UpdateFormationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateFormationRequest;
  return proto.professionalProfileApi.UpdateFormationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateFormationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateFormationRequest}
 */
proto.professionalProfileApi.UpdateFormationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialization(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitution(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateFormationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateFormationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateFormationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpecialization();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitution();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateFormationRequest} returns this
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string specialization = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.getSpecialization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateFormationRequest} returns this
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.setSpecialization = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.getInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateFormationRequest} returns this
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.setInstitution = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string year = 4;
 * @return {string}
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateFormationRequest} returns this
 */
proto.professionalProfileApi.UpdateFormationRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateFormationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateFormationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateFormationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateFormationResponse}
 */
proto.professionalProfileApi.UpdateFormationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateFormationResponse;
  return proto.professionalProfileApi.UpdateFormationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateFormationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateFormationResponse}
 */
proto.professionalProfileApi.UpdateFormationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateFormationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateFormationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateFormationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateFormationResponse} returns this
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateFormationResponse} returns this
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateFormationResponse} returns this
 */
proto.professionalProfileApi.UpdateFormationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteFormationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteFormationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteFormationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteFormationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteFormationRequest}
 */
proto.professionalProfileApi.DeleteFormationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteFormationRequest;
  return proto.professionalProfileApi.DeleteFormationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteFormationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteFormationRequest}
 */
proto.professionalProfileApi.DeleteFormationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteFormationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteFormationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteFormationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteFormationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeleteFormationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteFormationRequest} returns this
 */
proto.professionalProfileApi.DeleteFormationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeleteFormationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeleteFormationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteFormationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeleteFormationResponse}
 */
proto.professionalProfileApi.DeleteFormationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeleteFormationResponse;
  return proto.professionalProfileApi.DeleteFormationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeleteFormationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeleteFormationResponse}
 */
proto.professionalProfileApi.DeleteFormationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeleteFormationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeleteFormationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeleteFormationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeleteFormationResponse} returns this
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteFormationResponse} returns this
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeleteFormationResponse} returns this
 */
proto.professionalProfileApi.DeleteFormationResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateVisiblesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateVisiblesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateVisiblesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateVisiblesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    field: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateVisiblesRequest}
 */
proto.professionalProfileApi.UpdateVisiblesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateVisiblesRequest;
  return proto.professionalProfileApi.UpdateVisiblesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateVisiblesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateVisiblesRequest}
 */
proto.professionalProfileApi.UpdateVisiblesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateVisiblesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateVisiblesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateVisiblesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateVisiblesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateVisiblesRequest.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateVisiblesRequest} returns this
 */
proto.professionalProfileApi.UpdateVisiblesRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateVisiblesRequest.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateVisiblesRequest} returns this
 */
proto.professionalProfileApi.UpdateVisiblesRequest.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateVisiblesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateVisiblesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateVisiblesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateVisiblesResponse}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateVisiblesResponse;
  return proto.professionalProfileApi.UpdateVisiblesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateVisiblesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateVisiblesResponse}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateVisiblesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateVisiblesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateVisiblesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateVisiblesResponse} returns this
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateVisiblesResponse} returns this
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateVisiblesResponse} returns this
 */
proto.professionalProfileApi.UpdateVisiblesResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileList: jspb.Message.toObjectList(msg.getProfileList(),
    proto.professionalProfileApi.ProfileOfProfessional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalProfileResponse;
  return proto.professionalProfileApi.ListProfessionalProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ProfileOfProfessional;
      reader.readMessage(value,proto.professionalProfileApi.ProfileOfProfessional.deserializeBinaryFromReader);
      msg.addProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ProfileOfProfessional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProfileOfProfessional profile = 4;
 * @return {!Array<!proto.professionalProfileApi.ProfileOfProfessional>}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.getProfileList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfileOfProfessional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfileOfProfessional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfileOfProfessional>} value
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse} returns this
*/
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.setProfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfileOfProfessional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfileOfProfessional}
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.addProfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ProfileOfProfessional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListProfessionalProfileResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalProfileResponse.prototype.clearProfileList = function() {
  return this.setProfileList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ProfileOfProfessional.repeatedFields_ = [21,22,23,24,25,26,27,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfileOfProfessional.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfileOfProfessional} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfileOfProfessional.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    socialsecuritynumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    picturefileid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    biography: jspb.Message.getFieldWithDefault(msg, 11, ""),
    completedprofile: jspb.Message.getFieldWithDefault(msg, 12, 0),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    visibleaward: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    visiblecertificate: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    visibledisease: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    visibleformation: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    visiblelanguage: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    visiblepublication: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    visibleexperience: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    experiencesList: jspb.Message.toObjectList(msg.getExperiencesList(),
    proto.professionalProfileApi.ListExperience.toObject, includeInstance),
    certificatesList: jspb.Message.toObjectList(msg.getCertificatesList(),
    proto.professionalProfileApi.ListCertificate.toObject, includeInstance),
    awardsList: jspb.Message.toObjectList(msg.getAwardsList(),
    proto.professionalProfileApi.ListAward.toObject, includeInstance),
    publicationsList: jspb.Message.toObjectList(msg.getPublicationsList(),
    proto.professionalProfileApi.ListPublication.toObject, includeInstance),
    languagesList: jspb.Message.toObjectList(msg.getLanguagesList(),
    proto.professionalProfileApi.ListLanguage.toObject, includeInstance),
    formationsList: jspb.Message.toObjectList(msg.getFormationsList(),
    proto.professionalProfileApi.ListFormation.toObject, includeInstance),
    diseasesList: jspb.Message.toObjectList(msg.getDiseasesList(),
    proto.professionalProfileApi.ListDisease.toObject, includeInstance),
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.professionalProfileApi.Place.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional}
 */
proto.professionalProfileApi.ProfileOfProfessional.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfileOfProfessional;
  return proto.professionalProfileApi.ProfileOfProfessional.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfileOfProfessional} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional}
 */
proto.professionalProfileApi.ProfileOfProfessional.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialsecuritynumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicturefileid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiography(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedprofile(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleaward(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisiblecertificate(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibledisease(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleformation(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisiblelanguage(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisiblepublication(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleexperience(value);
      break;
    case 21:
      var value = new proto.professionalProfileApi.ListExperience;
      reader.readMessage(value,proto.professionalProfileApi.ListExperience.deserializeBinaryFromReader);
      msg.addExperiences(value);
      break;
    case 22:
      var value = new proto.professionalProfileApi.ListCertificate;
      reader.readMessage(value,proto.professionalProfileApi.ListCertificate.deserializeBinaryFromReader);
      msg.addCertificates(value);
      break;
    case 23:
      var value = new proto.professionalProfileApi.ListAward;
      reader.readMessage(value,proto.professionalProfileApi.ListAward.deserializeBinaryFromReader);
      msg.addAwards(value);
      break;
    case 24:
      var value = new proto.professionalProfileApi.ListPublication;
      reader.readMessage(value,proto.professionalProfileApi.ListPublication.deserializeBinaryFromReader);
      msg.addPublications(value);
      break;
    case 25:
      var value = new proto.professionalProfileApi.ListLanguage;
      reader.readMessage(value,proto.professionalProfileApi.ListLanguage.deserializeBinaryFromReader);
      msg.addLanguages(value);
      break;
    case 26:
      var value = new proto.professionalProfileApi.ListFormation;
      reader.readMessage(value,proto.professionalProfileApi.ListFormation.deserializeBinaryFromReader);
      msg.addFormations(value);
      break;
    case 27:
      var value = new proto.professionalProfileApi.ListDisease;
      reader.readMessage(value,proto.professionalProfileApi.ListDisease.deserializeBinaryFromReader);
      msg.addDiseases(value);
      break;
    case 28:
      var value = new proto.professionalProfileApi.Place;
      reader.readMessage(value,proto.professionalProfileApi.Place.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfileOfProfessional.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfileOfProfessional} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfileOfProfessional.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSocialsecuritynumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPicturefileid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBiography();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCompletedprofile();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVisibleaward();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getVisiblecertificate();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getVisibledisease();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getVisibleformation();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getVisiblelanguage();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getVisiblepublication();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getVisibleexperience();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getExperiencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.professionalProfileApi.ListExperience.serializeBinaryToWriter
    );
  }
  f = message.getCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.professionalProfileApi.ListCertificate.serializeBinaryToWriter
    );
  }
  f = message.getAwardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.professionalProfileApi.ListAward.serializeBinaryToWriter
    );
  }
  f = message.getPublicationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.professionalProfileApi.ListPublication.serializeBinaryToWriter
    );
  }
  f = message.getLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.professionalProfileApi.ListLanguage.serializeBinaryToWriter
    );
  }
  f = message.getFormationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.professionalProfileApi.ListFormation.serializeBinaryToWriter
    );
  }
  f = message.getDiseasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.professionalProfileApi.ListDisease.serializeBinaryToWriter
    );
  }
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.professionalProfileApi.Place.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string socialSecurityNumber = 6;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getSocialsecuritynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setSocialsecuritynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string identityNumber = 7;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string birthDate = 8;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string pictureFileId = 9;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getPicturefileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setPicturefileid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string pictureUrl = 10;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string biography = 11;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getBiography = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setBiography = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 completedProfile = 12;
 * @return {number}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getCompletedprofile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setCompletedprofile = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool visibleAward = 14;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisibleaward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisibleaward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool visibleCertificate = 15;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisiblecertificate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisiblecertificate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool visibleDisease = 16;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisibledisease = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisibledisease = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool visibleFormation = 17;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisibleformation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisibleformation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool visibleLanguage = 18;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisiblelanguage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisiblelanguage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool visiblePublication = 19;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisiblepublication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisiblepublication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool visibleExperience = 20;
 * @return {boolean}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getVisibleexperience = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.setVisibleexperience = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated ListExperience experiences = 21;
 * @return {!Array<!proto.professionalProfileApi.ListExperience>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getExperiencesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListExperience>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListExperience, 21));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListExperience>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setExperiencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.professionalProfileApi.ListExperience=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListExperience}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addExperiences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.professionalProfileApi.ListExperience, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearExperiencesList = function() {
  return this.setExperiencesList([]);
};


/**
 * repeated ListCertificate certificates = 22;
 * @return {!Array<!proto.professionalProfileApi.ListCertificate>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getCertificatesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListCertificate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListCertificate, 22));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListCertificate>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.professionalProfileApi.ListCertificate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListCertificate}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.professionalProfileApi.ListCertificate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearCertificatesList = function() {
  return this.setCertificatesList([]);
};


/**
 * repeated ListAward awards = 23;
 * @return {!Array<!proto.professionalProfileApi.ListAward>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getAwardsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListAward>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListAward, 23));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListAward>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setAwardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.professionalProfileApi.ListAward=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListAward}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addAwards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.professionalProfileApi.ListAward, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearAwardsList = function() {
  return this.setAwardsList([]);
};


/**
 * repeated ListPublication publications = 24;
 * @return {!Array<!proto.professionalProfileApi.ListPublication>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getPublicationsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListPublication>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListPublication, 24));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListPublication>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setPublicationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.professionalProfileApi.ListPublication=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListPublication}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addPublications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.professionalProfileApi.ListPublication, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearPublicationsList = function() {
  return this.setPublicationsList([]);
};


/**
 * repeated ListLanguage languages = 25;
 * @return {!Array<!proto.professionalProfileApi.ListLanguage>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getLanguagesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListLanguage, 25));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListLanguage>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setLanguagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.professionalProfileApi.ListLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListLanguage}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addLanguages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.professionalProfileApi.ListLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearLanguagesList = function() {
  return this.setLanguagesList([]);
};


/**
 * repeated ListFormation formations = 26;
 * @return {!Array<!proto.professionalProfileApi.ListFormation>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getFormationsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListFormation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListFormation, 26));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListFormation>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setFormationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.professionalProfileApi.ListFormation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListFormation}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addFormations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.professionalProfileApi.ListFormation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearFormationsList = function() {
  return this.setFormationsList([]);
};


/**
 * repeated ListDisease diseases = 27;
 * @return {!Array<!proto.professionalProfileApi.ListDisease>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getDiseasesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ListDisease>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ListDisease, 27));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ListDisease>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setDiseasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.professionalProfileApi.ListDisease=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListDisease}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addDiseases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.professionalProfileApi.ListDisease, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearDiseasesList = function() {
  return this.setDiseasesList([]);
};


/**
 * repeated Place places = 28;
 * @return {!Array<!proto.professionalProfileApi.Place>}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Place>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Place, 28));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Place>} value
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
*/
proto.professionalProfileApi.ProfileOfProfessional.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.professionalProfileApi.Place=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Place}
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.professionalProfileApi.Place, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfileOfProfessional} returns this
 */
proto.professionalProfileApi.ProfileOfProfessional.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListExperience.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListExperience.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListExperience} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListExperience.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institution: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    current: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    startyear: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endyear: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListExperience}
 */
proto.professionalProfileApi.ListExperience.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListExperience;
  return proto.professionalProfileApi.ListExperience.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListExperience} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListExperience}
 */
proto.professionalProfileApi.ListExperience.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitution(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartyear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndyear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListExperience.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListExperience.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListExperience} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListExperience.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitution();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStartyear();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndyear();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListExperience.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListExperience.prototype.getInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setInstitution = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListExperience.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListExperience.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool current = 5;
 * @return {boolean}
 */
proto.professionalProfileApi.ListExperience.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string startYear = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListExperience.prototype.getStartyear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setStartyear = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string endYear = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListExperience.prototype.getEndyear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListExperience} returns this
 */
proto.professionalProfileApi.ListExperience.prototype.setEndyear = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListCertificate.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListCertificate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListCertificate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCertificate.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentfileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documenturl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListCertificate}
 */
proto.professionalProfileApi.ListCertificate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListCertificate;
  return proto.professionalProfileApi.ListCertificate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListCertificate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListCertificate}
 */
proto.professionalProfileApi.ListCertificate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentfileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenturl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListCertificate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListCertificate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListCertificate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListCertificate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumenturl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string documentFileId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListCertificate.prototype.getDocumentfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCertificate} returns this
 */
proto.professionalProfileApi.ListCertificate.prototype.setDocumentfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string documentUrl = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListCertificate.prototype.getDocumenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListCertificate} returns this
 */
proto.professionalProfileApi.ListCertificate.prototype.setDocumenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListAward.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListAward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListAward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAward.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uf: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    year: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListAward}
 */
proto.professionalProfileApi.ListAward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListAward;
  return proto.professionalProfileApi.ListAward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListAward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListAward}
 */
proto.professionalProfileApi.ListAward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUf(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListAward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListAward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListAward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListAward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUf();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string uf = 5;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getUf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setUf = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string year = 7;
 * @return {string}
 */
proto.professionalProfileApi.ListAward.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListAward} returns this
 */
proto.professionalProfileApi.ListAward.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPublication.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPublication.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPublication} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPublication.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPublication}
 */
proto.professionalProfileApi.ListPublication.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPublication;
  return proto.professionalProfileApi.ListPublication.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPublication} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPublication}
 */
proto.professionalProfileApi.ListPublication.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPublication.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPublication.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPublication} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPublication.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListPublication.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPublication} returns this
 */
proto.professionalProfileApi.ListPublication.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPublication.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPublication} returns this
 */
proto.professionalProfileApi.ListPublication.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListPublication.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPublication} returns this
 */
proto.professionalProfileApi.ListPublication.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.professionalProfileApi.ListPublication.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPublication} returns this
 */
proto.professionalProfileApi.ListPublication.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    idiom: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListLanguage}
 */
proto.professionalProfileApi.ListLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListLanguage;
  return proto.professionalProfileApi.ListLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListLanguage}
 */
proto.professionalProfileApi.ListLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdiom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdiom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string idiom = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListLanguage.prototype.getIdiom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListLanguage} returns this
 */
proto.professionalProfileApi.ListLanguage.prototype.setIdiom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListFormation.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListFormation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListFormation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListFormation.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documenturl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListFormation}
 */
proto.professionalProfileApi.ListFormation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListFormation;
  return proto.professionalProfileApi.ListFormation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListFormation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListFormation}
 */
proto.professionalProfileApi.ListFormation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenturl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListFormation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListFormation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListFormation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListFormation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumenturl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string documentId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListFormation.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListFormation} returns this
 */
proto.professionalProfileApi.ListFormation.prototype.setDocumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string documentUrl = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListFormation.prototype.getDocumenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListFormation} returns this
 */
proto.professionalProfileApi.ListFormation.prototype.setDocumenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListDisease.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListDisease.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListDisease} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListDisease.toObject = function(includeInstance, msg) {
  var f, obj = {
    disease: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListDisease}
 */
proto.professionalProfileApi.ListDisease.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListDisease;
  return proto.professionalProfileApi.ListDisease.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListDisease} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListDisease}
 */
proto.professionalProfileApi.ListDisease.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListDisease.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListDisease.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListDisease} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListDisease.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisease();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string disease = 1;
 * @return {string}
 */
proto.professionalProfileApi.ListDisease.prototype.getDisease = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListDisease} returns this
 */
proto.professionalProfileApi.ListDisease.prototype.setDisease = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateBiographyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateBiographyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateBiographyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateBiographyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    biography: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateBiographyRequest}
 */
proto.professionalProfileApi.UpdateBiographyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateBiographyRequest;
  return proto.professionalProfileApi.UpdateBiographyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateBiographyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateBiographyRequest}
 */
proto.professionalProfileApi.UpdateBiographyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiography(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateBiographyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateBiographyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateBiographyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateBiographyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBiography();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string biography = 1;
 * @return {string}
 */
proto.professionalProfileApi.UpdateBiographyRequest.prototype.getBiography = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateBiographyRequest} returns this
 */
proto.professionalProfileApi.UpdateBiographyRequest.prototype.setBiography = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.UpdateBiographyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.UpdateBiographyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateBiographyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.UpdateBiographyResponse}
 */
proto.professionalProfileApi.UpdateBiographyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.UpdateBiographyResponse;
  return proto.professionalProfileApi.UpdateBiographyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.UpdateBiographyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.UpdateBiographyResponse}
 */
proto.professionalProfileApi.UpdateBiographyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.UpdateBiographyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.UpdateBiographyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.UpdateBiographyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.UpdateBiographyResponse} returns this
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateBiographyResponse} returns this
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.UpdateBiographyResponse} returns this
 */
proto.professionalProfileApi.UpdateBiographyResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SeeAsPatientResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SeeAsPatientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SeeAsPatientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SeeAsPatientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileList: jspb.Message.toObjectList(msg.getProfileList(),
    proto.professionalProfileApi.ProfileOfProfessional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse}
 */
proto.professionalProfileApi.SeeAsPatientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SeeAsPatientResponse;
  return proto.professionalProfileApi.SeeAsPatientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SeeAsPatientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse}
 */
proto.professionalProfileApi.SeeAsPatientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ProfileOfProfessional;
      reader.readMessage(value,proto.professionalProfileApi.ProfileOfProfessional.deserializeBinaryFromReader);
      msg.addProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SeeAsPatientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SeeAsPatientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SeeAsPatientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ProfileOfProfessional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse} returns this
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse} returns this
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse} returns this
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProfileOfProfessional profile = 4;
 * @return {!Array<!proto.professionalProfileApi.ProfileOfProfessional>}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.getProfileList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfileOfProfessional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfileOfProfessional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfileOfProfessional>} value
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse} returns this
*/
proto.professionalProfileApi.SeeAsPatientResponse.prototype.setProfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfileOfProfessional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfileOfProfessional}
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.addProfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ProfileOfProfessional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SeeAsPatientResponse} returns this
 */
proto.professionalProfileApi.SeeAsPatientResponse.prototype.clearProfileList = function() {
  return this.setProfileList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest;
  return proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProfessionalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string professionalId = 1;
 * @return {!Array<string>}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.prototype.getProfessionalidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.prototype.setProfessionalidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.prototype.addProfessionalid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsRequest.prototype.clearProfessionalidList = function() {
  return this.setProfessionalidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    proto.professionalProfileApi.InfoProfessionals.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse;
  return proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.InfoProfessionals;
      reader.readMessage(value,proto.professionalProfileApi.InfoProfessionals.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.InfoProfessionals.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated InfoProfessionals profiles = 4;
 * @return {!Array<!proto.professionalProfileApi.InfoProfessionals>}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.InfoProfessionals>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.InfoProfessionals, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.InfoProfessionals>} value
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} returns this
*/
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.InfoProfessionals=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.InfoProfessionals}
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.InfoProfessionals, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsMedicalRecordsResponse.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.InfoProfessionals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.InfoProfessionals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.InfoProfessionals.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    picturefileid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.InfoProfessionals}
 */
proto.professionalProfileApi.InfoProfessionals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.InfoProfessionals;
  return proto.professionalProfileApi.InfoProfessionals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.InfoProfessionals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.InfoProfessionals}
 */
proto.professionalProfileApi.InfoProfessionals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicturefileid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.InfoProfessionals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.InfoProfessionals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.InfoProfessionals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPicturefileid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string professionalId = 1;
 * @return {string}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProfessionals} returns this
 */
proto.professionalProfileApi.InfoProfessionals.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProfessionals} returns this
 */
proto.professionalProfileApi.InfoProfessionals.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProfessionals} returns this
 */
proto.professionalProfileApi.InfoProfessionals.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pictureFileId = 4;
 * @return {string}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.getPicturefileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProfessionals} returns this
 */
proto.professionalProfileApi.InfoProfessionals.prototype.setPicturefileid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pictureUrl = 5;
 * @return {string}
 */
proto.professionalProfileApi.InfoProfessionals.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProfessionals} returns this
 */
proto.professionalProfileApi.InfoProfessionals.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchProfessionalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchProfessionalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchProfessionalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchProfessionalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchProfessionalsRequest}
 */
proto.professionalProfileApi.SearchProfessionalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchProfessionalsRequest;
  return proto.professionalProfileApi.SearchProfessionalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchProfessionalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchProfessionalsRequest}
 */
proto.professionalProfileApi.SearchProfessionalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchProfessionalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchProfessionalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchProfessionalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchProfessionalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.professionalProfileApi.SearchProfessionalsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchProfessionalsRequest} returns this
 */
proto.professionalProfileApi.SearchProfessionalsRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchProfessionalsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchProfessionalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchProfessionalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchProfessionalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalsList: jspb.Message.toObjectList(msg.getProfessionalsList(),
    proto.professionalProfileApi.InfoProf.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchProfessionalsResponse;
  return proto.professionalProfileApi.SearchProfessionalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchProfessionalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.InfoProf;
      reader.readMessage(value,proto.professionalProfileApi.InfoProf.deserializeBinaryFromReader);
      msg.addProfessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchProfessionalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchProfessionalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchProfessionalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.InfoProf.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse} returns this
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse} returns this
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse} returns this
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated InfoProf professionals = 4;
 * @return {!Array<!proto.professionalProfileApi.InfoProf>}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.getProfessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.InfoProf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.InfoProf, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.InfoProf>} value
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse} returns this
*/
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.setProfessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.InfoProf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.InfoProf}
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.addProfessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.InfoProf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchProfessionalsResponse} returns this
 */
proto.professionalProfileApi.SearchProfessionalsResponse.prototype.clearProfessionalsList = function() {
  return this.setProfessionalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.InfoProf.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.InfoProf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.InfoProf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.InfoProf.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.InfoProf}
 */
proto.professionalProfileApi.InfoProf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.InfoProf;
  return proto.professionalProfileApi.InfoProf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.InfoProf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.InfoProf}
 */
proto.professionalProfileApi.InfoProf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.InfoProf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.InfoProf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.InfoProf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.InfoProf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string professionalId = 1;
 * @return {string}
 */
proto.professionalProfileApi.InfoProf.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProf} returns this
 */
proto.professionalProfileApi.InfoProf.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.InfoProf.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.InfoProf} returns this
 */
proto.professionalProfileApi.InfoProf.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddPartnershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddPartnershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddPartnershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPartnershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddPartnershipRequest}
 */
proto.professionalProfileApi.AddPartnershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddPartnershipRequest;
  return proto.professionalProfileApi.AddPartnershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddPartnershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddPartnershipRequest}
 */
proto.professionalProfileApi.AddPartnershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddPartnershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddPartnershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddPartnershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPartnershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string professionalId = 1;
 * @return {string}
 */
proto.professionalProfileApi.AddPartnershipRequest.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPartnershipRequest} returns this
 */
proto.professionalProfileApi.AddPartnershipRequest.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.AddPartnershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.AddPartnershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPartnershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.AddPartnershipResponse}
 */
proto.professionalProfileApi.AddPartnershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.AddPartnershipResponse;
  return proto.professionalProfileApi.AddPartnershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.AddPartnershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.AddPartnershipResponse}
 */
proto.professionalProfileApi.AddPartnershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.AddPartnershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.AddPartnershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.AddPartnershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.AddPartnershipResponse} returns this
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPartnershipResponse} returns this
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.AddPartnershipResponse} returns this
 */
proto.professionalProfileApi.AddPartnershipResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeletePartnershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeletePartnershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeletePartnershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePartnershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnershipid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeletePartnershipRequest}
 */
proto.professionalProfileApi.DeletePartnershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeletePartnershipRequest;
  return proto.professionalProfileApi.DeletePartnershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeletePartnershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeletePartnershipRequest}
 */
proto.professionalProfileApi.DeletePartnershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeletePartnershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeletePartnershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeletePartnershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePartnershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnershipid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string partnershipId = 1;
 * @return {string}
 */
proto.professionalProfileApi.DeletePartnershipRequest.prototype.getPartnershipid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePartnershipRequest} returns this
 */
proto.professionalProfileApi.DeletePartnershipRequest.prototype.setPartnershipid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.DeletePartnershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.DeletePartnershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePartnershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.DeletePartnershipResponse}
 */
proto.professionalProfileApi.DeletePartnershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.DeletePartnershipResponse;
  return proto.professionalProfileApi.DeletePartnershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.DeletePartnershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.DeletePartnershipResponse}
 */
proto.professionalProfileApi.DeletePartnershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.DeletePartnershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.DeletePartnershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.DeletePartnershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.DeletePartnershipResponse} returns this
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePartnershipResponse} returns this
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.DeletePartnershipResponse} returns this
 */
proto.professionalProfileApi.DeletePartnershipResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListPartnershipsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListPartnershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListPartnershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPartnershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnersList: jspb.Message.toObjectList(msg.getPartnersList(),
    proto.professionalProfileApi.ProfessionalPartner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse}
 */
proto.professionalProfileApi.ListPartnershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListPartnershipsResponse;
  return proto.professionalProfileApi.ListPartnershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListPartnershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse}
 */
proto.professionalProfileApi.ListPartnershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ProfessionalPartner;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalPartner.deserializeBinaryFromReader);
      msg.addPartners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListPartnershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListPartnershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListPartnershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ProfessionalPartner.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProfessionalPartner partners = 4;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalPartner>}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.getPartnersList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalPartner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalPartner, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalPartner>} value
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse} returns this
*/
proto.professionalProfileApi.ListPartnershipsResponse.prototype.setPartnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalPartner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalPartner}
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.addPartners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ProfessionalPartner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.ListPartnershipsResponse.prototype.clearPartnersList = function() {
  return this.setPartnersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ProfessionalPartner.repeatedFields_ = [9,10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ProfessionalPartner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ProfessionalPartner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalPartner.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnershipid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    professionalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    picturefileid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    biography: jspb.Message.getFieldWithDefault(msg, 8, ""),
    servicelocationsList: jspb.Message.toObjectList(msg.getServicelocationsList(),
    proto.professionalProfileApi.ServiceLocations.toObject, includeInstance),
    professionsList: jspb.Message.toObjectList(msg.getProfessionsList(),
    proto.professionalProfileApi.Professions.toObject, includeInstance),
    specialitiesList: jspb.Message.toObjectList(msg.getSpecialitiesList(),
    proto.professionalProfileApi.SpecialitiesPartner.toObject, includeInstance),
    diseasesList: jspb.Message.toObjectList(msg.getDiseasesList(),
    proto.professionalProfileApi.Diseases.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ProfessionalPartner}
 */
proto.professionalProfileApi.ProfessionalPartner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ProfessionalPartner;
  return proto.professionalProfileApi.ProfessionalPartner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ProfessionalPartner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ProfessionalPartner}
 */
proto.professionalProfileApi.ProfessionalPartner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicturefileid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiography(value);
      break;
    case 9:
      var value = new proto.professionalProfileApi.ServiceLocations;
      reader.readMessage(value,proto.professionalProfileApi.ServiceLocations.deserializeBinaryFromReader);
      msg.addServicelocations(value);
      break;
    case 10:
      var value = new proto.professionalProfileApi.Professions;
      reader.readMessage(value,proto.professionalProfileApi.Professions.deserializeBinaryFromReader);
      msg.addProfessions(value);
      break;
    case 11:
      var value = new proto.professionalProfileApi.SpecialitiesPartner;
      reader.readMessage(value,proto.professionalProfileApi.SpecialitiesPartner.deserializeBinaryFromReader);
      msg.addSpecialities(value);
      break;
    case 12:
      var value = new proto.professionalProfileApi.Diseases;
      reader.readMessage(value,proto.professionalProfileApi.Diseases.deserializeBinaryFromReader);
      msg.addDiseases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ProfessionalPartner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ProfessionalPartner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ProfessionalPartner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnershipid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPicturefileid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBiography();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getServicelocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.professionalProfileApi.ServiceLocations.serializeBinaryToWriter
    );
  }
  f = message.getProfessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.professionalProfileApi.Professions.serializeBinaryToWriter
    );
  }
  f = message.getSpecialitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.professionalProfileApi.SpecialitiesPartner.serializeBinaryToWriter
    );
  }
  f = message.getDiseasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.professionalProfileApi.Diseases.serializeBinaryToWriter
    );
  }
};


/**
 * optional string partnershipId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getPartnershipid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setPartnershipid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string professionalId = 2;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string pictureFileId = 6;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getPicturefileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setPicturefileid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string pictureUrl = 7;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string biography = 8;
 * @return {string}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getBiography = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.setBiography = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ServiceLocations serviceLocations = 9;
 * @return {!Array<!proto.professionalProfileApi.ServiceLocations>}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getServicelocationsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ServiceLocations>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ServiceLocations, 9));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ServiceLocations>} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
*/
proto.professionalProfileApi.ProfessionalPartner.prototype.setServicelocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.professionalProfileApi.ServiceLocations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ServiceLocations}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.addServicelocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.professionalProfileApi.ServiceLocations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.clearServicelocationsList = function() {
  return this.setServicelocationsList([]);
};


/**
 * repeated Professions professions = 10;
 * @return {!Array<!proto.professionalProfileApi.Professions>}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getProfessionsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Professions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Professions, 10));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Professions>} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
*/
proto.professionalProfileApi.ProfessionalPartner.prototype.setProfessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.professionalProfileApi.Professions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Professions}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.addProfessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.professionalProfileApi.Professions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.clearProfessionsList = function() {
  return this.setProfessionsList([]);
};


/**
 * repeated SpecialitiesPartner specialities = 11;
 * @return {!Array<!proto.professionalProfileApi.SpecialitiesPartner>}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getSpecialitiesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.SpecialitiesPartner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.SpecialitiesPartner, 11));
};


/**
 * @param {!Array<!proto.professionalProfileApi.SpecialitiesPartner>} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
*/
proto.professionalProfileApi.ProfessionalPartner.prototype.setSpecialitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.professionalProfileApi.SpecialitiesPartner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.SpecialitiesPartner}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.addSpecialities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.professionalProfileApi.SpecialitiesPartner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.clearSpecialitiesList = function() {
  return this.setSpecialitiesList([]);
};


/**
 * repeated Diseases diseases = 12;
 * @return {!Array<!proto.professionalProfileApi.Diseases>}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.getDiseasesList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Diseases>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Diseases, 12));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Diseases>} value
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
*/
proto.professionalProfileApi.ProfessionalPartner.prototype.setDiseasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.professionalProfileApi.Diseases=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Diseases}
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.addDiseases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.professionalProfileApi.Diseases, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ProfessionalPartner} returns this
 */
proto.professionalProfileApi.ProfessionalPartner.prototype.clearDiseasesList = function() {
  return this.setDiseasesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ServiceLocations.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ServiceLocations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ServiceLocations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceLocations.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 7, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 8, ""),
    city: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    country: jspb.Message.getFieldWithDefault(msg, 13, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ServiceLocations}
 */
proto.professionalProfileApi.ServiceLocations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ServiceLocations;
  return proto.professionalProfileApi.ServiceLocations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ServiceLocations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ServiceLocations}
 */
proto.professionalProfileApi.ServiceLocations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ServiceLocations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ServiceLocations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ServiceLocations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ServiceLocations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string picture_url = 4;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string complement = 7;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string neighborhood = 8;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city = 9;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string country = 13;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional double latitude = 14;
 * @return {number}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double longitude = 15;
 * @return {number}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional string status = 16;
 * @return {string}
 */
proto.professionalProfileApi.ServiceLocations.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ServiceLocations} returns this
 */
proto.professionalProfileApi.ServiceLocations.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Professions.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Professions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Professions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Professions.toObject = function(includeInstance, msg) {
  var f, obj = {
    occupation: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Professions}
 */
proto.professionalProfileApi.Professions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Professions;
  return proto.professionalProfileApi.Professions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Professions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Professions}
 */
proto.professionalProfileApi.Professions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Professions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Professions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Professions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Professions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string occupation = 1;
 * @return {string}
 */
proto.professionalProfileApi.Professions.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professions} returns this
 */
proto.professionalProfileApi.Professions.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SpecialitiesPartner.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SpecialitiesPartner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SpecialitiesPartner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesPartner.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SpecialitiesPartner}
 */
proto.professionalProfileApi.SpecialitiesPartner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SpecialitiesPartner;
  return proto.professionalProfileApi.SpecialitiesPartner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SpecialitiesPartner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SpecialitiesPartner}
 */
proto.professionalProfileApi.SpecialitiesPartner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SpecialitiesPartner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SpecialitiesPartner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SpecialitiesPartner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SpecialitiesPartner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.professionalProfileApi.SpecialitiesPartner.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SpecialitiesPartner} returns this
 */
proto.professionalProfileApi.SpecialitiesPartner.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Diseases.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Diseases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Diseases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Diseases.toObject = function(includeInstance, msg) {
  var f, obj = {
    disease: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Diseases}
 */
proto.professionalProfileApi.Diseases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Diseases;
  return proto.professionalProfileApi.Diseases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Diseases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Diseases}
 */
proto.professionalProfileApi.Diseases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Diseases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Diseases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Diseases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Diseases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisease();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string disease = 1;
 * @return {string}
 */
proto.professionalProfileApi.Diseases.prototype.getDisease = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Diseases} returns this
 */
proto.professionalProfileApi.Diseases.prototype.setDisease = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchListPartnershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchListPartnershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchListPartnershipsRequest}
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchListPartnershipsRequest;
  return proto.professionalProfileApi.SearchListPartnershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchListPartnershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchListPartnershipsRequest}
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchListPartnershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchListPartnershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPartnershipsRequest} returns this
 */
proto.professionalProfileApi.SearchListPartnershipsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.SearchListPartnershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.SearchListPartnershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnersList: jspb.Message.toObjectList(msg.getPartnersList(),
    proto.professionalProfileApi.ProfessionalPartner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.SearchListPartnershipsResponse;
  return proto.professionalProfileApi.SearchListPartnershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.SearchListPartnershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.ProfessionalPartner;
      reader.readMessage(value,proto.professionalProfileApi.ProfessionalPartner.deserializeBinaryFromReader);
      msg.addPartners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.SearchListPartnershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.SearchListPartnershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.ProfessionalPartner.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProfessionalPartner partners = 4;
 * @return {!Array<!proto.professionalProfileApi.ProfessionalPartner>}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.getPartnersList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.ProfessionalPartner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.ProfessionalPartner, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.ProfessionalPartner>} value
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse} returns this
*/
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.setPartnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.ProfessionalPartner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.ProfessionalPartner}
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.addPartners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.ProfessionalPartner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.SearchListPartnershipsResponse} returns this
 */
proto.professionalProfileApi.SearchListPartnershipsResponse.prototype.clearPartnersList = function() {
  return this.setPartnersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetPartnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetPartnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetPartnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetPartnerRequest}
 */
proto.professionalProfileApi.GetPartnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetPartnerRequest;
  return proto.professionalProfileApi.GetPartnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetPartnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetPartnerRequest}
 */
proto.professionalProfileApi.GetPartnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetPartnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetPartnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetPartnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string professionalId = 1;
 * @return {string}
 */
proto.professionalProfileApi.GetPartnerRequest.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartnerRequest} returns this
 */
proto.professionalProfileApi.GetPartnerRequest.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetPartnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetPartnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professional: (f = msg.getProfessional()) && proto.professionalProfileApi.GetPartner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetPartnerResponse}
 */
proto.professionalProfileApi.GetPartnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetPartnerResponse;
  return proto.professionalProfileApi.GetPartnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetPartnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetPartnerResponse}
 */
proto.professionalProfileApi.GetPartnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.GetPartner;
      reader.readMessage(value,proto.professionalProfileApi.GetPartner.deserializeBinaryFromReader);
      msg.setProfessional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetPartnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetPartnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessional();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.professionalProfileApi.GetPartner.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.GetPartnerResponse} returns this
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartnerResponse} returns this
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartnerResponse} returns this
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GetPartner professional = 4;
 * @return {?proto.professionalProfileApi.GetPartner}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.getProfessional = function() {
  return /** @type{?proto.professionalProfileApi.GetPartner} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.GetPartner, 4));
};


/**
 * @param {?proto.professionalProfileApi.GetPartner|undefined} value
 * @return {!proto.professionalProfileApi.GetPartnerResponse} returns this
*/
proto.professionalProfileApi.GetPartnerResponse.prototype.setProfessional = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.GetPartnerResponse} returns this
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.clearProfessional = function() {
  return this.setProfessional(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.GetPartnerResponse.prototype.hasProfessional = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.GetPartner.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.GetPartner.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.GetPartner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.GetPartner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartner.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pictureurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    councilprofessionalsList: jspb.Message.toObjectList(msg.getCouncilprofessionalsList(),
    proto.professionalProfileApi.CouncilProfessional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.GetPartner}
 */
proto.professionalProfileApi.GetPartner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.GetPartner;
  return proto.professionalProfileApi.GetPartner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.GetPartner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.GetPartner}
 */
proto.professionalProfileApi.GetPartner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureurl(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.CouncilProfessional;
      reader.readMessage(value,proto.professionalProfileApi.CouncilProfessional.deserializeBinaryFromReader);
      msg.addCouncilprofessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.GetPartner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.GetPartner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.GetPartner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.GetPartner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPictureurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCouncilprofessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.CouncilProfessional.serializeBinaryToWriter
    );
  }
};


/**
 * optional string professionalId = 1;
 * @return {string}
 */
proto.professionalProfileApi.GetPartner.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartner} returns this
 */
proto.professionalProfileApi.GetPartner.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.professionalProfileApi.GetPartner.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartner} returns this
 */
proto.professionalProfileApi.GetPartner.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.professionalProfileApi.GetPartner.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartner} returns this
 */
proto.professionalProfileApi.GetPartner.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pictureUrl = 6;
 * @return {string}
 */
proto.professionalProfileApi.GetPartner.prototype.getPictureurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.GetPartner} returns this
 */
proto.professionalProfileApi.GetPartner.prototype.setPictureurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated CouncilProfessional councilProfessionals = 4;
 * @return {!Array<!proto.professionalProfileApi.CouncilProfessional>}
 */
proto.professionalProfileApi.GetPartner.prototype.getCouncilprofessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.CouncilProfessional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.CouncilProfessional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.CouncilProfessional>} value
 * @return {!proto.professionalProfileApi.GetPartner} returns this
*/
proto.professionalProfileApi.GetPartner.prototype.setCouncilprofessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.CouncilProfessional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.CouncilProfessional}
 */
proto.professionalProfileApi.GetPartner.prototype.addCouncilprofessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.CouncilProfessional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.GetPartner} returns this
 */
proto.professionalProfileApi.GetPartner.prototype.clearCouncilprofessionalsList = function() {
  return this.setCouncilprofessionalsList([]);
};


goog.object.extend(exports, proto.professionalProfileApi);
