import { createApp } from 'vue'
import App from '../src/modules/app/views/App.vue';
import router from './router'
import { createPinia } from 'pinia';
// import store from './store';;

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './modules/app/css/App.scss';
import './modules/app/css/Template.scss';
import './modules/app/css/Flex.scss';

import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Card from 'primevue/card';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import InputMask from 'primevue/inputmask';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Kprogress from 'k-progress-v3';
import Menu from 'primevue/menu';
import OverlayPanel from 'primevue/overlaypanel';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
// import Pdf from 'pdfjs-dist';
import VuePdf from 'vue3-pdfjs'
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import SelectButton from 'primevue/selectbutton';
import TabMenu from 'primevue/tabmenu';
import Toolbar from 'primevue/toolbar';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice';
import VueSocialSharing from 'vue-social-sharing'
import ConfirmationService from 'primevue/confirmationservice';
import MultiSelect from 'primevue/multiselect';
import Skeleton from 'primevue/skeleton';
import Editor from 'primevue/editor';

const pinia = createPinia()
const app = createApp(App);

// app.use(store);
app.use(pinia);
app.use(router);

// app.use(PrimeVue);
app.use(PrimeVue, {
  locale: {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'Igual',
    notEquals: 'Diferente',
    noFilter: 'Sem Filtro',
    lt: 'Menor que',
    lte: 'Menor ou igual a',
    gt: 'Maior que',
    gte: 'Maior ou igual a',
    dateIs: 'Data é',
    dateIsNot: 'Data não é',
    dateBefore: 'Data é antes',
    dateAfter: 'Data é depois',
    clear: 'Limpar',
    apply: 'Aplicar',
    matchAll: 'Corresponde a Todos',
    matchAny: 'Corresponde a Qualquer',
    addRule: 'Adicionar Regra',
    removeRule: 'Remover Regra',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolher',
    upload: 'Upload',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Dom.","Seg.","Ter.","Qua.","Qui.","Sex.","Sáb."],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan.", "Fev.", "Mar.", "Abr.", "Mai.", "Jun.", "Jul.", "Ago.", "Set.", "Out.", "Nov.", "Dez."],
    today: 'Hoje',
    weekHeader: 'Sem',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyFilterMessage: 'Nenhum resultado encontrado',
    emptyMessage: 'Sem opções disponíveis'
  }
});

app.directive('tooltip', Tooltip)

app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar);
app.component('BlockUI', BlockUI);
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Checkbox', Checkbox);
app.component('Card', Card);
app.component('Chip', Chip)
app.component('Column', Column)
app.component('ConfirmPopup', ConfirmPopup)
app.component('DataTable', DataTable)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('FileUpload', FileUpload)
app.component('InputMask', InputMask)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('k-progress', Kprogress)
app.component('Menu', Menu)
app.component('OverlayPanel', OverlayPanel)
app.component('Panel', Panel)
app.component('Password', Password)
// app.component('Pdf', Pdf)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton)
app.component('SelectButton', SelectButton)
app.component('SplitButton', SplitButton)
app.component('Splitter', Splitter)
app.component('SplitterPanel', SplitterPanel)
app.component('TabMenu', TabMenu)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('MultiSelect', MultiSelect)
app.component('Skeleton', Skeleton)
app.component('Editor', Editor)
// app.component('ShareNetwork', VueSocialSharing)

// app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' })
// app.config.globalProperties.$primevue = reactive({ ripple: true })

app.use(VuePdf)
app.use(VueSocialSharing)
app.use(ToastService)
app.use(ConfirmationService)

app.mount('#app');
