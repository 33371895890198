import { 
  createRouter, 
  createWebHistory, 
  RouteRecordRaw 
} from 'vue-router'
import validationLogin from './validationsLogin';
import healthAuthentication from "./health.authentication"
import validationsRegister from './validationsRegister';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    beforeEnter: validationLogin.AccountMain,
    meta: { auth: true },
    component: () => import('../modules/app/views/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../modules/dashboard/views/Dashboard.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../modules/profile/views/Profile.vue'),
        children: [
          {
            path: 'info',
            name: 'Profile.Info',
            component: () => import('../modules/profile/views/Info.vue')
          },
        ],
        redirect: {
          name: 'Profile.Info'
        }
      },
      {
        path: '/team',
        component: () => import('../modules/team/index.vue'),
      },
      {
          path: '/settings',
          component: () => import('../modules/settings/index.vue'),
      },
      {
          path: '/help',
          component: () => import('../modules/help/index.vue'),
      },
      {
          path: '/my-profile',
          component: () => import('../modules/myprofile/index.vue'),
      },
      {
        path: '/appointment',
        name: 'Appointment',
        component: () => import('../modules/appointment/Appointment.vue'),
        children: [
          {
            path: 'today',
            name: 'Appointment.Today',
            component: () => import('../modules/appointment/Today/Today.vue')
          },
          {
            path: 'futures',
            name: 'Appointment.Futures',
            component: () => import('../modules/appointment/Futures/Futures.vue')
          },
          {
            path: 'previous',
            name: 'Appointment.Previous',
            component: () => import('../modules/appointment/Previous/Previous.vue')
          },
          // {
          //   path: 'cockpit/:id',
          //   name: 'Appointment.Cockpit',
          //   component: () => import('../modules/appointment/views/Cockpit.vue')
          // },
        ]
      },
      {
        path: '/schedule',
        name: 'Schedule',
        component: () => import('../modules/schedule/Schedule.vue'),
        children: [
          {
            path: 'diary',
            name: 'Schedule.Diary',
            component: () => import('../modules/schedule/diary/diary.vue')
          },
          {
            path: 'week',
            name: 'Schedule.Week',
            component: () => import('../modules/schedule/week/week.vue')
          },
          {
            path: 'monthly',
            name: 'Schedule.Monthly',
            component: () => import('../modules/schedule/monthly/monthly.vue')
          },
        ]
      },
      {
        path: '/myservices',
        name: 'MyServices',
        component: () => import('../modules/myservices/index.vue'),
        children: [
          {
            path: 'services',
            name: 'MyServices.Services',
            component: () => import('../modules/myservices/Services/index.vue')
          },
          {
            path: 'schedules',
            name: 'MyServices.Schedules',
            component: () => import('../modules/myservices/Schedules/Schedules.vue')
          },
          {
            path: 'locations',
            name: 'Schedule.Locations',
            component: () => import('../modules/myservices/Locations/index.vue')
          },
        ]
      },
      {
        path: '/patients',
        name: 'Patients',
        component: () => import('../modules/patients/patients.vue'),
      },
      {
        path: '/partnerships',
        name: 'Partnerships',
        component: () => import('../modules/partnerships/index.vue'),
      },
      {
        path: '/financial',
        name: 'Financial',
        component: () => import('../modules/financial/index.vue'),
      },
      {
        path: '/marketing',
        name: 'Marketing',
        component: () => import('../modules/marketing/index.vue'),
      },
    ]
  },
  {
    path: '/health',
    name: 'Health',
    beforeEnter: healthAuthentication.validation,
    component: () => import('../modules/health/Health.vue')
  },
  {
    path: '/register/continue/:email&:token',
    name: 'Continue',
    component: () => import('../modules/account/views/RegisterContinue/RegisterContinue.vue')
  },
  {
    path: '/pdf-view',
    name: 'PdfView',
    component: () => import('../modules/account/views/pdfView.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../modules/app/views/PublicLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Account.Login',
        component: () => import('../modules/account/views/Login/Login.vue')
      },
      {
        path: 'register',
        name: 'Account.Register',
        component: () => import('../modules/account/views/Register/Register.vue')
      },
      {
        path: '/account/email/confirmation/:email&:token',
        name: 'Account.EmailConfirmation',
        // beforeEnter: ,
        component: () => import('../modules/account/views/EmailConfirmationWithCode/EmailConfirmationWithCode.vue')
      },
      {
        path: '/account/email/confirmation',
        name: 'Account.EmailConfirm',
        beforeEnter: validationsRegister.AccountEmailConfirm,
        component: () => import('../modules/account/views/EmailConfirmation/EmailConfirmation.vue')
      },
      {
        path: '/account/phone/confirmation',
        name: 'AccountPhoneConfirmation',
        beforeEnter: validationsRegister.AccountPhoneConfirmation,
        component: () => import('../modules/account/views/PhoneConfirmation/PhoneConfirmation.vue')
      },
      {
        path: '/account/pending/confirmation',
        name: 'AccountPendingConfirmation',
        beforeEnter: validationsRegister.SetPhone,
        component: () => import('../modules/account/views/PendingConfirmation/PendingConfirmation.vue')
      },
      {
        path: 'recover',
        name: 'Account.Recover',
        component: () => import('../modules/account/views/Recover.vue')
      },
      {
        path: '/account/recover/searchAccount',
        name: 'Account.SearchAccount',
        component: () => import('../modules/account/views/SearchAccount.vue')
      },
      {
        path: '/account/recover/change_password',
        name: 'Account.ChangePassword',
        component: () => import('../modules/account/views/ChangePassword.vue')
      },
      {
        path: 'infos',
        name: 'Account.Infos',
        beforeEnter: validationsRegister.AccountInfos,
        component: () => import('../modules/account/views/Infos/Infos.vue')
      },
      {
        path: 'records',
        name: 'Account.Records',
        beforeEnter: validationsRegister.AccountRecords,
        component: () => import('../modules/account/views/Records/Records.vue')
      },
      {
        path: 'upload',
        name: 'Account.UploadDocument',
        beforeEnter: validationsRegister.AccountUpload,
        // beforeEnter: isAuthenticated,
        component: () => import('../modules/account/views/UploadDocument/UploadDocument.vue')
      },
      {
        path: 'presentation',
        name: 'Account.Presentation',
        beforeEnter: validationsRegister.AccountPresentation,
        // component: () => import('../modules/account/views/Presentation.vue')
        component: () => import('../modules/account/views/Presentation/Presentation.vue')
      },
      {
        path: 'success',
        name: 'Account.Success',
        // beforeEnter: isAuthenticated,
        // component: () => import('../modules/account/views/Success.vue')
        component: () => import('../modules/account/views/RegisterSuccessfully.vue')
      },
    ],
    redirect: {
      name: 'Account.Login'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to, from, next) => {
//    console.log(to, from);
//    next();
// });

export default router
